import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/LoginPage.module.css';
import videoBackground from '../styles/background.mp4'; // Calea relativă către videoclip


const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [clientId, setClientId] = useState('');
  const [role, setRole] = useState('client');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';
      const loginEndpoint =
        role === 'client' ? '/loginClient' : role === 'dispatcher' ? '/loginDispatcher' : '/loginAccounting';

      const response = await axios.post(
        `${apiUrl}${loginEndpoint}`,
        { email, password },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === '2FA required') {
        setIsTwoFactorRequired(true);
        setClientId(response.data.clientId);
      } else if (response.data.message === 'Login successful') {
        const { token, clientId, package: clientPackage, accessibleModules } = response.data;

        sessionStorage.setItem('token', token);
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('role', role);

        if (clientPackage) {
          if (role === 'client') {
            localStorage.setItem('clientPackage', clientPackage);
            navigateToPackage(clientPackage);
          } else {
            navigate(`/client/${clientPackage}`, { state: { role, accessibleModules } });
          }
        } else {
          setError('Client package is undefined. Please check your account.');
        }
      } else {
        setError('Incorrect email or password.');
      }
    } catch (err) {
      console.error('Error during login:', err);
      const errorMessage = err.response?.data?.error || 'An error occurred. Please try again.';
      setError(errorMessage);
    }
  };

  const handleTwoFactorSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';

      if (!clientId || !twoFactorCode) {
        setError('Client ID and 2FA code are required.');
        return;
      }

      const response = await axios.post(
        `${apiUrl}/verify-two-factor`,
        { clientId, code: twoFactorCode },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === '2FA verified successfully') {
        const { token, package: clientPackage, accessibleModules } = response.data;

        sessionStorage.setItem('token', token);

        if (clientPackage) {
          if (role === 'client') {
            localStorage.setItem('clientPackage', clientPackage);
            navigateToPackage(clientPackage);
          } else {
            navigate(`/client/${clientPackage}`, { state: { role, accessibleModules } });
          }
        } else {
          setError('Client package is undefined. Please check your account.');
        }
      } else {
        setError('Invalid 2FA code.');
      }
    } catch (err) {
      console.error('Error during 2FA verification:', err);
      const errorMessage = err.response?.data?.error || 'An error occurred. Please try again.';
      setError(errorMessage);
    }
  };

  const navigateToPackage = (clientPackage) => {
    switch (clientPackage) {
      case 'Basic':
        navigate('/client/basic');
        break;
      case 'Standard':
        navigate('/client/standard');
        break;
      case 'Premium':
        navigate('/client/premium');
        break;
      case 'Ultimate':
        navigate('/client/ultimate');
        break;
      default:
        setError('Invalid package.');
    }
  };

  return (
    <div className={styles.loginPageContainer}>
    {/* Videoclip de fundal */}
    <video className={styles.loginPageVideoBackground} autoPlay loop muted>
      <source src={videoBackground} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
   
      {/* Header Menu */}
      <header className={styles.loginPageheaderMenu}>
        <div onClick={() => navigate('/')} className={styles.loginPagelogo}>
          Full Trucking Service
        </div>
        <nav>
          <ul>
            <li onClick={() => navigate('/about')}>About Us</li>
            <li onClick={() => navigate('/services')}>Services</li>
            <li onClick={() => navigate('/services')}>Services</li>
            <li onClick={() => navigate('/contact')}>Contact</li>
          </ul>
        </nav>
      </header>

      <div className={styles.loginPagemainContent}>
        {/* Secțiunea de bun venit */}
        <div className={styles.loginPagewelcomeSection}>
          <h1>Welcome to Full Trucking Service</h1>
          <p>Your trusted partner in professional logistics. Let’s deliver excellence together!</p>
        </div>

        {/* Login Section */}
        <div className={styles.loginSection}>
          {!isTwoFactorRequired ? (
            <form onSubmit={handleLogin} className={styles.loginForm}>
              <h2>Login</h2>
              <select value={role} onChange={(e) => setRole(e.target.value)} className={styles.loginPageinput}>
                <option value="client">Client</option>
                <option value="dispatcher">Dispatcher</option>
                <option value="accounting">Accounting</option>
              </select>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.loginPageinput1}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className={styles.loginPageinput2}
              />
              {error && <p className={styles.loginPageerror}>{error}</p>}
              <button type="submit" className={styles.loginPagebutton}>Login</button>
            </form>
          ) : (
            <form onSubmit={handleTwoFactorSubmit} className={styles.loginForm}>
              <h2>Two-Factor Authentication</h2>
              <input
                type="text"
                placeholder="Enter 2FA Code"
                value={twoFactorCode}
                onChange={(e) => setTwoFactorCode(e.target.value)}
                required
                className={styles.loginPageinput3}
              />
              {error && <p className={styles.loginPageerror}>{error}</p>}
              <button type="submit" className={styles.loginPagebutton}>Verify</button>
            </form>
          )}
          <div className={styles.links}>
            <div onClick={() => navigate('/pricing')} className={styles.loginPagelink}>Register</div>
            <div onClick={() => navigate('/forget-password')} className={styles.loginPagelink}>Forgot Password?</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
