import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import Select from 'react-select';
import { Doughnut, Bar, Pie, Scatter } from 'react-chartjs-2';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar } from "react-bootstrap";
import GaugeChart from 'react-gauge-chart';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'react-datepicker/dist/react-datepicker.css';
import Picker from 'emoji-picker-react'; 
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Compressor from 'compressorjs';


import styles from '../../styles/UltimatePage.module.css';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View } from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';
import InvoicePDF from './InvoicePDF';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  ArcElement
} from 'chart.js';
import {
  BarChart,
  XAxis,
  YAxis,

 
  LineChart,
  Line,
  CartesianGrid,
  PieChart,
  
  Cell
} from "recharts";
import { GoogleMap, LoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';


// Înregistrăm ArcElement împreună cu celelalte elemente
ChartJS.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
  Title
);

const socket = io('https://api-backend.full-trucking-service.com', {
  transports: ['websocket', 'polling'],
  secure: true, // Asigură conexiuni securizate în mediul de producție
  reconnect: true, // Activează reconectarea automată în cazul întreruperii
  timeout: 20000, // Timpul maxim de conectare înainte de timeout (în milisecunde)
  reconnectionAttempts: 5, // Numărul de încercări de reconectare înainte de a renunța
  reconnectionDelay: 1000, // Întârziere între reconectări (în milisecunde)
  rejectUnauthorized: false, // Permite conexiuni securizate fără a respinge cererile nesemnate
});



const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';

const UltimatePage = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(null);
  const [userSettings, setUserSettings] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [consignees, setConsignees] = useState([]);
  const [loads, setLoads] = useState([]);
  const [driverPays, setDriverPays] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [loadNumber, setLoadNumber] = useState('');
  const [dispatcher, setDispatcher] = useState('');
  const [brokerName, setBrokerName] = useState('');
  const [brokerAddress, setBrokerAddress] = useState('');
  const [rate, setRate] = useState('');
  const [equipment, setEquipment] = useState('');
  const [driver, setDriver] = useState('');
  const [truck, setTruck] = useState('');
  const [trailer, setTrailer] = useState('');
  const [status, setStatus] = useState('');
  const [shipperName, setShipperName] = useState('');
  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');
  const [miles, setMiles] = useState('');
  const [dh, setDH] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [messages, setMessages] = useState([]);
  const [startDate, setStartDate] = useState('');  // Definim startDate
  const [endDate, setEndDate] = useState('');  
  const [loading, setLoading] = useState(false);
  const [dispatchers, setDispatchers] = useState([]); // Definește `dispatchers`
  const [currentUserId, setCurrentUserId] = useState(null); // Definește `currentUserId`
  const [currentUserRole, setCurrentUserRole] = useState(null); // Definește `currentUserRole`
   
  const location = useLocation(); // Inițializează useLocation
  const userRole = location.state?.role || 'client'; // Setează 'client' ca rol implicit
  
  const modulesForRole = {
    dispatcher: ['dashboard', 'load-manager', 'load-finder', 'trucking', 'chat', 'add-new-load'],
    accounting: ['dashboard', 'accounting-manager', 'driver-pay', 'ifta']
  };

  const accessibleModules = userRole === 'client' ? 
    [
      'dashboard', 'accounting-manager', 'driver-pay', 'ifta', 
      'drivers', 'trucks', 'trailers', 'brokers', 'shippers', 
      'consignees', 'load-manager', 'load-finder', 'trucking', 
      'chat', 'settings', 'add-new-load'
    ] 
    : modulesForRole[userRole] || [];
  
    

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
      const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,  // Adaugă tokenul cu prefixul Bearer
          'Content-Type': 'application/json',  // Specifică tipul de conținut
        },
        withCredentials: true,  // Trimite și cookies pentru autentificare
      };
      
      // Fiecare cerere axios.get are propriul config cu Authorization
      const userSettingsRes = await axios.get(`${apiUrl}/settings`, config);
      const driversRes = await axios.get(`${apiUrl}/drivers`, config);
      const trucksRes = await axios.get(`${apiUrl}/trucks`, config);
      const trailersRes = await axios.get(`${apiUrl}/trailers`, config);
      const brokersRes = await axios.get(`${apiUrl}/brokers`, config);
      const shippersRes = await axios.get(`${apiUrl}/shippers`, config);
      const consigneesRes = await axios.get(`${apiUrl}/consignees`, config);
      const loadsRes = await axios.get(`${apiUrl}/loads`, config);
      const driverPaysRes = await axios.get(`${apiUrl}/driverpays`, config);
      const iftaReportsRes = await axios.get(`${apiUrl}/ifta`, config);
  
      // Actualizarea state-urilor cu datele primite
      setUserSettings(userSettingsRes.data);
      setDrivers(driversRes.data);
      setTrucks(trucksRes.data);
      setTrailers(trailersRes.data);
      setBrokers(brokersRes.data);
      setShippers(shippersRes.data);
      setConsignees(consigneesRes.data);
      setLoads(loadsRes.data);
      setDriverPays(driverPaysRes.data);  
    
      // Se adaugă iftaReportsRes.data la apelul calculateDashboardData
      calculateDashboardData(
        driversRes.data, 
        trucksRes.data, 
        loadsRes.data, 
        iftaReportsRes.data
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleSendMessage = (message) => {
    if (message.trim() !== '') {
      setMessages((prevMessages) => [
        ...prevMessages,
        { from: 'me', message },
      ]);
    }
  };
  useEffect(() => {
    // Exemplu de apel API pentru a obține dispatchers și datele utilizatorului
    const fetchData = async () => {
      const token = sessionStorage.getItem('token') || localStorage.getItem('token'); // Obține token-ul de autentificare
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token-ul cu prefixul Bearer
          'Content-Type': 'application/json',
        },
        withCredentials: true, // Permite trimiterea cookie-urilor pentru autentificare
      };
    
      try {
        // Apel pentru a obține lista de dispatchers
        const dispatchersData = await axios.get('https://api-backend.full-trucking-service.com/api/dispatchers', config);
        setDispatchers(dispatchersData.data);
    
        // Apel pentru a obține datele utilizatorului curent
        const userData = await axios.get('https://api-backend.full-trucking-service.com/api/current-user', config);
        setCurrentUserId(userData.data.id);
        setCurrentUserRole(userData.data.role);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const calculateDashboardData = (drivers = [], trucks = [], loads = [], iftaReports = []) => {
    const totalDrivers = Array.isArray(drivers) ? drivers.length : 0;
    const totalLoads = Array.isArray(loads) ? loads.length : 0;
    const totalMiles = Array.isArray(loads)
      ? loads.reduce((sum, load) => sum + parseFloat(load.miles || 0), 0)
      : 0;
    const totalDH = Array.isArray(loads)
      ? loads.reduce((sum, load) => sum + parseFloat(load.dh || 0), 0)
      : 0;
    const totalRevenue = Array.isArray(loads)
      ? loads.reduce((sum, load) => sum + parseFloat(load.rate || 0), 0)
      : 0;
  
    const totalIFTAFuelTax = Array.isArray(iftaReports)
      ? iftaReports.reduce((sum, report) => sum + parseFloat(report.totalFuelTax || 0), 0)
      : 0;
    const totalIFTAFuelCost = Array.isArray(iftaReports)
      ? iftaReports.reduce((sum, report) => sum + parseFloat(report.totalFuelCost || 0), 0)
      : 0;
  
    const upcomingLicenseExpirations = Array.isArray(drivers)
      ? drivers.filter(driver =>
          new Date(driver.licenseExpiryDate) < new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
        ).length
      : 0;
  
    setDashboardData({
      totalDrivers,
      totalLoads,
      totalMiles,
      totalDH,
      totalRevenue,
      totalIFTAFuelTax,
      totalIFTAFuelCost,
      upcomingLicenseExpirations,
      loads, // Include loads data for later use
      drivers, // Include drivers data for later use
      iftaReports, // Include IFTA data for later use
    });
  };
  
  
  function handleImageUpload(event) {
    const file = event.target.files[0];
  
    if (!file) return;
  
    new Compressor(file, {
      quality: 0.6, // Nivel de compresie (0-1), 0.6 înseamnă 60% din calitatea originală
      maxWidth: 1000, // Lățimea maximă a imaginii (în funcție de nevoie)
      success(result) {
        // Trimite imaginea comprimata la server
        const formData = new FormData();
        formData.append('image', result);
  
        fetch('/api/logo', {
          method: 'PUT',
          body: formData,
        })
        .then(response => response.json())
        .then(data => {
          console.log('Imaginea a fost încărcată cu succes', data);
        })
        .catch(error => {
          console.error('Eroare la încărcarea imaginii:', error);
        });
      },
      error(err) {
        console.error('Eroare la compresarea imaginii:', err);
      },
    });
  }
  
  useEffect(() => {
    fetchData();
  }, []);
  const handleUpdateDriver = async (updatedDriver) => {
    try {
      const response = await axios.put(`${apiUrl}/drivers/${updatedDriver._id}`, updatedDriver, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
  
      if (!response || response.status !== 200) {
        throw new Error('Failed to update driver');
      }
  
      const updatedData = response.data;
      setDrivers((prevDrivers) =>
        prevDrivers.map((driver) => (driver._id === updatedDriver._id ? updatedData : driver))
      );
      alert('Driver information has been updated successfully!');
    } catch (error) {
      console.error('Error updating driver:', error);
      alert('Failed to update driver');
    }
  };
  
  const openSection = (section) => setActiveSection(section);
   
  const closeSection = () => setActiveSection(null);

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}/logout`, {}, { withCredentials: true });
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const updateUserSettings = async (settings) => {
    setLoading(true); // Pornește animația
    
    const token = sessionStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
  
    try {
      const response = await axios.put(`${apiUrl}/settings`, settings, config);
      setUserSettings(response.data);
      alert('Settings updated successfully!');
    } catch (error) {
      console.error('Error updating user settings:', error);
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        navigate('/login');
      }
    } finally {
      setLoading(false); // Oprește animația după finalizarea cererii
    }
  };
  
  

  const handleAddLoad = async (load) => {
    try {
        if (!load.loadNumber || !load.brokerName || !load.brokerAddress || !load.rate || !load.miles || !load.dh) {
            alert('Please fill in all required fields.');
            return;
        }

        // Elimina virgula și convertește rate într-un număr
        const cleanedRate = parseFloat(load.rate.replace(/,/g, ''));
        
        const loadData = {
            ...load,
            rate: cleanedRate,  // Asigură-te că `rate` este un număr
            miles: parseFloat(load.miles), // Adaugă miles și convertește în număr
            dh: parseFloat(load.dh), // Adaugă dh și convertește în număr
        };

        const config = { headers: { 'Content-Type': 'application/json' }, withCredentials: true };
        const response = await axios.post(`${apiUrl}/loads`, loadData, config);

        setLoads([...loads, response.data]);
    } catch (error) {
        console.error('Error adding load:', error);
    }
};

  
  
  
const handleAddDriverPay = async (driverPayData) => {
  try {
    const response = await axios.post('https://api-backend.full-trucking-service.com/api/driverpays', driverPayData, {
      withCredentials: true, // Dacă folosești autentificare
    });
    console.log('Driver pay added:', response.data);
  } catch (error) {
    console.error('Error adding driver pay:', error);
  }
};


  
const handleUploadRC = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  console.log('File being uploaded: ', file);

  try {
    // Trimitem request-ul către server pentru încărcare și procesare fișier
    const response = await axios.post(`${apiUrl}/upload-rc`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Verificăm dacă răspunsul există și conține date
    if (!response || !response.data || !response.data.extractedData) {
      throw new Error('No data returned from server.');
    }

    console.log('Raw response from server:', response.data);

    // Parsează datele extrase trimise de la server
    const extractedData = response.data.extractedData;

    // Log pentru datele extrase
    console.log('Extracted Data:', extractedData);

    // Poți salva `extractedData` în state sau folosi în continuare după necesitate
    return extractedData;

  } catch (error) {
    // Afișăm eroarea în consolă și o aruncăm pentru a putea fi capturată în altă parte
    console.error('Error during file upload:', error);
    throw new Error('Error uploading the file. Please try again.');
  }
};

  

return (
  <div className={styles.ultimatePage}>
    <header className={styles.headerultimatePage}>
      <div className={styles.logoultimatePage} onClick={() => navigate('')}>
        <span className={styles.logoPart1ultimatePage}>Full</span>{' '}
        <span className={styles.logoPart2ultimatePage}>Trucking</span>{' '}
        <span className={styles.logoPart1ultimatePage}>Service</span>
      </div>
      <nav className={styles.navultimatePage}>
        <ul className={styles.mainMenuultimatePage}>
          {accessibleModules.includes('dashboard') && <li onClick={() => openSection('dashboard')}>Dashboard</li>}
          {accessibleModules.includes('accounting-manager') && (
            <li>
              Finance
              <ul className={styles.subMenuultimatePage}>
                <li onClick={() => openSection('accounting-manager')}>Ledger Manager</li>
                <li onClick={() => openSection('driver-pay')}>Payroll</li>
                <li onClick={() => openSection('fuel-report')}>Fuel Report</li>
                <li onClick={() => openSection('invoice')}>Invoice</li>
               
              </ul>
            </li>
          )}
          {accessibleModules.includes('drivers') && (
            <li>
              Operations
              <ul className={styles.subMenuultimatePage}>
                <li onClick={() => openSection('drivers')}>Drivers</li>
                <li onClick={() => openSection('trucks')}>Fleet</li>
                <li onClick={() => openSection('trailers')}>Trailers</li>
                <li onClick={() => openSection('shippers')}>Shippers</li>
                <li onClick={() => openSection('consignee')}>Consignees</li>
                <li onClick={() => openSection('brokers')}>Brokers</li>
         
              </ul>
            </li>
          )}
          {accessibleModules.includes('load-manager') && (
            <li>
              Logistics
              <ul className={styles.subMenuultimatePage}>
                <li onClick={() => openSection('load-manager')}>Load Scheduler</li>
                <li onClick={() => openSection('load-finder')}>Load Tracker</li>
              </ul>
            </li>
          )}
          {accessibleModules.includes('trucking') && <li onClick={() => openSection('trucking')}>Tracking</li>}
          {accessibleModules.includes('chat') && <li onClick={() => openSection('chat')}>Communication Center</li>}
          {accessibleModules.includes('settings') && <li onClick={() => openSection('settings')}>Settings</li>}
          {accessibleModules.includes('add-new-load') && (
            <li>
              <button className={styles.buttonultimatePage} onClick={() => openSection('add-new-load')}>
                Load Entry
              </button>
            </li>
          )}
          {accessibleModules.includes('ifta') && (
            <li>
              <button className={styles.buttonultimatePage} onClick={() => openSection('ifta')}>
                Fuel Tax Reporting
              </button>
            </li>
          )}
          <li>
            <button className={styles.buttonultimatePage} onClick={handleLogout}>Logout</button>
          </li>
        </ul>
      </nav>
    </header>

    <main>
    {activeSection === 'dashboard' && (
    <Dashboard
      dashboardData={dashboardData}
      drivers={drivers}
      trucks={trucks}
      loads={loads}
      onNavigate={setActiveSection}
      shippers={shippers}
      brokers={brokers}
      consignees={consignees}
    />
  )}
 {activeSection === 'load-dashboard' && (
  <LoadsDashboard
    drivers={drivers}
    loads={loads}
    shippers={shippers}
    consignees={consignees}
    trucks={trucks}
  />
)}

      {activeSection === 'drivers' && <Drivers drivers={drivers} setDrivers={setDrivers} trucks={trucks} />}
      {activeSection === 'trucks' && <Trucks trucks={trucks} setTrucks={setTrucks} drivers={drivers} trailers={trailers} />}
      {activeSection === 'trailers' && <Trailers trailers={trailers} setTrailers={setTrailers} />}
      {activeSection === 'brokers' && <Brokers brokers={brokers} setBrokers={setBrokers} />}
      {activeSection === 'shippers' && <Shippers shippers={shippers} setShippers={setShippers} />}
      {activeSection === 'consignee' && <Consignees consignees={consignees} setConsignees={setConsignees} />}
      {activeSection === 'load-manager' && <LoadManager loads={loads} setLoads={setLoads} />}
      {activeSection === 'invoice' && <Invoice
       loads={loads}
      setLoads={setLoads}
      settings={userSettings}
      drivers={drivers}
      trucks={trucks}
      brokers={brokers}
      shippers={shippers}
      consignees={consignees}
         />}
      {activeSection === 'load-finder' && <LoadFinder loads={loads} />}
      {activeSection === 'accounting-manager' && <AccountingManager loads={loads} drivers={drivers} />}
      {activeSection === 'driver-pay' && <DriverPay drivers={drivers} trucks={trucks} loads={loads} onAddDriverPay={handleAddDriverPay} />}
      {activeSection === 'fuel-report' && <FuelReport drivers={drivers} trucks={trucks} />}
  
      {activeSection === 'settings' && <Settings settings={userSettings} updateUserSettings={updateUserSettings} drivers={drivers} />}
      {activeSection === 'add-new-load' && <AddLoad
  onClose={closeSection}
  onAddLoad={handleAddLoad}
  drivers={drivers}
  trucks={trucks}
  trailers={trailers}
  brokers={brokers}
  shippers={shippers}
  consignees={consignees}
  handleUploadRC={handleUploadRC}
  dispatchers={dispatchers} // Asigură-te că `dispatchers` este definit în componenta principală
  currentUserId={currentUserId} // Obține `currentUserId` și `currentUserRole` din starea sau props
  currentUserRole={currentUserRole}
/>
}      {activeSection === 'ifta' && <IFTA />}
      {activeSection === 'trucking' && <Trucking drivers={drivers} />}
      {activeSection === 'chat' && <Chat drivers={drivers} handleSendMessage={handleSendMessage} />}
    </main>
  </div>
);
};

const Dashboard = ({ drivers = [], trucks = [], loads = [], onNavigate, fuelReports = [], iftaReports = [] }) => {
  const [dashboardData, setDashboardData] = useState({
    totalDrivers: 0,
    totalTrucks: 0,
    totalLoads: 0,
    totalMiles: 0,
    totalRate: 0,
    loadsPerDriver: [],
    loadsPerTruck: [],
    dailyRevenue: [],
    fuelPerMile: 0,
    topRates: [],
  });

  const [filteredDriver, setFilteredDriver] = useState(null);
  const [filteredTruck, setFilteredTruck] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [mapCenter, setMapCenter] = useState({ lat: 40.7128, lng: -74.006 }); // Default: New York City

  useEffect(() => {
    if (drivers.length && trucks.length && loads.length) {
      calculateDashboardData();
    }
  }, [drivers, trucks, loads, fuelReports, iftaReports]);

  const calculateDashboardData = () => {
    const totalDrivers = drivers.length;
    const totalTrucks = trucks.length;
    const totalLoads = loads.length;
    const totalMiles = loads.reduce((acc, load) => acc + (parseFloat(load.miles) || 0), 0);
    const totalRate = loads.reduce((acc, load) => acc + (parseFloat(load.rate) || 0), 0);

    const loadsPerDriver = drivers.map((driver) => ({
      name: `${driver.firstName || "Unknown"} ${driver.lastName || ""}`,
      count: loads.filter((load) => load.driver === driver._id).length,
    }));

    const loadsPerTruck = trucks.map((truck) => ({
      name: truck.truckNumber || "Unknown",
      count: loads.filter((load) => load.truck === truck._id).length,
    }));

    const dailyRevenue = loads.reduce((acc, load) => {
      const date = new Date(load.date).toLocaleDateString();
      acc[date] = (acc[date] || 0) + (parseFloat(load.rate) || 0);
      return acc;
    }, {});

    const fuelUsed = fuelReports.reduce((acc, report) => acc + parseFloat(report.amount || 0), 0);
    const fuelPerMile = totalMiles ? (fuelUsed / totalMiles).toFixed(2) : 0;

    const topRates = loads
      .sort((a, b) => b.rate - a.rate)
      .slice(0, 5)
      .map((load) => ({
        loadNumber: load.loadNumber,
        rate: load.rate,
        driver: drivers.find((d) => d._id === load.driver)?.firstName || "Unknown",
      }));

    setDashboardData({
      totalDrivers,
      totalTrucks,
      totalLoads,
      totalMiles,
      totalRate,
      loadsPerDriver,
      loadsPerTruck,
      dailyRevenue: Object.entries(dailyRevenue).map(([date, revenue]) => ({ date, revenue })),
      fuelPerMile,
      topRates,
    });
  };

  const handleDriverChange = (selected) => {
    setFilteredDriver(selected);
    if (selected) {
      const driverLoads = loads.filter((load) => load.driver === selected.value);
      updateFilteredData(driverLoads);
    }
  };

  const handleTruckChange = (selected) => {
    setFilteredTruck(selected);
    if (selected) {
      const truckLoads = loads.filter((load) => load.truck === selected.value);
      updateFilteredData(truckLoads);
    }
  };

  const updateFilteredData = (filteredLoads) => {
    const filteredMiles = filteredLoads.reduce((acc, load) => acc + (parseFloat(load.miles) || 0), 0);
    const filteredRate = filteredLoads.reduce((acc, load) => acc + (parseFloat(load.rate) || 0), 0);
    setDashboardData((prevData) => ({
      ...prevData,
      totalLoads: filteredLoads.length,
      totalMiles: filteredMiles,
      totalRate: filteredRate,
    }));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setSelectedDateRange([start, end]);
    if (start && end) {
      const filteredLoads = loads.filter(
        (load) => new Date(load.date) >= start && new Date(load.date) <= end
      );
      updateFilteredData(filteredLoads);
    }
  };

  const {
    totalDrivers,
    totalTrucks,
    totalLoads,
    totalMiles,
    totalRate,
    loadsPerDriver,
    loadsPerTruck,
    dailyRevenue,
    fuelPerMile,
    topRates,
  } = dashboardData;

  return (
    <div className={styles.dashboard}>
      <header className={styles.headerdashboard}>
        <h1>Dashboard Overview</h1>
      </header>

      <section className={styles.actionsdashboard}>
        <button
          className={styles.loadDashboardButton}
          onClick={() => onNavigate('load-dashboard')}
        >
          Go to Load Dashboard
        </button>
      </section>

      {/* Selectors */}
      <section className={styles.filtersdashboard}>
        <div>
          <label>Driver:</label>
          <Select
            options={drivers.map((driver) => ({
              value: driver._id,
              label: `${driver.firstName} ${driver.lastName}`,
            }))}
            onChange={handleDriverChange}
          />
        </div>
        <div>
          <label>Truck:</label>
          <Select
            options={trucks.map((truck) => ({
              value: truck._id,
              label: truck.truckNumber,
            }))}
            onChange={handleTruckChange}
          />
        </div>
        <div>
          <label>Date Range:</label>
          <DatePicker
            selected={selectedDateRange[0]}
            onChange={handleDateChange}
            startDate={selectedDateRange[0]}
            endDate={selectedDateRange[1]}
            selectsRange
            isClearable
          />
        </div>
      </section>

      {/* Summary */}
      <section className={styles.summarydashboard}>
        <div className={styles.statCarddashboard}>
          <h4>Total Drivers</h4>
          <p>{totalDrivers}</p>
        </div>
        <div className={styles.statCarddashboard}>
          <h4>Total Trucks</h4>
          <p>{totalTrucks}</p>
        </div>
        <div className={styles.statCarddashboard}>
          <h4>Total Loads</h4>
          <p>{totalLoads}</p>
        </div>
        <div className={styles.statCarddashboard}>
          <h4>Total Miles</h4>
          <p>{totalMiles.toLocaleString()} mi</p>
        </div>
        <div className={styles.statCarddashboard}>
          <h4>Total Revenue</h4>
          <p>${totalRate.toLocaleString()}</p>
        </div>
        <div className={styles.statCarddashboard}>
          <h4>Fuel Per Mile</h4>
          <p>{fuelPerMile} gal/mi</p>
        </div>
      </section>

      {/* Charts */}
      <section className={styles.chartsdashboard}>
        <div className={styles.chartdashboard}>
          <h4>Loads per Driver</h4>
          <Bar
            data={{
              labels: loadsPerDriver.map((d) => d.name),
              datasets: [
                {
                  label: "Loads",
                  data: loadsPerDriver.map((d) => d.count),
                  backgroundColor: "rgba(75, 192, 192, 0.6)",
                },
              ],
            }}
          />
        </div>
        <div className={styles.chartdashboard}>
          <h4>Loads per Truck</h4>
          <Bar
            data={{
              labels: loadsPerTruck.map((t) => t.name),
              datasets: [
                {
                  label: "Loads",
                  data: loadsPerTruck.map((t) => t.count),
                  backgroundColor: "rgba(255, 159, 64, 0.6)",
                },
              ],
            }}
          />
        </div>
        <div className={styles.chartdashboard}>
          <h4>Daily Revenue</h4>
          <Line
            data={{
              labels: dailyRevenue.map((entry) => entry.date),
              datasets: [
                {
                  label: "Revenue ($)",
                  data: dailyRevenue.map((entry) => entry.revenue),
                  borderColor: "rgba(75, 192, 192, 1)",
                  backgroundColor: "rgba(75, 192, 192, 0.2)",
                },
              ],
            }}
          />
        </div>
      </section>

      {/* Top Rates */}
      <section className={styles.topRatesdashboard}>
        <h4>Top 5 Loads by Rate</h4>
        <table>
          <thead>
            <tr>
              <th>Load Number</th>
              <th>Rate</th>
              <th>Driver</th>
            </tr>
          </thead>
          <tbody>
            {topRates.map((load, index) => (
              <tr key={index}>
                <td>{load.loadNumber}</td>
                <td>${load.rate}</td>
                <td>{load.driver}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Progress */}
      <section className={styles.progressdashboard}>
        <h4>Progress Towards Monthly Goal</h4>
        <ProgressBar now={(totalRate / 50000) * 100} label={`${((totalRate / 50000) * 100).toFixed(2)}%`} />
      </section>

      {/* Map */}
      <section className={styles.mapdashboard}>
        <h4>Map Overview</h4>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap mapContainerStyle={{ height: "400px", width: "100%" }} center={mapCenter} zoom={8}>
            {loads
              .filter(
                (load) =>
                  load.latitude !== undefined &&
                  load.longitude !== undefined &&
                  !isNaN(parseFloat(load.latitude)) &&
                  !isNaN(parseFloat(load.longitude))
              )
              .map((load, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(load.latitude),
                    lng: parseFloat(load.longitude),
                  }}
                />
              ))}
          </GoogleMap>
        </LoadScript>
      </section>
    </div>
  );
};

const LoadsDashboard = ({ loads, drivers, trucks }) => {
  const [selectedStatus, setSelectedStatus] = useState("Open");
  const [filteredLoads, setFilteredLoads] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const itemsPerPage = 25;

  useEffect(() => {
    filterLoads();
  }, [selectedStatus, selectedDriver, loads, startDate, endDate]);

  const filterLoads = () => {
    let filtered = loads.filter(
      (load) =>
        (selectedStatus === "Open" && !["Cancelled", "Completed"].includes(load.status)) ||
        load.status === selectedStatus
    );

    if (selectedDriver) {
      filtered = filtered.filter((load) => load.driver === selectedDriver.value);
    }
    if (startDate && endDate) {
      filtered = filtered.filter(
        (load) =>
          new Date(load.createdAt) >= startDate && new Date(load.createdAt) <= endDate
      );
    }
    setFilteredLoads(filtered);
  };

  const paginatedLoads = filteredLoads.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const handleDriverChange = (driver) => {
    setSelectedDriver(driver);
    setCurrentPage(1);
  };

  const handleLoadClick = (load) => {
    setSelectedLoad(load);
  };

  const closeDetails = () => {
    setSelectedLoad(null);
  };

  const averageRate = (loadsArray) =>
    loadsArray.length
      ? loadsArray.reduce((total, load) => total + load.rate, 0) / loadsArray.length
      : 0;

  const statusDistributionData = {
    labels: ["Open", "Completed", "Cancelled"],
    datasets: [
      {
        label: "Percentage of Loads",
        data: [
          loads.filter((load) => load.status === "Open").length,
          loads.filter((load) => load.status === "Completed").length,
          loads.filter((load) => load.status === "Cancelled").length,
        ],
        backgroundColor: ["#36A2EB", "#4BC0C0", "#FF6384"],
      },
    ],
  };

  const driverPerformanceData = {
    labels: drivers.map((driver) => `${driver.firstName} ${driver.lastName}`),
    datasets: [
      {
        label: "Number of Loads",
        data: drivers.map(
          (driver) => loads.filter((load) => load.driver === driver._id).length
        ),
        backgroundColor: drivers.map(
          (_, index) =>
            `hsl(${(index / drivers.length) * 360}, 70%, 50%)` // Culori dinamice
        ),
      },
    ],
  };
  const completedLoads = loads.filter((load) => load.status === "Completed");
  const cancelledLoads = loads.filter((load) => load.status === "Cancelled");
  const openLoads = loads.filter((load) => !["Completed", "Cancelled"].includes(load.status));

  console.log("Rate Data Debug:", {
    openLoadsRate: averageRate(openLoads),
    completedLoadsRate: averageRate(completedLoads),
    cancelledLoadsRate: averageRate(cancelledLoads),
  });

  const rateComparisonData = {
    labels: ["Open", "Completed", "Cancelled"],
    datasets: [
      {
        label: "Average Rate",
        data: [
          averageRate(openLoads),
          averageRate(completedLoads),
          averageRate(cancelledLoads),
        ],
        borderColor: "#36A2EB",
        backgroundColor: "rgba(54,162,235,0.2)",
        fill: true,
      },
    ],
  };

  return (
    <div className={styles.loaddashboard}>
      {/* Selecția statusului și șoferului */}
      <div className={styles.filtersloaddashboard}>
        <Select
          options={[
            { value: "Open", label: "Open" },
            { value: "Completed", label: "Completed" },
            { value: "Cancelled", label: "Cancelled" },
          ]}
          value={{ value: selectedStatus, label: selectedStatus }}
          onChange={(option) => handleStatusChange(option.value)}
        />
        <Select
          options={drivers.map((driver) => ({
            value: driver._id,
            label: `${driver.firstName} ${driver.lastName}`,
          }))}
          value={selectedDriver}
          onChange={handleDriverChange}
          placeholder="Select Driver"
        />
      </div>
      <div className={styles.datePickers}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Start Date"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="End Date"
          />
        </div>
      
      {/* Tabel cu loads */}
      <div className={styles.tableContainerloaddashboard}>
        <table className={styles.tableloaddashboard}>
          <thead>
            <tr>
              <th>Dispatcher</th>
              <th>Load Number</th>
              <th>Broker</th>
              <th>Equipment</th>
              <th>Driver</th>
              <th>Team Drivers</th>
              <th>Truck</th>
              <th>Shippers & Consignees</th>
              <th>Notes</th>
              <th>Rate</th>
              <th>Added Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {paginatedLoads.map((load) => (
              <tr key={load._id} onClick={() => handleLoadClick(load)}>
                <td>{load.dispatcher}</td>
                <td>{load.loadNumber}</td>
                <td>{load.brokerName}</td>
                <td>{load.equipment}</td>
                <td>
                  {drivers.find((driver) => driver._id === load.driver)?.firstName || "N/A"}
                </td>
                <td>
                  {load.teamDrivers
                    .map((id) => drivers.find((driver) => driver._id === id)?.firstName)
                    .join(", ") || "N/A"}
                </td>
                <td>{load.truck}</td>
                <td>
                  {load.shippers.map((shipper) => shipper.address).join(", ")} to{" "}
                  {load.consignees.map((consignee) => consignee.address).join(", ")}
                </td>
                <td>{load.notes}</td>
                <td>${load.rate}</td>
                <td>{new Date(load.createdAt).toLocaleDateString()}</td>
                <td>{load.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginare */}
      <div className={styles.paginationloaddashboard}>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          disabled={currentPage === Math.ceil(filteredLoads.length / itemsPerPage)}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>

      {/* Detalii despre încărcătură */}
      {selectedLoad && (
        <div className={styles.loadDetailsloaddashboard}>
          <button onClick={closeDetails} className={styles.closeButtonloaddashboard}>
            Close
          </button>
          <h2>{selectedLoad.loadNumber} is {selectedLoad.status}</h2>
          <p>
            in {selectedLoad.shippers.map((s) => s.address).join(", ")} to{" "}
            {selectedLoad.consignees.map((c) => c.address).join(", ")} for ${selectedLoad.rate} on{" "}
            {new Date(selectedLoad.createdAt).toLocaleDateString()}
          </p>
          <div>
            <h4>Status Progress</h4>
            <ul>
              {getStatusProgress(selectedLoad.status).map(({ status, completed }) => (
                <li
                  key={status}
                  style={{
                    color: completed ? "green" : "gray",
                    fontWeight: completed ? "bold" : "normal",
                  }}
                >
                  {status}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
 
     
      {/* Chart-uri și statistici */}
      <div className={styles.chartsloaddashboard}>
        <h3>Status Distribution</h3>
        <Bar data={statusDistributionData} />
        <h3>Driver Performance</h3>
        <Pie data={driverPerformanceData} />
        <div className={styles.chartsloaddashboard}>
        <h3>Rate Comparison</h3>
        <Line
  data={rateComparisonData}
  options={{
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true, position: "top" },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        title: { display: true, text: "Status" },
        grid: { display: false },
      },
      y: {
        title: { display: true, text: "Average Rate ($)" },
        beginAtZero: true,
        ticks: {
          stepSize: 500, // Ajustăm treptele pentru o afișare mai clară
        },
      },
    },
  }}
/>

      </div>
  
      </div>
    </div>
  );
};

const getStatusProgress = (status) => {
  const statusesMap = {
    Pending: ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered"],
    Open: ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered"],
    Assigned: ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered"],
    Dispatched: ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered"],
    "On Route": ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered"],
    "In Progress": ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered"],
    Delivered: ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered"],
    Completed: ["Pending", "Open", "Assigned", "Dispatched", "On Route", "In Progress", "Delivered", "Completed"],
    Cancelled: ["Pending", "Open", "In Yard", "Refused", "Un-Loading", "Cancelled"],
  };

  const progressStatuses = statusesMap[status] || [];
  const currentIndex = progressStatuses.indexOf(status);

  return progressStatuses.map((s, index) => ({
    status: s,
    completed: index <= currentIndex, // Marchează statusurile completate
  }));
};


const Drivers = ({ drivers = [], setDrivers, trucks = [] }) => {
  const [limits, setLimits] = useState({ numberOfDrivers: 0 });
  const [driverData, setDriverData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    hireDate: '',
    truckNumber: '',
    email: '',
    mobilePhone: '',
    phone: '',
    address: '',
    licenseNumber: '',
    licenseExpiryDate: '',
    passportExpiryDate: '',
    lastMedical: '',
    nextMedical: '',
    lastDrugTest: '',
    lastAlcoholTest: '',
    fuelCardNumber: '',
    fuelCardExpiryDate: '',
    fleetDivision: '',
    settlement: '',
    settlementAmount: '',
    licenseType: '',
    licenseState: '',
    emergencyContactFirstName: '',
    emergencyContactLastName: '',
    emergencyContactRelation: '',
    emergencyContactPhone: '',
    employeeRecordsChecklist: {
      copyOfLicense: false,
      preEmploymentDrugTest: false,
      longMedicalForm: false,
      copyOfRoadTest: false,
      enrolledInDrugTestingProgram: false,
      certificateOfCompliance: false,
      inquiryToPreviousEmployers: false,
      nonMotorCarrierActivityForm: false,
      inquiryToStateAgencies: false,
      proofOfReceivingFMCSRHandbook: false,
    },
    annualCertificationOfViolations: '',
    employmentApplication: '',
    annualMVRReview: '',
    internalNotes: '',
    notes: '',
  });

  const apiUrl = 'https://api-backend.full-trucking-service.com/api'; 
  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [licenseExpired, setLicenseExpired] = useState(false);
 


  const token = sessionStorage.getItem('token'); 
  const clientId = localStorage.getItem('clientId'); 
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    withCredentials: true,
  };

  useEffect(() => {
    const fetchDriverLimit = async () => {
      try {
        const response = await axios.get(`${apiUrl}/clients`, config);
        setLimits({
          numberOfDrivers: response.data.numberOfDrivers,
        });
      } catch (error) {
        console.error('Error fetching driver limit:', error);
      }
    };
    
    fetchDriverLimit();
  }, []);

  const fetchDrivers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/drivers`, config);
      setDrivers(response.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);


  useEffect(() => {
    if (driverData.licenseExpiryDate) {
      const today = new Date().toISOString().split('T')[0];
      setLicenseExpired(driverData.licenseExpiryDate < today);
    }
  }, [driverData.licenseExpiryDate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDriverData({ ...driverData, [name]: value });
  };

  const handleCheckboxChange = (e, field) => {
    const [section, fieldName] = field.split('.');
    if (section === 'employeeRecordsChecklist') {
      setDriverData({
        ...driverData,
        employeeRecordsChecklist: {
          ...driverData.employeeRecordsChecklist,
          [fieldName]: e.target.checked,
        },
      });
    } else {
      setDriverData({ ...driverData, [field]: e.target.checked });
    }
  };
  const handleSettlementChange = (e) => {
    const selectedSettlement = e.target.value;
    
    // Resetăm câmpurile în funcție de metoda selectată
    const resetData = {
      normalMileRate: selectedSettlement === 'Per mile pay' ? driverData.normalMileRate : undefined,
      dhMileRate: selectedSettlement === 'Per mile pay' ? driverData.dhMileRate : undefined,
      hourlyRate: selectedSettlement === 'Hourly pay' ? driverData.hourlyRate : undefined,
      hoursWorked: selectedSettlement === 'Hourly pay' ? driverData.hoursWorked : undefined,
      teamDriver: selectedSettlement === 'Team driver pay' ? driverData.teamDriver : undefined,
      teamPercentage: selectedSettlement === 'Team driver pay' ? driverData.teamPercentage : undefined,
      settlementAmount: selectedSettlement !== 'Per mile pay' && selectedSettlement !== 'Hourly pay' ? driverData.settlementAmount : undefined,
    };
  
    setDriverData({
      ...driverData,
      settlement: selectedSettlement,
      ...resetData,
    });
  };
  

  const handleSubmit = async () => {
    if (editingIndex === null && drivers.length >= limits.numberOfDrivers) {
      alert('Nu poți adăuga mai mulți șoferi decât limita stabilită.');
      return;
    }
  
    const dataToSend = {
      ...(driverData.firstName && { firstName: driverData.firstName.trim() }),
      ...(driverData.lastName && { lastName: driverData.lastName.trim() }),
      ...(driverData.email && { email: driverData.email.trim() }),
      ...(driverData.phone && { phone: driverData.phone.trim() }),
      ...(driverData.address && { address: driverData.address.trim() }),
      ...(driverData.licenseNumber && { licenseNumber: driverData.licenseNumber.trim() }),
      ...(driverData.licenseExpiryDate && { licenseExpiryDate: driverData.licenseExpiryDate }),
      ...(driverData.lastDrugTestDate && { lastDrugTestDate: driverData.lastDrugTestDate }),
      ...(driverData.truckNumber && { truckNumber: driverData.truckNumber.trim() }),
      ...(driverData.settlement && { settlement: driverData.settlement.trim() }),
      ...(driverData.settlementAmount && { settlementAmount: Number(driverData.settlementAmount) }),
      ...(driverData.hourlyRate && { hourlyRate: Number(driverData.hourlyRate) }),
      ...(driverData.hoursWorked && { hoursWorked: Number(driverData.hoursWorked) }),
      ...(driverData.normalMileRate && { normalMileRate: Number(driverData.normalMileRate) }),
      ...(driverData.dhMileRate && { dhMileRate: Number(driverData.dhMileRate) }),
      ...(driverData.dateOfBirth && { dateOfBirth: driverData.dateOfBirth }),
      ...(driverData.hireDate && { hireDate: driverData.hireDate }),
      ...(driverData.terminationDate && { terminationDate: driverData.terminationDate }),
      ...(driverData.licenseType && { licenseType: driverData.licenseType.trim() }),
      ...(driverData.licenseState && { licenseState: driverData.licenseState.trim() }),
      ...(driverData.passportExpires && { passportExpires: driverData.passportExpires }),
      ...(driverData.medicalInfo?.lastMedical && { "medicalInfo.lastMedical": driverData.medicalInfo.lastMedical }),
      ...(driverData.medicalInfo?.nextMedical && { "medicalInfo.nextMedical": driverData.medicalInfo.nextMedical }),
      ...(driverData.medicalInfo?.lastAlcoholTest && { "medicalInfo.lastAlcoholTest": driverData.medicalInfo.lastAlcoholTest }),
      ...(driverData.corporateInfo?.fuelCardNumber && { "corporateInfo.fuelCardNumber": driverData.corporateInfo.fuelCardNumber }),
      ...(driverData.corporateInfo?.fuelCardExpires && { "corporateInfo.fuelCardExpires": driverData.corporateInfo.fuelCardExpires }),
      ...(driverData.corporateInfo?.fleetDivision && { "corporateInfo.fleetDivision": driverData.corporateInfo.fleetDivision }),
      ...(driverData.checklist?.copyOfLicense && { "checklist.copyOfLicense": driverData.checklist.copyOfLicense }),
      ...(driverData.checklist?.preEmploymentDrugTest && { "checklist.preEmploymentDrugTest": driverData.checklist.preEmploymentDrugTest }),
      ...(driverData.checklist?.longMedicalForm && { "checklist.longMedicalForm": driverData.checklist.longMedicalForm }),
      ...(driverData.checklist?.proofOfReceivingFMCSR && { "checklist.proofOfReceivingFMCSR": driverData.checklist.proofOfReceivingFMCSR }),
      ...(driverData.recurringDeductions && { recurringDeductions: driverData.recurringDeductions }),
      ...(driverData.recurringAdditions && { recurringAdditions: driverData.recurringAdditions }),
      ...(driverData.annualCertificationOfViolations && { annualCertificationOfViolations: driverData.annualCertificationOfViolations }),
      ...(driverData.employmentApplication && { employmentApplication: driverData.employmentApplication }),
      ...(driverData.annualMVRReview && { annualMVRReview: driverData.annualMVRReview }),
      ...(driverData.internalNotes && { internalNotes: driverData.internalNotes }),
      ...(driverData.notes && { notes: driverData.notes }),
      clientId,
    };
  
    console.log('Sending data to backend:', dataToSend);
  
    try {
      if (editingIndex !== null) {
        const driverId = drivers[editingIndex]._id;
        const response = await axios.put(`${apiUrl}/drivers/${driverId}`, dataToSend, config);
  
        if (response.status === 200) {
          const updatedDriver = response.data.driver;
          setDrivers((prevDrivers) =>
            prevDrivers.map((driver, index) => (index === editingIndex ? updatedDriver : driver))
          );
          alert('Driver updated successfully!');
        } else {
          console.error('Failed to update driver:', response.data.error);
          alert(response.data.error || 'Failed to update driver.');
        }
      } else {
        const response = await axios.post(`${apiUrl}/drivers`, dataToSend, config);
  
        if (response.status === 201) {
          setDrivers((prevDrivers) => [...prevDrivers, response.data.driver]);
          alert('Driver added successfully!');
        } else {
          console.error('Failed to add driver:', response.data.error);
          alert(response.data.error || 'Failed to add driver.');
        }
      }
  
      // Reset form data
      setDriverData({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        hireDate: '',
        truckNumber: '',
        email: '',
        mobilePhone: '',
        phone: '',
        address: '',
        licenseNumber: '',
        licenseExpiryDate: '',
        passportExpiryDate: '',
        lastMedical: '',
        nextMedical: '',
        lastDrugTest: '',
        lastAlcoholTest: '',
        fuelCardNumber: '',
        fuelCardExpiryDate: '',
        fleetDivision: '',
        settlement: '',
        settlementAmount: '',
        licenseType: '',
        licenseState: '',
        emergencyContactFirstName: '',
        emergencyContactLastName: '',
        emergencyContactRelation: '',
        emergencyContactPhone: '',
        employeeRecordsChecklist: {
          copyOfLicense: false,
          preEmploymentDrugTest: false,
          longMedicalForm: false,
          copyOfRoadTest: false,
          enrolledInDrugTestingProgram: false,
          certificateOfCompliance: false,
          inquiryToPreviousEmployers: false,
          nonMotorCarrierActivityForm: false,
          inquiryToStateAgencies: false,
          proofOfReceivingFMCSRHandbook: false,
        },
        annualCertificationOfViolations: '',
        employmentApplication: '',
        annualMVRReview: '',
        internalNotes: '',
        notes: '',
      });
  
      setEditingIndex(null);
    } catch (error) {
      console.error('Error saving driver:', error.response?.data || error.message);
      alert(error.response?.data?.error || 'Failed to save driver. Please try again.');
    }
  };
  

  const handleEdit = (index) => {
    const driverToEdit = drivers[index];
  
    // Asigură-te că `employeeRecordsChecklist` și alte câmpuri există
    const updatedDriverData = {
      firstName: driverToEdit.firstName || '',
      lastName: driverToEdit.lastName || '',
      dateOfBirth: driverToEdit.dateOfBirth || '',
      hireDate: driverToEdit.hireDate || '',
      truckNumber: driverToEdit.truckNumber || '',
      email: driverToEdit.email || '',
      mobilePhone: driverToEdit.mobilePhone || '',
      phone: driverToEdit.phone || '',
      address: driverToEdit.address || '',
      licenseNumber: driverToEdit.licenseNumber || '',
      licenseExpiryDate: driverToEdit.licenseExpiryDate || '',
      passportExpiryDate: driverToEdit.passportExpiryDate || '',
      lastMedical: driverToEdit.lastMedical || '',
      nextMedical: driverToEdit.nextMedical || '',
      lastDrugTest: driverToEdit.lastDrugTest || '',
      lastAlcoholTest: driverToEdit.lastAlcoholTest || '',
      fuelCardNumber: driverToEdit.fuelCardNumber || '',
      fuelCardExpiryDate: driverToEdit.fuelCardExpiryDate || '',
      fleetDivision: driverToEdit.fleetDivision || '',
      settlement: driverToEdit.settlement || '',
      settlementAmount: driverToEdit.settlementAmount || '',
      licenseType: driverToEdit.licenseType || '',
      licenseState: driverToEdit.licenseState || '',
      emergencyContactFirstName: driverToEdit.emergencyContactFirstName || '',
      emergencyContactLastName: driverToEdit.emergencyContactLastName || '',
      emergencyContactRelation: driverToEdit.emergencyContactRelation || '',
      emergencyContactPhone: driverToEdit.emergencyContactPhone || '',
      employeeRecordsChecklist: {
        copyOfLicense: driverToEdit.employeeRecordsChecklist?.copyOfLicense || false,
        preEmploymentDrugTest: driverToEdit.employeeRecordsChecklist?.preEmploymentDrugTest || false,
        longMedicalForm: driverToEdit.employeeRecordsChecklist?.longMedicalForm || false,
        copyOfRoadTest: driverToEdit.employeeRecordsChecklist?.copyOfRoadTest || false,
        enrolledInDrugTestingProgram: driverToEdit.employeeRecordsChecklist?.enrolledInDrugTestingProgram || false,
        certificateOfCompliance: driverToEdit.employeeRecordsChecklist?.certificateOfCompliance || false,
        inquiryToPreviousEmployers: driverToEdit.employeeRecordsChecklist?.inquiryToPreviousEmployers || false,
        nonMotorCarrierActivityForm: driverToEdit.employeeRecordsChecklist?.nonMotorCarrierActivityForm || false,
        inquiryToStateAgencies: driverToEdit.employeeRecordsChecklist?.inquiryToStateAgencies || false,
        proofOfReceivingFMCSRHandbook: driverToEdit.employeeRecordsChecklist?.proofOfReceivingFMCSRHandbook || false,
      },
      annualCertificationOfViolations: driverToEdit.annualCertificationOfViolations || '',
      employmentApplication: driverToEdit.employmentApplication || '',
      annualMVRReview: driverToEdit.annualMVRReview || '',
      internalNotes: driverToEdit.internalNotes || '',
      notes: driverToEdit.notes || '',
    };
  
    setDriverData(updatedDriverData);
    setEditingIndex(index);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  

  const handleDelete = async (index) => {
    try {
      const driverId = drivers[index]._id;
      await axios.delete(`${apiUrl}/drivers/${driverId}`, config);
      const updatedDrivers = drivers.filter((_, i) => i !== index);
      setDrivers(updatedDrivers);
    } catch (error) {
      console.error('Error deleting driver:', error);
    }
  };

  const filteredDrivers = drivers.filter(
    (driver) =>
      driver.clientId === clientId &&
      (driver.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        driver.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className={styles.driversSection}>
      <h3 className={styles.title}>Add or Edit Driver</h3>
      {licenseExpired && <p className={styles.expiredMessage}>The driver's license has expired!</p>}
      <form className={styles.driverForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>First Name*</label>
            <input
              name="firstName"
              value={driverData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Last Name*</label>
            <input
              name="lastName"
              value={driverData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Date Of Birth</label>
            <input
              name="dateOfBirth"
              type="date"
              value={driverData.dateOfBirth}
              onChange={handleChange}
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Hire Date</label>
            <input
              name="hireDate"
              type="date"
              value={driverData.hireDate}
              onChange={handleChange}
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Truck Number*</label>
            <select
              name="truckNumber"
              value={driverData.truckNumber}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Truck</option>
              {trucks.map((truck) => (
                <option key={truck._id} value={truck.truckNumber}>
                  {truck.truckNumber}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={driverData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Mobile Phone*</label>
            <input
              name="mobilePhone"
              value={driverData.mobilePhone}
              onChange={handleChange}
              placeholder="Mobile Phone"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={driverData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="address"
              value={driverData.address}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <h4>Emergency Contact</h4>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>First Name</label>
            <input
              name="emergencyContactFirstName"
              value={driverData.emergencyContactFirstName}
              onChange={handleChange}
              placeholder="First Name"
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Last Name</label>
            <input
              name="emergencyContactLastName"
              value={driverData.emergencyContactLastName}
              onChange={handleChange}
              placeholder="Last Name"
              className={styles.inputField}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Relation</label>
            <input
              name="emergencyContactRelation"
              value={driverData.emergencyContactRelation}
              onChange={handleChange}
              placeholder="Relation"
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone</label>
            <input
              name="emergencyContactPhone"
              value={driverData.emergencyContactPhone}
              onChange={handleChange}
              placeholder="Phone"
              className={styles.inputField}
            />
          </div>
        </div>

        <h4>Pay Settings</h4>
        <div className={styles.formGroup}>
  <label>Settlement*</label>
  <select
    name="settlement"
    value={driverData.settlement}
    onChange={handleSettlementChange}
    required
    className={styles.selectField}
  >
    <option value="">Select Payment Method</option>
    <option value="Hourly pay">Hourly pay</option>
    <option value="Per mile pay">Per mile pay</option>
    <option value="Fixed salary">Fixed salary</option>
    <option value="Percentage of the load">Percentage of the load</option>
    <option value="Team driver pay">Team driver pay</option>
  </select>
</div>
        {driverData.settlement === 'Per mile pay' && (
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label>Enter amount per normal mile ($)*</label>
      <input
        name="normalMileRate"
        type="number"
        step="0.01"
        value={driverData.normalMileRate || ''}
        onChange={handleChange}
        placeholder="Enter amount per normal mile"
        required
        className={styles.inputField}
      />
    </div>
    <div className={styles.formGroup}>
      <label>Enter amount per DH mile ($)*</label>
      <input
        name="dhMileRate"
        type="number"
        step="0.01"
        value={driverData.dhMileRate || ''}
        onChange={handleChange}
        placeholder="Enter amount per DH mile"
        required
        className={styles.inputField}
      />
    </div>
  </div>
)}

{driverData.settlement === 'Hourly pay' && (
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label>Hourly Rate ($)*</label>
      <input
        name="hourlyRate"
        type="number"
        step="0.01"
        value={driverData.hourlyRate || ''}
        onChange={handleChange}
        placeholder="Enter hourly rate"
        required
        className={styles.inputField}
      />
    </div>
    <div className={styles.formGroup}>
      <label>Hours Worked*</label>
      <input
        name="hoursWorked"
        type="number"
        step="0.1"
        value={driverData.hoursWorked || ''}
        onChange={handleChange}
        placeholder="Enter hours worked"
        required
        className={styles.inputField}
      />
    </div>
  </div>
)}

{driverData.settlement === 'Team driver pay' && (
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label>Select Co-Driver</label>
      <select
        name="teamDriver"
        value={driverData.teamDriver || ''}
        onChange={handleChange}
        required
        className={styles.selectField}
      >
        <option value="">Select Co-Driver</option>
        {drivers.map((driver) => (
          <option key={driver._id} value={driver._id}>
            {driver.firstName} {driver.lastName}
          </option>
        ))}
      </select>
    </div>
    <div className={styles.formGroup}>
      <label>Enter Team Pay Percentage (%)</label>
      <input
        name="teamPercentage"
        type="number"
        step="1"
        value={driverData.teamPercentage || ''}
        onChange={handleChange}
        placeholder="Enter team pay percentage"
        required
        className={styles.inputField}
      />
    </div>
    <div className={styles.formGroup}>
      <label>Enter Pay Amount for Main Driver ($)*</label>
      <input
        name="settlementAmount"
        type="number"
        step="0.01"
        value={driverData.settlementAmount || ''}
        onChange={handleChange}
        placeholder="Enter pay amount for main driver"
        required
        className={styles.inputField}
      />
    </div>
  </div>
)}

{driverData.settlement === 'Percentage of the load' && (
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label>Enter Percentage of Load Revenue (%)</label>
      <input
        name="settlementAmount"
        type="number"
        step="1"
        value={driverData.settlementAmount || ''}
        onChange={handleChange}
        placeholder="Enter percentage of load (e.g., 20 for 20%)"
        required
        className={styles.inputField}
      />
    </div>
  </div>
)}

{driverData.settlement === 'Fixed salary' && (
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label>Fixed Salary Amount ($)</label>
      <input
        name="settlementAmount"
        type="number"
        step="0.01"
        value={driverData.settlementAmount || ''}
        onChange={handleChange}
        placeholder="Enter fixed salary amount"
        required
        className={styles.inputField}
      />
    </div>
  </div>
)}


        <h4>Licensing Info</h4>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>License Type</label>
            <select
              name="licenseType"
              value={driverData.licenseType}
              onChange={handleChange}
              className={styles.selectField}
            >
              <option value="">Select License Type</option>
              <option value="CDL-A">CDL-A</option>
              <option value="CDL-B">CDL-B</option>
              <option value="NonCommercial">NonCommercial</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>License State</label>
            <input
              name="licenseState"
              value={driverData.licenseState}
              onChange={handleChange}
              placeholder="Enter State"
              className={styles.inputField}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label>License Number*</label>
          <input
            name="licenseNumber"
            value={driverData.licenseNumber}
            onChange={handleChange}
            placeholder="License Number"
            required
            className={styles.inputField}
          />
        </div>
        <div className={styles.formGroup}>
          <label>License Expires*</label>
          <input
            name="licenseExpiryDate"
            type="date"
            value={driverData.licenseExpiryDate}
            onChange={handleChange}
            required
            className={styles.inputField}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Passport Expires</label>
          <input
            name="passportExpiryDate"
            type="date"
            value={driverData.passportExpiryDate}
            onChange={handleChange}
            className={styles.inputField}
          />
        </div>

        <h4>Medical Info</h4>
        <div className={styles.formGroup}>
          <label>Last Medical</label>
          <input
            name="lastMedical"
            type="date"
            value={driverData.lastMedical}
            onChange={handleChange}
            className={styles.inputField}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Next Medical</label>
          <input
            name="nextMedical"
            type="date"
            value={driverData.nextMedical}
            onChange={handleChange}
            className={styles.inputField}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Last Drug Test</label>
          <input
            name="lastDrugTest"
            type="date"
            value={driverData.lastDrugTest}
            onChange={handleChange}
            className={styles.inputField}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Last Alcohol Test</label>
          <input
            name="lastAlcoholTest"
            type="date"
            value={driverData.lastAlcoholTest}
            onChange={handleChange}
            className={styles.inputField}
          />
        </div>

        <h4>Corporate Info</h4>
        <div className={styles.formGroup}>
          <label>Fuel Card Number</label>
          <input
            name="fuelCardNumber"
            value={driverData.fuelCardNumber}
            onChange={handleChange}
            placeholder="Fuel Card Number"
            className={styles.inputField}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Fuel Card Expires</label>
          <input
            name="fuelCardExpiryDate"
            type="date"
            value={driverData.fuelCardExpiryDate}
            onChange={handleChange}
            className={styles.inputField}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Fleet / Division</label>
          <input
            name="fleetDivision"
            value={driverData.fleetDivision}
            onChange={handleChange}
            placeholder="Fleet / Division"
            className={styles.inputField}
          />
        </div>

        <h4>Employee Records Checklist</h4>
        <div className={styles.formGroup}>
        <label>
  <input
    type="checkbox"
    checked={driverData.employeeRecordsChecklist?.copyOfLicense || false}
    onChange={(e) => handleCheckboxChange(e, 'employeeRecordsChecklist.copyOfLicense')}
  />
  Copy of License
</label>

          <label>
          <input
    type="checkbox"
    checked={driverData.employeeRecordsChecklist?.preEmploymentDrugTest || false}
    onChange={(e) => handleCheckboxChange(e, 'employeeRecordsChecklist.preEmploymentDrugTest')}
  />
            Pre-Employment Drug Test
          </label>
          <label>
  <input
    type="checkbox"
    checked={driverData.employeeRecordsChecklist?.longMedicalForm || false}
    onChange={(e) => handleCheckboxChange(e, 'employeeRecordsChecklist.longMedicalForm')}
  />
  Long Medical Form
</label>

          <label>
            <input
              type="checkbox"
              checked={driverData.employeeRecordsChecklist.copyOfRoadTest}
              onChange={(e) => handleCheckboxChange(e, 'employeeRecordsChecklist.copyOfRoadTest')}
            />
            Copy of Road Test
          </label>
          <label>
            <input
              type="checkbox"
              checked={driverData.employeeRecordsChecklist.enrolledInDrugTestingProgram}
              onChange={(e) => handleCheckboxChange(e, 'employeeRecordsChecklist.enrolledInDrugTestingProgram')}
            />
            Enrolled in Drug Testing Program
          </label>
          {/* Add more checklist items as needed */}
        </div>

        <h4>Internal Notes</h4>
        <div className={styles.formGroup}>
          <textarea
            name="internalNotes"
            value={driverData.internalNotes}
            onChange={handleChange}
            placeholder="Internal Notes"
            className={styles.textareaField}
          />
        </div>

        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Driver' : 'Add Driver'}
        </button>
      </form>

      <h3 className={styles.title}>Search Drivers</h3>
      <input
        type="text"
        placeholder="Search by driver name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />

      <h3 className={styles.title}>Existing Drivers</h3>
      <ul className={styles.driverList}>
        {filteredDrivers.map((driver, index) => (
          <li key={index} className={styles.driverListItem}>
            {driver.firstName} {driver.lastName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>Edit</button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};


const Trucks = ({ trucks, setTrucks, drivers = [], trailers = [] }) => {
  const [limits, setLimits] = useState({ numberOfTrucks: 0 });
  const [truckData, setTruckData] = useState({
    truckNumber: '',
    licensePlate: '',
    VIN: '',
    truckType: '',
    fuelType: '',
    miles: '',
    inspectionDate: '',
    maintenanceDate: '',
    insurancePolicyNumber: '',
    insuranceExpiryDate: '',
    fleetDivision: '',
    driver: '',
    trailer: '',
    settlement: '',
    settlementAmount: '',
    normalMileRate: '',
    dhMileRate: '',
    hourlyRate: '',
    hoursWorked: '',
    teamDriver: '',
    teamPercentage: '',
    notes: '',
  });
  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const token = sessionStorage.getItem('token'); // Obține tokenul din sessionStorage
  const clientId = localStorage.getItem('clientId'); // Obține clientId din localStorage
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`, // Adaugă tokenul cu prefixul Bearer
      'Content-Type': 'application/json',
    },
    withCredentials: true, // Trimite cookies pentru autentificare
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTruckData({ ...truckData, [name]: value });
  };

  const handleSettlementChange = (e) => {
    const selectedSettlement = e.target.value;
    const resetData = {
      normalMileRate: selectedSettlement === 'Per mile pay' ? truckData.normalMileRate : undefined,
      dhMileRate: selectedSettlement === 'Per mile pay' ? truckData.dhMileRate : undefined,
      hourlyRate: selectedSettlement === 'Hourly pay' ? truckData.hourlyRate : undefined,
      hoursWorked: selectedSettlement === 'Hourly pay' ? truckData.hoursWorked : undefined,
      teamDriver: selectedSettlement === 'Team driver pay' ? truckData.teamDriver : undefined,
      teamPercentage: selectedSettlement === 'Team driver pay' ? truckData.teamPercentage : undefined,
      settlementAmount: selectedSettlement !== 'Per mile pay' && selectedSettlement !== 'Hourly pay' ? truckData.settlementAmount : undefined,
    };

    setTruckData({
      ...truckData,
      settlement: selectedSettlement,
      ...resetData,
    });
  };

  const handleSubmit = async () => {
    if (editingIndex === null && trucks.length >= limits.numberOfTrucks) {
      alert('Nu poți adăuga mai multe camioane decât limita stabilită.');
      return;
    }
    const validDriver = truckData.driver || null;
    const validTrailer = truckData.trailer || null;
    const dataToSend = {
        truckNumber: truckData.truckNumber, // Singurul câmp obligatoriu
        ...(truckData.licensePlate && { licensePlate: truckData.licensePlate }),
        ...(truckData.VIN && { VIN: truckData.VIN }),
        ...(truckData.truckType && { truckType: truckData.truckType }),
        ...(truckData.fuelType && { fuelType: truckData.fuelType }),
        ...(truckData.miles && { miles: truckData.miles }),
        ...(truckData.inspectionDate && { inspectionDate: truckData.inspectionDate }),
        ...(truckData.maintenanceDate && { maintenanceDate: truckData.maintenanceDate }),
        ...(truckData.insurancePolicyNumber && { insurancePolicyNumber: truckData.insurancePolicyNumber }),
        ...(truckData.insuranceExpiryDate && { insuranceExpiryDate: truckData.insuranceExpiryDate }),
        ...(truckData.fleetDivision && { fleetDivision: truckData.fleetDivision }),
        ...(validDriver && { driver: validDriver }),
        ...(validTrailer && { trailer: validTrailer }),
        ...(truckData.notes && { notes: truckData.notes }),
        ...(truckData.settlement && { settlement: truckData.settlement }),
        ...(truckData.settlementAmount && { settlementAmount: truckData.settlementAmount }),
        ...(truckData.normalMileRate && { normalMileRate: truckData.normalMileRate }),
        ...(truckData.dhMileRate && { dhMileRate: truckData.dhMileRate }),
        ...(truckData.hourlyRate && { hourlyRate: truckData.hourlyRate }),
        ...(truckData.hoursWorked && { hoursWorked: truckData.hoursWorked }),
        ...(truckData.teamDriver && { teamDriver: truckData.teamDriver }),
        ...(truckData.teamPercentage && { teamPercentage: truckData.teamPercentage }),
        clientId,
      };
      
    

    if (editingIndex !== null) {
      try {
        const response = await axios.put(`${apiUrl}/trucks/${trucks[editingIndex]._id}`, dataToSend, config);
        const updatedTrucks = [...trucks];
        updatedTrucks[editingIndex] = response.data.truck;
        setTrucks(updatedTrucks);
        setEditingIndex(null);
      } catch (error) {
        console.error('Error updating truck:', error);
      }
    } else {
      try {
        const response = await axios.post(`${apiUrl}/trucks`, dataToSend, config);
        setTrucks([...trucks, response.data.truck]);
      } catch (error) {
        console.error('Error adding truck:', error);
      }
    }

    setTruckData({
      truckNumber: '',
      licensePlate: '',
      VIN: '',
      truckType: '',
      fuelType: '',
      miles: '',
      inspectionDate: '',
      maintenanceDate: '',
      insurancePolicyNumber: '',
      insuranceExpiryDate: '',
      fleetDivision: '',
      driver: '',
      trailer: '',
      settlement: '',
      settlementAmount: '',
      normalMileRate: '',
      dhMileRate: '',
      hourlyRate: '',
      hoursWorked: '',
      teamDriver: '',
      teamPercentage: '',
      notes: '',
    });
  };

  useEffect(() => {
    const fetchTruckLimit = async () => {
      try {
        const response = await axios.get(`${apiUrl}/clients`, config);
        setLimits({
          numberOfTrucks: response.data.numberOfTrucks,
        });
      } catch (error) {
        console.error('Error fetching truck limit:', error);
      }
    };
    
    fetchTruckLimit();
  }, []);

  const handleEdit = (index) => {
    const truckToEdit = trucks[index];
    setEditingIndex(index);
    setTruckData({
      truckNumber: truckToEdit.truckNumber || '',
      licensePlate: truckToEdit.licensePlate || '',
      VIN: truckToEdit.VIN || '',
      truckType: truckToEdit.truckType || '',
      fuelType: truckToEdit.fuelType || '',
      miles: truckToEdit.miles || '',
      inspectionDate: truckToEdit.inspectionDate || '',
      maintenanceDate: truckToEdit.maintenanceDate || '',
      insurancePolicyNumber: truckToEdit.insurancePolicyNumber || '',
      insuranceExpiryDate: truckToEdit.insuranceExpiryDate || '',
      fleetDivision: truckToEdit.fleetDivision || '',
      driver: truckToEdit.driver || '',
      trailer: truckToEdit.trailer || '',
      settlement: truckToEdit.settlement || '',
      settlementAmount: truckToEdit.settlementAmount || '',
      normalMileRate: truckToEdit.normalMileRate || '',
      dhMileRate: truckToEdit.dhMileRate || '',
      notes: truckToEdit.notes || '',
      
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  

  const handleDelete = async (index) => {
    try {
      const truckId = trucks[index]._id;
      await axios.delete(`${apiUrl}/trucks/${truckId}`, config);
      const updatedTrucks = trucks.filter((_, i) => i !== index);
      setTrucks(updatedTrucks);
    } catch (error) {
      console.error('Error deleting truck:', error);
    }
  };

  const filteredTrucks = trucks.filter(truck =>
    truck.truckNumber && truck.truckNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={styles.trucksSection}>
      <h3 className={styles.title}>Add or Edit Truck</h3>
      <form className={styles.truckForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Truck Number*</label>
            <input
              name="truckNumber"
              value={truckData.truckNumber}
              onChange={handleChange}
              placeholder="Truck Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>License Plate*</label>
            <input
              name="licensePlate"
              value={truckData.licensePlate}
              onChange={handleChange}
              placeholder="License Plate"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <h4>Pay Settings</h4>
        <div className={styles.formGroup}>
          <label>Settlement*</label>
          <select
            name="settlement"
            value={truckData.settlement}
            onChange={handleSettlementChange}
            required
            className={styles.selectField}
          >
            <option value="">Select Payment Method</option>
            <option value="Hourly pay">Hourly pay</option>
            <option value="Per mile pay">Per mile pay</option>
            <option value="Fixed salary">Fixed salary</option>
            <option value="Percentage of the load">Percentage of the load</option>
            <option value="Team driver pay">Team driver pay</option>
          </select>
        </div>

        {truckData.settlement === 'Per mile pay' && (
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Enter amount per normal mile ($)*</label>
              <input
                name="normalMileRate"
                type="number"
                step="0.01"
                value={truckData.normalMileRate || ''}
                onChange={handleChange}
                required
                className={styles.inputField}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Enter amount per DH mile ($)*</label>
              <input
                name="dhMileRate"
                type="number"
                step="0.01"
                value={truckData.dhMileRate || ''}
                onChange={handleChange}
                required
                className={styles.inputField}
              />
            </div>
          </div>
        )}

        {truckData.settlement === 'Hourly pay' && (
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Hourly Rate ($)*</label>
              <input
                name="hourlyRate"
                type="number"
                step="0.01"
                value={truckData.hourlyRate || ''}
                onChange={handleChange}
                required
                className={styles.inputField}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Hours Worked*</label>
              <input
                name="hoursWorked"
                type="number"
                step="0.1"
                value={truckData.hoursWorked || ''}
                onChange={handleChange}
                required
                className={styles.inputField}
              />
            </div>
          </div>
        )}

        {truckData.settlement === 'Team driver pay' && (
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Select Co-Driver</label>
              <input
                name="teamDriver"
                value={truckData.teamDriver || ''}
                onChange={handleChange}
                className={styles.inputField}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Enter Team Pay Percentage (%)</label>
              <input
                name="teamPercentage"
                type="number"
                step="1"
                value={truckData.teamPercentage || ''}
                onChange={handleChange}
                className={styles.inputField}
              />
            </div>
          </div>
        )}

        {truckData.settlement === 'Percentage of the load' && (
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Enter Percentage of Load Revenue (%)</label>
              <input
                name="settlementAmount"
                type="number"
                step="1"
                value={truckData.settlementAmount || ''}
                onChange={handleChange}
                className={styles.inputField}
              />
            </div>
          </div>
        )}

        {truckData.settlement === 'Fixed salary' && (
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Fixed Salary Amount ($)</label>
              <input
                name="settlementAmount"
                type="number"
                step="0.01"
                value={truckData.settlementAmount || ''}
                onChange={handleChange}
                className={styles.inputField}
              />
            </div>
          </div>
        )}

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>VIN*</label>
            <input
              name="VIN"
              value={truckData.VIN}
              onChange={handleChange}
              placeholder="VIN"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Truck Type*</label>
            <select
              name="truckType"
              value={truckData.truckType}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Type</option>
              <option value="Semi-truck">Semi-truck</option>
              <option value="Trailer">Trailer</option>
            </select>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Fuel Type*</label>
            <select
              name="fuelType"
              value={truckData.fuelType}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Fuel Type</option>
              <option value="Diesel">Diesel</option>
              <option value="Gasoline">Gasoline</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Miles*</label>
            <input
              name="miles"
              value={truckData.miles}
              onChange={handleChange}
              placeholder="Miles"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Driver*</label>
            <select
              name="driver"
              value={truckData.driver}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Driver</option>
              {Array.isArray(drivers) && drivers.map((driver) => (
                <option key={driver._id} value={driver._id}>
                  {driver.firstName} {driver.lastName}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Trailer*</label>
            <select
              name="trailer"
              value={truckData.trailer}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Trailer</option>
              {Array.isArray(trailers) && trailers.map((trailer) => (
                <option key={trailer._id} value={trailer._id}>
                  {trailer.trailerNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Inspection Date*</label>
            <input
              name="inspectionDate"
              type="date"
              value={truckData.inspectionDate}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Maintenance Date*</label>
            <input
              name="maintenanceDate"
              type="date"
              value={truckData.maintenanceDate}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Insurance Policy Number*</label>
            <input
              name="insurancePolicyNumber"
              value={truckData.insurancePolicyNumber}
              onChange={handleChange}
              placeholder="Insurance Policy Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Insurance Expiry Date*</label>
            <input
              name="insuranceExpiryDate"
              type="date"
              value={truckData.insuranceExpiryDate}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Fleet Division*</label>
          <input
            name="fleetDivision"
            value={truckData.fleetDivision}
            onChange={handleChange}
            placeholder="Fleet Division"
            required
            className={styles.inputField}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Notes</label>
          <textarea
            name="notes"
            value={truckData.notes}
            onChange={handleChange}
            placeholder="Notes"
            className={styles.textareaField}
          ></textarea>
        </div>

        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Truck' : 'Add Truck'}
        </button>
      </form>

      <h3 className={styles.title}>Search Trucks</h3>
      <input
        type="text"
        placeholder="Search by truck number"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />

      <h3 className={styles.title}>Existing Trucks</h3>
      <ul className={styles.truckList}>
        {filteredTrucks.map((truck, index) => (
          <li key={index} className={styles.truckListItem}>
            {truck.truckNumber}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};


const Trailers = ({ trailers = [], setTrailers }) => {
  const [trailerData, setTrailerData] = useState({
    trailerNumber: '',
    licensePlate: '',
    VIN: '',
    trailerType: '',
    capacity: '',
    length: '',
    inspectionDate: '',
    maintenanceDate: '',
    insurancePolicyNumber: '',
    insuranceExpiryDate: '',
    fleetDivision: '',
    notes: ''
  });

  const apiUrl = 'https://api-backend.full-trucking-service.com/api'; 
  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [alarms, setAlarms] = useState({ inspectionAlarm: false, maintenanceAlarm: false, insuranceAlarm: false });

  const token = sessionStorage.getItem('token'); 
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    withCredentials: true,
  };

  // Fetch trailers
  const fetchTrailers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/trailers`, config);
      setTrailers(response.data);
    } catch (error) {
      console.error('Error fetching trailers:', error);
    }
  };

  useEffect(() => {
    fetchTrailers();
  }, []);

  // Function to check if any date has expired or reached the current date
  const checkAlarms = () => {
    const today = new Date().toISOString().split('T')[0];
    setAlarms({
      inspectionAlarm: trailerData.inspectionDate && trailerData.inspectionDate <= today,
      maintenanceAlarm: trailerData.maintenanceDate && trailerData.maintenanceDate <= today,
      insuranceAlarm: trailerData.insuranceExpiryDate && trailerData.insuranceExpiryDate <= today,
    });
  };

  useEffect(() => {
    checkAlarms();
  }, [trailerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrailerData({ ...trailerData, [name]: value });
  };

  const handleSubmit = async () => {
    if (editingIndex !== null) {
      const updatedTrailers = [...trailers];
      updatedTrailers[editingIndex] = { ...trailerData };
      setTrailers(updatedTrailers);
      setEditingIndex(null);
    } else {
      try {
        const response = await axios.post(`${apiUrl}/trailers`, trailerData, config);
        if (response.status === 201) {
          setTrailers([...trailers, response.data]);
        }
      } catch (error) {
        console.error('Error adding trailer:', error);
      }
    }

    setTrailerData({
      trailerNumber: '',
      licensePlate: '',
      VIN: '',
      trailerType: '',
      capacity: '',
      length: '',
      inspectionDate: '',
      maintenanceDate: '',
      insurancePolicyNumber: '',
      insuranceExpiryDate: '',
      fleetDivision: '',
      notes: ''
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setTrailerData(trailers[index]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDelete = async (index) => {
    try {
      const trailerId = trailers[index]._id;
      await axios.delete(`${apiUrl}/trailers/${trailerId}`, config);
      const updatedTrailers = trailers.filter((_, i) => i !== index);
      setTrailers(updatedTrailers);
    } catch (error) {
      console.error('Error deleting trailer:', error);
    }
  };

  const filteredTrailers = trailers.filter(
    (trailer) => 
      trailer?.trailerNumber?.toLowerCase().includes(searchQuery.toLowerCase()) || 
      trailer?.VIN?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  return (
    <div className={styles.trailersSection}>
      <h3 className={styles.title}>Add or Edit Trailer</h3>
      {alarms.inspectionAlarm && <p className={styles.alarmMessage}>Inspection date has expired!</p>}
      {alarms.maintenanceAlarm && <p className={styles.alarmMessage}>Maintenance date has expired!</p>}
      {alarms.insuranceAlarm && <p className={styles.alarmMessage}>Insurance has expired!</p>}

      <form className={styles.trailerForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Trailer Number*</label>
            <input
              name="trailerNumber"
              value={trailerData.trailerNumber}
              onChange={handleChange}
              placeholder="Trailer Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>License Plate*</label>
            <input
              name="licensePlate"
              value={trailerData.licensePlate}
              onChange={handleChange}
              placeholder="License Plate"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>VIN*</label>
            <input
              name="VIN"
              value={trailerData.VIN}
              onChange={handleChange}
              placeholder="VIN"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Trailer Type*</label>
            <select
              name="trailerType"
              value={trailerData.trailerType}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Type</option>
              <option value="Platform">Platform</option>
              <option value="Refrigerated">Refrigerated</option>
              <option value="Container">Container</option>
            </select>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Capacity*</label>
            <input
              name="capacity"
              value={trailerData.capacity}
              onChange={handleChange}
              placeholder="Capacity"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Length*</label>
            <input
              name="length"
              value={trailerData.length}
              onChange={handleChange}
              placeholder="Length"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Inspection Date*</label>
            <input
              name="inspectionDate"
              type="date"
              value={trailerData.inspectionDate}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Maintenance Date*</label>
            <input
              name="maintenanceDate"
              type="date"
              value={trailerData.maintenanceDate}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Insurance Policy Number*</label>
            <input
              name="insurancePolicyNumber"
              value={trailerData.insurancePolicyNumber}
              onChange={handleChange}
              placeholder="Insurance Policy Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Insurance Expiry Date*</label>
            <input
              name="insuranceExpiryDate"
              type="date"
              value={trailerData.insuranceExpiryDate}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Fleet Division*</label>
          <input
            name="fleetDivision"
            value={trailerData.fleetDivision}
            onChange={handleChange}
            placeholder="Fleet Division"
            required
            className={styles.inputField}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Notes</label>
          <textarea
            name="notes"
            value={trailerData.notes}
            onChange={handleChange}
            placeholder="Notes"
            className={styles.textareaField}
          ></textarea>
        </div>

        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Trailer' : 'Add Trailer'}
        </button>
      </form>

      <h3 className={styles.title}>Search Trailers</h3>
      <input
        type="text"
        placeholder="Search by trailer number or VIN"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />

      <h3 className={styles.title}>Existing Trailers</h3>
      <ul className={styles.trailerList}>
        {filteredTrailers.map((trailer, index) => (
          <li key={index} className={styles.trailerListItem}>
            {trailer.trailerNumber} ({trailer.VIN})
            <button onClick={() => handleEdit(index)} className={styles.editButton}>Edit</button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};



// Componenta Brokers
const Brokers = ({ brokers, setBrokers }) => {
  const [brokerData, setBrokerData] = useState({
    brokerName: '',
    brokerAddress: '',
    email: '',
    phone: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
  const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,  // Adaugă tokenul cu prefixul Bearer
        // Adaugă Content-Type
    },
    withCredentials: true,  // Trimite cookies pentru autentificare
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrokerData({ ...brokerData, [name]: value });
  };

  const handleSubmit = async () => {
    
    const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
    const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
      withCredentials: true,  // Trimite și cookies pentru autentificare
    };
    if (editingIndex !== null) {
      // Update existing broker
      const updatedBrokers = [...brokers];
      updatedBrokers[editingIndex] = brokerData;
      setBrokers(updatedBrokers);
      setEditingIndex(null);
    } else {
      // Add new broker
      try {
        const dataToSend = { ...brokerData, clientId };
        const response = await axios.post(`${apiUrl}/brokers`, dataToSend, config);
        setBrokers([...brokers, response.data]);
      } catch (error) {
        console.error('Error adding broker:', error);
      }
    }
    setBrokerData({
      brokerName: '',
      brokerAddress: '',
      email: '',
      phone: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setBrokerData(brokers[index]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDelete = async (index) => {
    try {
      const clientId = localStorage.getItem('clientId');  
      const token = sessionStorage.getItem('token');
      const brokerId = brokers[index]._id; // Assuming each broker has an _id field
      await axios.delete(`${apiUrl}/brokers/${brokerId}`, { headers: { 
        'Authorization': `Bearer ${token}`,  
      },
      withCredentials: true,
    });
      const updatedBrokers = brokers.filter((_, i) => i !== index);
      setBrokers(updatedBrokers);
    } catch (error) {
      console.error('Error deleting broker:', error);
    }
  };

  const filteredBrokers = brokers.filter(broker =>
    broker.brokerName && broker.brokerName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  return (
    <div className={styles.brokersSection}>
      <h3 className={styles.title}>Add or Edit Broker</h3>
      <form className={styles.brokerForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Broker Name*</label>
            <input
              name="brokerName"
              value={brokerData.brokerName}
              onChange={handleChange}
              placeholder="Broker Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="address"
              value={brokerData.brokerAddress}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={brokerData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={brokerData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Broker' : 'Add Broker'}
        </button>
      </form>
  
      <h3 className={styles.title}>Search Brokers</h3>
      <input
        type="text"
        placeholder="Search by broker name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />
  
      <h3 className={styles.title}>Existing Brokers</h3>
      <ul className={styles.brokerList}>
        {filteredBrokers.map((broker, index) => (
          <li key={index} className={styles.brokerListItem}>
            {broker.brokerName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
  
};


// Componenta Shippers
const Shippers = ({ shippers, setShippers }) => {
  const [shipperData, setShipperData] = useState({
    shipperName: '',
    shipperAddress: '',
    email: '',
    phone: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
  const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,  // Adaugă tokenul cu prefixul Bearer
        // Adaugă Content-Type
    },
    withCredentials: true,  // Trimite cookies pentru autentificare
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShipperData({ ...shipperData, [name]: value });
  };

  const handleSubmit = async () => { 
    const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
    const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
      withCredentials: true,  // Trimite și cookies pentru autentificare
    };
    if (editingIndex !== null) {
      // Update existing shipper
      const updatedShippers = [...shippers];
      updatedShippers[editingIndex] = shipperData;
      setShippers(updatedShippers);
      setEditingIndex(null);
    } else {
      // Add new shipper
      try {
        const dataToSend = { ...shipperData, clientId };  // Adaugă clientId la datele de trimis
        const response = await axios.post(`${apiUrl}/shippers`, shipperData,config);
        setShippers([...shippers, response.data]);
      } catch (error) {
        console.error('Error adding shipper:', error);
      }
    }
    setShipperData({
      shipperName: '',
      shipperAddress: '',
      email: '',
      phone: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setShipperData(shippers[index]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDelete = async (index) => {
    try {
      const clientId = localStorage.getItem('clientId');  
      const token = sessionStorage.getItem('token');
      const shipperId = shippers[index]._id;
      await axios.delete(`${apiUrl}/shippers/${shipperId}`, { 
        headers: { 
          'Authorization': `Bearer ${token}`,  // Asigură-te că folosești tokenul corect
        },
        withCredentials: true,
      });
      const updatedShippers = shippers.filter((_, i) => i !== index);
      setShippers(updatedShippers);
    } catch (error) {
      console.error('Error deleting shipper:', error);
    }
  };
  
  const filteredShippers = shippers.filter(shipper =>
    shipper.shipperName && shipper.shipperName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  
  return (
    <div className={styles.shippersSection}>
      <h3 className={styles.title}>Add or Edit Shipper</h3>
      <form className={styles.shipperForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Shipper Name*</label>
            <input
              name="shipperName"
              value={shipperData.shipperName}
              onChange={handleChange}
              placeholder="Shipper Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="shipperAddress"
              value={shipperData.shipperAddress}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={shipperData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={shipperData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Shipper' : 'Add Shipper'}
        </button>
      </form>
  
      <h3 className={styles.title}>Search Shippers</h3>
      <input
        type="text"
        placeholder="Search by shipper name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />
  
      <h3 className={styles.title}>Existing Shippers</h3>
      <ul className={styles.shipperList}>
        {filteredShippers.map((shipper, index) => (
          <li key={index} className={styles.shipperListItem}>
            {shipper.shipperName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}  


// Componenta Consignees
const Consignees = ({ consignees, setConsignees }) => {
  const [consigneeData, setConsigneeData] = useState({
    consigneeName: '',
    consigneeAddress: '',
    email: '',
    phone: '',
    notes: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  
  const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
  const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,  // Adaugă tokenul cu prefixul Bearer
        // Adaugă Content-Type
    },
    withCredentials: true,  // Trimite cookies pentru autentificare
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setConsigneeData({ ...consigneeData, [name]: value });
  };

  const handleSubmit = async () => {
    const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
    const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
      withCredentials: true,  // Trimite și cookies pentru autentificare
    };
    if (editingIndex !== null) {
      // Update existing consignee
      const updatedConsignees = [...consignees];
      updatedConsignees[editingIndex] = consigneeData;
      setConsignees(updatedConsignees);
      setEditingIndex(null);
    } else {
      // Add new consignee
      try {
        const dataToSend = { ...consigneeData, clientId };
        const response = await axios.post(`${apiUrl}/consignees`, consigneeData, config);
       setConsignees([...consignees, response.data]);
      } catch (error) {
        console.error('Error adding consignee:', error);
      }
    }
    setConsigneeData({
      consigneeName: '',
      consigneeAddress: '',
      email: '',
      phone: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setConsigneeData(consignees[index]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDelete = async (index) => {
    try {
      const clientId = localStorage.getItem('clientId');  
      const token = sessionStorage.getItem('token');
      const consigneeId = consignees[index]._id;
      await axios.delete(`${apiUrl}/consignees/${consigneeId}`, {headers: { 
        'Authorization': `Bearer ${token}`,  // Asigură-te că folosești tokenul corect
      },
      withCredentials: true,
    });
      const updatedConsignees = consignees.filter((_, i) => i !== index);
      setConsignees(updatedConsignees);
    } catch (error) {
      console.error('Error deleting consignee:', error);
    }
  };

  const filteredConsignees = consignees.filter(consignee =>
    consignee.consigneeName && consignee.consigneeName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  return (
    <div className={styles.consigneesSection}>
      <h3 className={styles.title}>Add or Edit Consignee</h3>
      <form className={styles.consigneeForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Consignee Name*</label>
            <input
              name="consigneeName"
              value={consigneeData.consigneeName}
              onChange={handleChange}
              placeholder="Consignee Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="consigneeAddress"
              value={consigneeData.consigneeAddress}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={consigneeData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={consigneeData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Consignee' : 'Add Consignee'}
        </button>
      </form>
  
      <h3 className={styles.title}>Search Consignees</h3>
      <input
        type="text"
        placeholder="Search by consignee name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />
  
      <h3 className={styles.title}>Existing Consignees</h3>
      <ul className={styles.consigneeList}>
        {filteredConsignees.map((consignee, index) => (
          <li key={index} className={styles.consigneeListItem}>
            {consignee.consigneeName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
  
};
const LoadFinder = ({ loads }) => {
  const [search, setSearch] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [trailers, setTrailers] = useState([]);

  const apiUrl = 'https://api-backend.full-trucking-service.com/api';
  const token = sessionStorage.getItem('token');
  const config = { 
    headers: { 
      Authorization: `Bearer ${token}` 
    },
    withCredentials: true
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [driversRes, trucksRes, trailersRes] = await Promise.all([
          axios.get(`${apiUrl}/drivers`, config),
          axios.get(`${apiUrl}/trucks`, config),
          axios.get(`${apiUrl}/trailers`, config)
        ]);
        setDrivers(driversRes.data);
        setTrucks(trucksRes.data);
        setTrailers(trailersRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getDriverName = (driverId) => {
    const driver = drivers.find((d) => d._id === driverId);
    return driver ? `${driver.firstName} ${driver.lastName}` : 'Unknown Driver';
  };

  const getTruckNumber = (truckId) => {
    const truck = trucks.find((t) => t._id === truckId);
    return truck ? truck.truckNumber : 'Unknown Truck';
  };

  const getTrailerNumber = (trailerId) => {
    const trailer = trailers.find((t) => t._id === trailerId);
    return trailer ? trailer.trailerNumber : 'Unknown Trailer';
  };

  // Filtrarea pentru a permite căutarea în mai multe câmpuri
  const filteredLoads = loads.filter((load) =>
    load.loadNumber.toLowerCase().includes(search.toLowerCase()) ||
    (load.truck && getTruckNumber(load.truck).toLowerCase().includes(search.toLowerCase())) ||
    (load.driver && getDriverName(load.driver).toLowerCase().includes(search.toLowerCase())) ||
    (load.trailers && getTrailerNumber(load.trailers).toLowerCase().includes(search.toLowerCase())) ||
    (load.brokerName && load.brokerName.toLowerCase().includes(search.toLowerCase())) ||
    (load.brokerAddress && load.brokerAddress.toLowerCase().includes(search.toLowerCase())) ||
    (load.status && load.status.toLowerCase().includes(search.toLowerCase())) ||
    load.shippers.some(shipper =>
      shipper.name.toLowerCase().includes(search.toLowerCase()) ||
      shipper.address.toLowerCase().includes(search.toLowerCase())
    ) ||
    load.consignees.some(consignee =>
      consignee.name.toLowerCase().includes(search.toLowerCase()) ||
      consignee.address.toLowerCase().includes(search.toLowerCase())
    )
  );

  return (
    <div className={styles.loadFinderSection}>
      <h2 className={styles.title}>Load Tracker</h2>
      <input
        type="text"
        placeholder="Search by any field"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
      />
      <ul className={styles.loadList}>
        {filteredLoads.map((load, index) => (
          <li key={index} className={styles.loadItem}>
            <strong>Load #:</strong> {load.loadNumber} <br />
            <strong>Broker Name:</strong> {load.brokerName} <br />
            <strong>Broker Address:</strong> {load.brokerAddress} <br />
            <strong>Rate:</strong> ${load.rate} <br />

            <strong>Shippers:</strong>
            <ul>
              {load.shippers.map((shipper, idx) => (
                <li key={idx}>
                  <strong>Name:</strong> {shipper.name} <br />
                  <strong>Address:</strong> {shipper.address} <br />
                  <strong>Pick-up Time:</strong> {shipper.pickUpTime ? new Date(shipper.pickUpTime).toLocaleString() : 'N/A'}
                </li>
              ))}
            </ul>

            <strong>Consignees:</strong>
            <ul>
              {load.consignees.map((consignee, idx) => (
                <li key={idx}>
                  <strong>Name:</strong> {consignee.name} <br />
                  <strong>Address:</strong> {consignee.address} <br />
                  <strong>Delivery Time:</strong> {consignee.deliveryTime ? new Date(consignee.deliveryTime).toLocaleString() : 'N/A'}
                </li>
              ))}
            </ul>

            <strong>Dispatcher:</strong> {load.dispatcher} <br />
            <strong>Driver:</strong> {getDriverName(load.driver)} <br />
            <strong>Status:</strong> {load.status} <br />
            <strong>Miles:</strong> {load.miles} <br />
            <strong>DH:</strong> {load.dh} <br />
            <strong>Notes:</strong> {load.notes} <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

const LoadManager = ({ loads, setLoads }) => {
  const [editingLoad, setEditingLoad] = useState(null);
  const [search, setSearch] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [filteredLoads, setFilteredLoads] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState('');

  const apiUrl = 'https://api-backend.full-trucking-service.com/api';
  const token = sessionStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    withCredentials: true
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const driversRes = await axios.get(`${apiUrl}/drivers`, config);
        setDrivers(driversRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = loads.filter((load) => {
      const searchTerm = search.toLowerCase();
  
      return (
        (load.loadNumber && load.loadNumber.toLowerCase().includes(searchTerm)) ||
        (load.brokerName && load.brokerName.toLowerCase().includes(searchTerm)) ||
        (load.brokerAddress && load.brokerAddress.toLowerCase().includes(searchTerm)) ||
        (load.rate && load.rate.toString().includes(searchTerm)) ||
        (load.miles && load.miles.toString().includes(searchTerm)) ||
        (load.dh && load.dh.toString().includes(searchTerm)) ||
        (load.notes && load.notes.toLowerCase().includes(searchTerm)) ||
        (load.status && load.status.toLowerCase().includes(searchTerm)) ||
        
        // Verificăm fiecare shipper
        load.shippers.some((shipper) =>
          (shipper.name && shipper.name.toLowerCase().includes(searchTerm)) ||
          (shipper.address && shipper.address.toLowerCase().includes(searchTerm)) ||
          (shipper.pickUpTime &&
            new Date(shipper.pickUpTime).toLocaleString().toLowerCase().includes(searchTerm))
        ) ||
  
        // Verificăm fiecare consignee
        load.consignees.some((consignee) =>
          (consignee.name && consignee.name.toLowerCase().includes(searchTerm)) ||
          (consignee.address && consignee.address.toLowerCase().includes(searchTerm)) ||
          (consignee.deliveryTime &&
            new Date(consignee.deliveryTime).toLocaleString().toLowerCase().includes(searchTerm))
        )
      );
    });
  
    setFilteredLoads(filtered);
  }, [search, loads]);

  const getDriverName = (driverId) => {
    const driver = drivers.find((d) => d._id === driverId);
    return driver ? `${driver.firstName} ${driver.lastName}` : 'Unknown Driver';
  };

  const handleSelectLoad = (loadIndex) => {
    setEditingLoad(filteredLoads[loadIndex]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingLoad({ ...editingLoad, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (editingLoad && editingLoad._id) {
        await axios.put(`${apiUrl}/loads/${editingLoad._id}`, editingLoad, config);
        setLoads((prevLoads) =>
          prevLoads.map((load) =>
            load._id === editingLoad._id ? editingLoad : load
          )
        );
        alert('Load updated successfully');
      }
    } catch (error) {
      console.error('Error updating load:', error);
    }
  };

  const handleDelete = async (loadId) => {
    try {
      await axios.delete(`${apiUrl}/loads/${loadId}`, config);
      setLoads((prevLoads) => prevLoads.filter((load) => load._id !== loadId));
      setEditingLoad(null);
      alert('Load deleted successfully');
    } catch (error) {
      console.error('Error deleting load:', error);
    }
  };

  return (
    <div className={styles.loadManagerSection}>
      <h2 className={styles.title}>Load Scheduler</h2>

      <input
        type="text"
        placeholder="Search by Load Number or Driver"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
      />

      <select
        onChange={(e) => setSelectedDriver(e.target.value)}
        value={selectedDriver}
        className={styles.selectField}
      >
        <option value="">Filter by Driver</option>
        {drivers.map((driver) => (
          <option key={driver._id} value={driver._id}>
            {driver.firstName} {driver.lastName}
          </option>
        ))}
      </select>

      <table className={styles.loadTable}>
        <thead>
          <tr>
            <th>Load #</th>
            <th>Broker Name</th>
            <th>Broker Address</th>
            <th>Rate</th>
            <th>Shippers</th>
            <th>Consignees</th>
            <th>Dispatcher</th>
            <th>Status</th>
            <th>Miles</th>
            <th>DH</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {filteredLoads.map((load, index) => (
            <tr key={index} onClick={() => handleSelectLoad(index)} className={styles.loadRow}>
              <td>{load.loadNumber}</td>
              <td>{load.brokerName}</td>
              <td>{load.brokerAddress}</td>
              <td>${load.rate}</td>
              <td>
                {load.shippers.map((shipper, idx) => (
                  <div key={idx}>
                    <strong>Name:</strong> {shipper.name} <br />
                    <strong>Address:</strong> {shipper.address} <br />
                    <strong>Pick-up Time:</strong> {shipper.pickUpTime ? new Date(shipper.pickUpTime).toLocaleString() : 'N/A'}
                  </div>
                ))}
              </td>
              <td>
                {load.consignees.map((consignee, idx) => (
                  <div key={idx}>
                    <strong>Name:</strong> {consignee.name} <br />
                    <strong>Address:</strong> {consignee.address} <br />
                    <strong>Delivery Time:</strong> {consignee.deliveryTime ? new Date(consignee.deliveryTime).toLocaleString() : 'N/A'}
                  </div>
                ))}
              </td>
              <td>{load.dispatcher}</td>
              <td>{load.status}</td>
              <td>{load.miles}</td>
              <td>{load.dh}</td>
              <td>{load.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingLoad ? (
  <div className={styles.editLoadForm}>
    <button onClick={() => setEditingLoad(null)} className={styles.closeButton}>✕</button>
    <h3>Edit Load</h3>
    
    {/* Load Number */}
    <label>Load #:</label>
    <input
      name="loadNumber"
      value={editingLoad.loadNumber}
      disabled // Disable edit pentru loadNumber
      className={styles.inputField}
    />

    {/* Broker Information */}
    <label>Broker Name:</label>
    <input
      name="brokerName"
      value={editingLoad.brokerName || ''}
      onChange={handleChange}
      className={styles.inputField}
    />

    <label>Broker Address:</label>
    <input
      name="brokerAddress"
      value={editingLoad.brokerAddress || ''}
      onChange={handleChange}
      className={styles.inputField}
    />

    {/* Rate */}
    <label>Rate:</label>
    <input
      name="rate"
      type="number"
      value={editingLoad.rate || ''}
      onChange={handleChange}
      className={styles.inputField}
    />

    {/* Status */}
    <label>Status:</label>
    <select
      name="status"
      value={editingLoad.status || ''}
      onChange={handleChange}
      className={styles.selectField}
    >
      <option value="">Select Status</option>
      <option value="Pending">Pending</option>
      <option value="In Progress">In Progress</option>
      <option value="Completed">Completed</option>
    </select>

    {/* Miles and DH */}
    <label>Miles:</label>
    <input
      name="miles"
      type="number"
      value={editingLoad.miles || ''}
      onChange={handleChange}
      className={styles.inputField}
    />

    <label>DH:</label>
    <input
      name="dh"
      type="number"
      value={editingLoad.dh || ''}
      onChange={handleChange}
      className={styles.inputField}
    />

    {/* Notes */}
    <label>Notes:</label>
    <textarea
      name="notes"
      value={editingLoad.notes || ''}
      onChange={handleChange}
      className={styles.textareaField}
    />

    {/* Shippers */}
    <label>Shippers:</label>
    {editingLoad.shippers.map((shipper, index) => (
      <div key={index} className={styles.subForm}>
        <label>Name:</label>
        <input
          value={shipper.name || ''}
          onChange={(e) => {
            const updatedShippers = [...editingLoad.shippers];
            updatedShippers[index].name = e.target.value;
            setEditingLoad({ ...editingLoad, shippers: updatedShippers });
          }}
          className={styles.inputField}
        />
        <label>Address:</label>
        <input
          value={shipper.address || ''}
          onChange={(e) => {
            const updatedShippers = [...editingLoad.shippers];
            updatedShippers[index].address = e.target.value;
            setEditingLoad({ ...editingLoad, shippers: updatedShippers });
          }}
          className={styles.inputField}
        />
        <label>Pick-up Time:</label>
        <input
          type="datetime-local"
          value={shipper.pickUpTime ? new Date(shipper.pickUpTime).toISOString().slice(0, -1) : ''}
          onChange={(e) => {
            const updatedShippers = [...editingLoad.shippers];
            updatedShippers[index].pickUpTime = new Date(e.target.value);
            setEditingLoad({ ...editingLoad, shippers: updatedShippers });
          }}
          className={styles.inputField}
        />
      </div>
    ))}

    {/* Consignees */}
    <label>Consignees:</label>
    {editingLoad.consignees.map((consignee, index) => (
      <div key={index} className={styles.subForm}>
        <label>Name:</label>
        <input
          value={consignee.name || ''}
          onChange={(e) => {
            const updatedConsignees = [...editingLoad.consignees];
            updatedConsignees[index].name = e.target.value;
            setEditingLoad({ ...editingLoad, consignees: updatedConsignees });
          }}
          className={styles.inputField}
        />
        <label>Address:</label>
        <input
          value={consignee.address || ''}
          onChange={(e) => {
            const updatedConsignees = [...editingLoad.consignees];
            updatedConsignees[index].address = e.target.value;
            setEditingLoad({ ...editingLoad, consignees: updatedConsignees });
          }}
          className={styles.inputField}
        />
        <label>Delivery Time:</label>
        <input
          type="datetime-local"
          value={consignee.deliveryTime ? new Date(consignee.deliveryTime).toISOString().slice(0, -1) : ''}
          onChange={(e) => {
            const updatedConsignees = [...editingLoad.consignees];
            updatedConsignees[index].deliveryTime = new Date(e.target.value);
            setEditingLoad({ ...editingLoad, consignees: updatedConsignees });
          }}
          className={styles.inputField}
        />
      </div>
    ))}

    {/* Save and Delete Buttons */}
    <button onClick={handleSave} className={styles.saveButton}>Save</button>
    <button onClick={() => handleDelete(editingLoad._id)} className={styles.deleteButton}>Delete</button>
  </div>
) : (
  <p>No load selected</p>
)}

    </div>
  );
};

const AccountingManager = ({ loads, drivers }) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setFilteredRecords(loads);
  }, [loads]);

  const handleDriverSelection = (driverId) => {
    setSelectedDrivers((prevSelectedDrivers) =>
      prevSelectedDrivers.includes(driverId)
        ? prevSelectedDrivers.filter((id) => id !== driverId)
        : [...prevSelectedDrivers, driverId]
    );
  };

  const handleFilter = () => {
    let filteredData = loads;

    if (selectedDrivers.length > 0) {
      filteredData = filteredData.filter((record) =>
        selectedDrivers.includes(record.driver)
      );
    }

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filteredData = filteredData.filter((record) => {
        const recordDate = new Date(record.createdAt);
        return recordDate >= start && recordDate <= end;
      });
    }

    setFilteredRecords(filteredData);
  };

  const handleExportExcel = () => {
    const data = filteredRecords.map((record) => ({
      DEBNAME: record.brokerName || 'N/A',
      INV_ID: record.invId || '',
      INV_DATE: record.createdAt
        ? new Date(record.createdAt).toLocaleDateString()
        : '',
      PO_NO: record.loadNumber || '',
      INVAMT: record.rate ? record.rate.toFixed(2) : '0.00',
      Name:
        drivers.find((driver) => driver._id === record.driver)?.firstName ||
        'N/A',
      AdditionalNote: record.notes || '',
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Accounting');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'accounting_manager.xlsx');
  };

  return (
    <div className={styles.accountingManager}>
      <h2>Accounting Manager</h2>

      <div className={styles.filtersaccountingManager}>
        <div className={styles.checkboxContaineraccountingManager}>
          <label>Select Drivers:</label>
          {drivers.map((driver) => (
            <div key={driver._id} className={styles.checkboxItemaccountingManager}>
              <input
                type="checkbox"
                value={driver._id}
                checked={selectedDrivers.includes(driver._id)}
                onChange={() => handleDriverSelection(driver._id)}
              />
              <span>
                {driver.firstName} {driver.lastName}
              </span>
            </div>
          ))}
        </div>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className={styles.dateFieldaccountingManager}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className={styles.dateFieldaccountingManager}
        />
        <button onClick={handleFilter} className={styles.filterButtonaccountingManager}>
          Apply Filter
        </button>
        <button onClick={handleExportExcel} className={styles.exportButtonaccountingManager}>
          Export to Excel
        </button>
      </div>

      <table className={styles.tableaccountingManager}>
        <thead>
          <tr>
            <th>DEBNAME</th>
            <th>INV_ID</th>
            <th>INV_DATE</th>
            <th>PO_NO</th>
            <th>INVAMT</th>
            <th>Name</th>
            <th>Additional Note</th>
          </tr>
        </thead>
        <tbody>
          {filteredRecords.map((record, index) => (
            <tr key={index}>
              <td>{record.brokerName || 'N/A'}</td>
              <td>
                <input
                  type="text"
                  defaultValue={record.invId || ''}
                  onChange={(e) => {
                    record.invId = e.target.value;
                    setFilteredRecords([...filteredRecords]);
                  }}
                  className={styles.inputFieldaccountingManager}
                />
              </td>
              <td>
                <input
                  type="date"
                  defaultValue={
                    record.createdAt
                      ? new Date(record.createdAt).toISOString().split('T')[0]
                      : ''
                  }
                  onChange={(e) => {
                    record.createdAt = e.target.value;
                    setFilteredRecords([...filteredRecords]);
                  }}
                  className={styles.dateFieldaccountingManager}
                />
              </td>
              <td>{record.loadNumber || 'N/A'}</td>
              <td>${record.rate ? record.rate.toFixed(2) : '0.00'}</td>
              <td>
                {drivers.find((driver) => driver._id === record.driver)
                  ?.firstName || 'N/A'}
              </td>
              <td>
                <input
                  type="text"
                  defaultValue={record.notes || ''}
                  onChange={(e) => {
                    record.notes = e.target.value;
                    setFilteredRecords([...filteredRecords]);
                  }}
                  className={styles.inputFieldaccountingManager}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const DriverPay = ({ drivers, trucks, loads, onAddDriverPay }) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredLoads, setFilteredLoads] = useState([]);
  const [selectedLoads, setSelectedLoads] = useState([]);
  const [recurringDeductions, setRecurringDeductions] = useState([]);
  const [recurringAdditions, setRecurringAdditions] = useState([]);
  const [clientInfo, setClientInfo] = useState({
    companyName: '',
    companyAddress: '',
    email: '',
    phone: '',
  });
  const [week, setWeek] = useState('');
  const [logo, setLogo] = useState(null);
  const [customFooterText, setCustomFooterText] = useState('');
  const [useCustomFooter, setUseCustomFooter] = useState(false);

  // Configurația pentru autentificare
  const token = sessionStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        const response = await axios.get('https://api-backend.full-trucking-service.com/api/settings', config);
        const { companyName, companyAddress, email, phone } = response.data;
        setClientInfo({ companyName, companyAddress, email, phone });
        if (customFooterText) setCustomFooterText(customFooterText);
        if (useCustomFooter !== undefined) setUseCustomFooter(useCustomFooter);
      } catch (error) {
        console.error("Error fetching client info:", error);
      }
    };

    const fetchLogo = async () => {
      try {
        const response = await axios.get('https://api-backend.full-trucking-service.com/api/logo', config);
        setLogo(response.data.logo);
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchClientInfo();
    fetchLogo();
  }, []);
  
  useEffect(() => {
    const fetchFooterText = async () => {
      try {
        const response = await axios.get('https://api-backend.full-trucking-service.com/api/footer', config);
        if (response.status === 200) {
          const { customFooterText, useCustomFooter } = response.data;
          setCustomFooterText(customFooterText || '');
          setUseCustomFooter(useCustomFooter || false);
        }
      } catch (error) {
        console.error('Error fetching footer text:', error);
      }
    };

    fetchFooterText();
  }, []);

  // Save Footer
  const handleSaveFooterText = async () => {
    try {
      const response = await axios.put(
        'https://api-backend.full-trucking-service.com/api/footer',
        { customFooterText, useCustomFooter },
        config
      );
      if (response.status === 200) {
        alert('Footer text saved successfully!');
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error saving footer text:', error);
      alert('Failed to save footer text.');
    }
  };

  // Delete Footer
  const handleDeleteFooterText = async () => {
    try {
      const response = await axios.delete('https://api-backend.full-trucking-service.com/api/footer', config);
      if (response.status === 200) {
        setCustomFooterText('');
        setUseCustomFooter(false);
        alert('Footer text deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting footer text:', error);
      alert('Failed to delete footer text.');
    }
  };
  
  useEffect(() => {
    const fetchRecurringData = async () => {
        if (selectedDriver) {
            try {
                const response = await axios.get(`https://api-backend.full-trucking-service.com/api/drivers/${selectedDriver._id}`, config);
                setRecurringDeductions(response.data.recurringDeductions || []);
                setRecurringAdditions(response.data.recurringAdditions || []);
            } catch (error) {
                console.error("Error fetching recurring data for driver:", error);
            }
        } else if (selectedTruck) {
            try {
                const response = await axios.get(`https://api-backend.full-trucking-service.com/api/trucks/${selectedTruck._id}`, config);
                setRecurringDeductions(response.data.recurringDeductions || []);
                setRecurringAdditions(response.data.recurringAdditions || []);
            } catch (error) {
                console.error("Error fetching recurring data for truck:", error);
            }
        }
    };

    fetchRecurringData();
}, [selectedDriver, selectedTruck]);
const saveRecurringData = async () => {
  if (selectedDriver) {
      try {
          await axios.put(
              `https://api-backend.full-trucking-service.com/api/drivers/${selectedDriver._id}`,
              {
                  recurringDeductions,
                  recurringAdditions,
              },
              config
          );
          console.log("Recurring data saved successfully for driver.");
      } catch (error) {
          console.error("Error saving recurring data for driver:", error);
      }
  } else if (selectedTruck) {
      try {
          await axios.put(
              `https://api-backend.full-trucking-service.com/api/trucks/${selectedTruck._id}`,
              {
                  recurringDeductions,
                  recurringAdditions,
              },
              config
          );
          console.log("Recurring data saved successfully for truck.");
      } catch (error) {
          console.error("Error saving recurring data for truck:", error);
      }
  }
};

// Salvăm datele recurente la fiecare modificare în deduceri și adiții
useEffect(() => {
  saveRecurringData();
}, [recurringDeductions, recurringAdditions]);

const handleLogoChange = async (e) => {
  const file = e.target.files[0];
  if (file && file.size > 5000000) { // 5MB limit
    alert("File is too large! Please select a file smaller than 5MB.");
    return;
  }
  if (file && !file.type.startsWith("image/")) {
    alert("Invalid file type! Please select an image.");
    return;
  }

  const reader = new FileReader();
  reader.onload = async (event) => {
    const imageData = event.target.result;
    try {
      await axios.put(
        'https://api-backend.full-trucking-service.com/api/logo',
        { logo: imageData },
        config
      );
      setLogo(imageData);
    } catch (error) {
      console.error("Error updating logo:", error);
    }
  };
  reader.readAsDataURL(file);
};


  const handleLogoDelete = async () => {
    try {
      await axios.delete('https://api-backend.full-trucking-service.com/api/logo', config);
      setLogo(null);
    } catch (error) {
      console.error("Error deleting logo:", error);
    }
  };
  // Funcție pentru selectarea șoferului
  const handleDriverSelect = (e) => {
    setSelectedDriver(drivers.find((driver) => driver._id === e.target.value));
    setSelectedTruck(null); // Dacă se alege șofer, resetăm camionul
  };

  // Funcție pentru selectarea camionului
  const handleTruckSelect = (e) => {
    setSelectedTruck(trucks.find((truck) => truck._id === e.target.value));
    setSelectedDriver(null); // Dacă se alege camion, resetăm șoferul
  };
  const handleAddDriverPay = async () => {
    if ((!selectedDriver && !selectedTruck) || !startDate || !endDate) {
        console.error('Driver/Truck, Start Date or End Date missing');
        return;
    }

    const transformedDeductions = recurringDeductions.map((ded) => ({
        amount: parseFloat(ded.amount) || 0,
        service: ded.service,
    }));

    const transformedAdditions = recurringAdditions.map((add) => ({
        amount: parseFloat(add.amount) || 0,
        service: add.service,
    }));

    const { payDetails, totalRate, totalRateWithAdjustments, totalPay } = calculatePay();

    if (totalRateWithAdjustments === undefined || totalRateWithAdjustments === null) {
        console.error('Total with adjustments is missing');
        return;
    }

    try {
        const response = await axios.post(
            'https://api-backend.full-trucking-service.com/api/driverpays',
            {
                driver: selectedDriver ? selectedDriver._id : null,
                truck: selectedTruck ? selectedTruck._id : null,
                startDate,
                endDate,
                recurringAddition: transformedAdditions,
                recurringDeduction: transformedDeductions,
                totalPay,
                totalWithAdjustments: totalRateWithAdjustments,
                payDetails,
                companyName: clientInfo.companyName,
                logo,
            },
            config
        );

        if (response.status === 201) {
            console.log('DriverPay saved successfully:', response.data);
        } else {
            console.error('Error saving DriverPay:', response.data);
        }
    } catch (error) {
        console.error('Error adding driver pay:', error);
    }
};


  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') setStartDate(value);
    else setEndDate(value);
  };

  const searchLoads = () => {
    if ((!selectedDriver && !selectedTruck) || !startDate || !endDate) return;
  
    const filtered = loads.filter((load) => {
      let match = false;
  
      // Verificăm dacă șoferul selectat este șoferul principal sau parte din echipă
      if (selectedDriver) {
        match =
          load.driver?.toString() === selectedDriver._id?.toString() ||
          (Array.isArray(load.teamDrivers) &&
            load.teamDrivers.some((teamDriverId) =>
              teamDriverId.toString() === selectedDriver._id?.toString()
            ));
      }
  
      // Verificăm dacă camionul selectat este asociat cu încărcătura
      if (selectedTruck) {
        match = load.truck?.toString() === selectedTruck._id?.toString();
      }
  
      // Verificăm datele de preluare și livrare
      const hasPickupDate = load.shippers.some(
        (shipper) =>
          shipper.pickUpTime && new Date(shipper.pickUpTime) >= new Date(startDate)
      );
      const hasDeliveryDate = load.consignees.some(
        (consignee) =>
          consignee.deliveryTime && new Date(consignee.deliveryTime) <= new Date(endDate)
      );
  
      return match && hasPickupDate && hasDeliveryDate;
    });
  
    setFilteredLoads(filtered);
  };
  
  

  const handleLoadSelection = (loadNumber) => {
    setSelectedLoads((prevSelected) =>
      prevSelected.includes(loadNumber)
        ? prevSelected.filter((num) => num !== loadNumber)
        : [...prevSelected, loadNumber]
    );
  };
  const calculatePay = () => {
    const payDetails = filteredLoads
      .filter((load) => selectedLoads.includes(load.loadNumber))
      .map((load) => {
        const driverSettlement = selectedDriver ? selectedDriver.settlement : null;
        const truckSettlement = selectedTruck ? selectedTruck.settlement : null;
        let pay = 0;
        let payDescription = '';
  
        // Calculation for Driver Settlement
        if (selectedDriver) {
          switch (driverSettlement) {
            case 'Hourly pay':
              pay = parseFloat(selectedDriver.hoursWorked || 0) * parseFloat(selectedDriver.settlementAmount || 0);
              payDescription = `${selectedDriver.hoursWorked || 0} hours * $${selectedDriver.settlementAmount || 0} per hour (Driver)`;
              break;
  
            case 'Per mile pay':
              const driverMilesPay = parseFloat(load.miles || 0) * parseFloat(selectedDriver.normalMileRate || 0);
              const driverDhPay = parseFloat(load.dh || 0) * parseFloat(selectedDriver.dhMileRate || 0);
              pay = driverMilesPay + driverDhPay;
              payDescription = `Miles: ${load.miles || 0} * $${selectedDriver.normalMileRate || 0} + DH Miles: ${load.dh || 0} * $${selectedDriver.dhMileRate || 0} (Driver)`;
              break;
  
            case 'Fixed salary':
              pay = 0;
              payDescription = 'Fixed Salary - Calculated once (Driver)';
              break;
  
            case 'Percentage of the load':
              pay = (parseFloat(load.rate || 0) * parseFloat(selectedDriver.settlementAmount || 0)) / 100;
              payDescription = `Percentage: ${selectedDriver.settlementAmount}% of $${load.rate || 0} (Driver)`;
              break;
  
            case 'Team driver pay':
              pay = parseFloat(load.rate || 0) / 2;
              payDescription = `Team Driver: Load Rate $${load.rate || 0} / 2 (Each driver receives half) (Driver)`;
              break;
  
            default:
              pay = 0;
              payDescription = 'No payment method selected (Driver)';
              break;
          }
        }
  
        // Calculation for Truck Settlement
        if (selectedTruck) {
          switch (truckSettlement) {
            case 'Hourly pay':
              pay = parseFloat(selectedTruck.hoursWorked || 0) * parseFloat(selectedTruck.settlementAmount || 0);
              payDescription = `${selectedTruck.hoursWorked || 0} hours * $${selectedTruck.settlementAmount || 0} per hour (Truck)`;
              break;
  
            case 'Per mile pay':
              const truckMilesPay = parseFloat(load.miles || 0) * parseFloat(selectedTruck.normalMileRate || 0);
              const truckDhPay = parseFloat(load.dh || 0) * parseFloat(selectedTruck.dhMileRate || 0);
              pay = truckMilesPay + truckDhPay;
              payDescription = `Miles: ${load.miles || 0} * $${selectedTruck.normalMileRate || 0} + DH Miles: ${load.dh || 0} * $${selectedTruck.dhMileRate || 0} (Truck)`;
              break;
  
              case 'Fixed salary':
                pay = 0;
                payDescription = 'Fixed Salary - Calculated once (Driver)';
                break;
  
            case 'Percentage of the load':
              pay = (parseFloat(load.rate || 0) * parseFloat(selectedTruck.settlementAmount || 0)) / 100;
              payDescription = `Percentage: ${selectedTruck.settlementAmount}% of $${load.rate || 0} (Truck)`;
              break;
  
            case 'Team driver pay':
              pay = parseFloat(load.rate || 0) / 2;
              payDescription = `Team Driver: Load Rate $${load.rate || 0} / 2 (Each driver receives half) (Truck)`;
              break;
  
            default:
              pay = parseFloat(load.rate || 0);
              payDescription = `Truck rate: $${load.rate || 0}`;
              break;
          }
        }
  
        return {
          loadNumber: load.loadNumber || 'N/A',
          pickUpTime: load.pickUpTime || 'N/A',
          deliveryTime: load.deliveryTime || 'N/A',
          shipperAddress: load.shipperAddress || 'N/A',
          consigneeAddress: load.consigneeAddress || 'N/A',
          shippers: load.shippers || [],
          consignees: load.consignees || [],
          miles: load.miles || 0,
          dh: load.dh || 0,
          rate: load.rate || 0,
          pay,
          payDescription,
          notes: load.notes || '',
        };
      });
  
    const totalRate = payDetails.reduce((sum, detail) => sum + parseFloat(detail.rate || 0), 0);
    let totalPay = 0;
  
    if (selectedDriver) {
      if (selectedDriver.settlement === 'Percentage of the load') {
        totalPay = (totalRate * parseFloat(selectedDriver.settlementAmount || 0)) / 100;
      } else if (selectedDriver.settlement === 'Fixed salary') {
        totalPay = parseFloat(selectedDriver.settlementAmount || 0);
      } else {
        totalPay = payDetails.reduce((sum, detail) => sum + parseFloat(detail.pay || 0), 0);
      }
    } else if (selectedTruck) {
      if (selectedTruck.settlement === 'Percentage of the load') {
        totalPay = (totalRate * parseFloat(selectedTruck.settlementAmount || 0)) / 100;
      } else if (selectedTruck.settlement === 'Fixed salary') {
        totalPay = parseFloat(selectedTruck.settlementAmount || 0);
      } else {
        totalPay = payDetails.reduce((sum, detail) => sum + parseFloat(detail.pay || 0), 0);
      }
    }
    
  
    const recurringAdditionsTotal = recurringAdditions.reduce((sum, add) => sum + parseFloat(add.amount || 0), 0);
    const recurringDeductionsTotal = recurringDeductions.reduce((sum, ded) => sum + parseFloat(ded.amount || 0), 0);
    const totalPayWithAdjustments = totalPay + recurringAdditionsTotal - recurringDeductionsTotal;
  
    return {
      payDetails,
      totalRate,
      totalPayWithAdjustments,
      totalPay,
    };
  };
  
  const generatePDF = () => {
    const { payDetails, totalRate, totalPayWithAdjustments, totalPay } = calculatePay();
    const doc = new jsPDF();
  
    // Adaugă antetul pe fiecare pagină
    const addHeader = () => {
      if (logo && typeof logo === 'string') {
        doc.addImage(logo, 'PNG', 10, 10, 55, 25);
      }
      doc.setFontSize(12);
      doc.setTextColor("#000000");
      doc.text('Settlement', 105, 20, { align: 'center' });
      doc.setFontSize(8);
      if (week) doc.text(`Week ${week}`, 105, 35, { align: 'center' });
      if (selectedDriver) {
        doc.text(`Driver: ${selectedDriver.firstName} ${selectedDriver.lastName}`, 10, 50);
        doc.text(`Address: ${selectedDriver.address}`, 10, 55);
        doc.text(`Truck: ${selectedDriver.truckNumber || 'N/A'}`, 10, 60);
        doc.text(`Phone: ${selectedDriver.phone}`, 10, 65);
      } else if (selectedTruck) {
        doc.text(`Truck Number: ${selectedTruck.truckNumber}`, 10, 60);
      }
      doc.text(`Start Date: ${startDate}`, 150, 50);
      doc.text(`End Date: ${endDate}`, 150, 55);
    };
  
    // Adaugă footer-ul pe fiecare pagină
    const addFooter = () => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(8);
      doc.setTextColor(0, 0, 0);
  
      // Adaugă numărul paginii
      doc.text(`Page ${pageCount}`, 10, doc.internal.pageSize.height - 10);
  
      // Adaugă footer-ul personalizat sau implicit
      if (useCustomFooter && customFooterText) {
        doc.text(customFooterText, 105, doc.internal.pageSize.height - 20, { align: 'center' });
      } else {
        doc.text('Thank you for your business!', 105, doc.internal.pageSize.height - 20, { align: 'center' });
        doc.text(`${clientInfo.companyName}`, 105, doc.internal.pageSize.height - 15, { align: 'center' });
        doc.text(`${clientInfo.companyAddress}`, 105, doc.internal.pageSize.height - 10, { align: 'center' });
      }
    };
  
    // Adaugă antetul pe prima pagină
    addHeader();
  
    let startY = 80;
  
    // Iterează prin detalii și adaugă conținut
    payDetails.forEach((detail, index) => {
      // Verifică dacă este necesară o pagină nouă
      if (startY > doc.internal.pageSize.height - 40) {
        doc.addPage();
        addHeader(); // Antet pe pagina nouă
        addFooter(); // Footer pe pagina nouă
        startY = 30;
      }
  
      doc.setFontSize(8);
  
      const pickupDetails = (detail.shippers || [])
        .map(
          (shipper) =>
            `${new Date(shipper.pickUpTime).toLocaleString()} - ${shipper.address || 'N/A'}`
        )
        .join('\n');
  
      const deliveryDetails = (detail.consignees || [])
        .map(
          (consignee) =>
            `${new Date(consignee.deliveryTime).toLocaleString()} - ${consignee.address || 'N/A'}`
        )
        .join('\n');
  
      doc.autoTable({
        startY: startY,
        body: [
          [
            { content: `Load #`, styles: { fontStyle: 'bold', halign: 'left' } },
            `${index + 1}`,
            '',
          ],
          [
            { content: 'Pickup:', styles: { fontStyle: 'bold', halign: 'left' } },
            { content: pickupDetails || 'N/A', colSpan: 2 },
          ],
          [
            { content: 'Delivery:', styles: { fontStyle: 'bold', halign: 'left' } },
            { content: deliveryDetails || 'N/A', colSpan: 2 },
          ],
          [
            { content: 'Rate', styles: { fontStyle: 'bold', halign: 'left' } },
            getRateDetail(detail),
            '',
          ],
          [
            'Notes',
            detail.notes || 'No notes available',
            {
              content: `Total Pay: $${detail.pay.toFixed(2)}`,
              styles: { fontStyle: 'bold', halign: 'right' },
            },
          ],
        ],
        theme: 'grid',
        styles: { textColor: "#000000", lineWidth: 0.75, fontSize: 8, cellPadding: 1.5 },
        margin: { top: 10, left: 10, right: 10 },
        columnStyles: { 0: { halign: 'left' }, 1: { halign: 'left' }, 2: { halign: 'right' } },
      });
  
      startY = doc.lastAutoTable.finalY + 10;
    });
  
    // Adaugă totaluri și salariul fix
    if (selectedDriver && selectedDriver.settlement === 'Fixed salary') {
      startY += 20;
      doc.text(`Fixed Salary: $${totalPay.toFixed(2)}`, 10, startY);
    }
  
    // Sub-Total
    doc.setFontSize(10);
    doc.text('Sub-Total', 10, startY);
    startY += 10;
  
    doc.autoTable({
      startY: startY,
      body: [['Sub-Total', `$${totalPay.toFixed(2)}`]],
      theme: 'grid',
      styles: { textColor: "#000000", lineWidth: 0.75, fontSize: 8, cellPadding: 1.5 },
      margin: { top: 10, left: 10, right: 10 },
      columnStyles: { 0: { halign: 'left' }, 1: { halign: 'right' } },
    });
  
    // Recurring Additions
    startY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(10);
    doc.text('Recurring Additions', 10, startY);
    startY += 10;
  
    doc.autoTable({
      startY: startY,
      head: [['Service', 'Amount']],
      body: recurringAdditions.map((add) => [add.service, `$${parseFloat(add.amount || 0).toFixed(2)}`]),
      theme: 'grid',
      styles: { textColor: "#000000", lineWidth: 0.75, fontSize: 8, cellPadding: 1.5 },
      margin: { top: 10, left: 10, right: 10 },
      columnStyles: { 0: { halign: 'left' }, 1: { halign: 'right' } },
    });
  
    // Recurring Deductions
    startY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(10);
    doc.text('Recurring Deductions', 10, startY);
    startY += 10;
  
    doc.autoTable({
      startY: startY,
      head: [['Service', 'Amount']],
      body: recurringDeductions.map((ded) => [ded.service, `-$${parseFloat(ded.amount || 0).toFixed(2)}`]),
      theme: 'grid',
      styles: { textColor: "#000000", lineWidth: 0.75, fontSize: 8, cellPadding: 1.5 },
      margin: { top: 10, left: 10, right: 10 },
      columnStyles: { 0: { halign: 'left' }, 1: { halign: 'right' } },
    });
  
    // Grand Total
    startY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(10);
    doc.text(`Grand Total: $${totalPayWithAdjustments.toFixed(2)}`, 10, startY);
  
    // Adaugă footer-ul final
    addFooter();
    doc.save('driver_payroll.pdf');
  };
  
// Funcție auxiliară pentru detaliile rate
const getRateDetail = (detail) => {
  if (selectedTruck && selectedTruck.settlement) {
    switch (selectedTruck.settlement) {
      case 'Hourly pay':
        return `Truck: ${selectedTruck.hoursWorked || 0} hours * $${selectedTruck.settlementAmount || 0} per hour`;
      case 'Per mile pay':
        return `Truck: Miles ${detail.miles} * $${selectedTruck.normalMileRate || 0} + DH Miles ${detail.dh} * $${selectedTruck.dhMileRate || 0}`;
      case 'Fixed salary':
        return `Fixed Salary (Truck) - $${selectedTruck.settlementAmount || 0}`;
      case 'Percentage of the load':
        return `Truck: ${selectedTruck.settlementAmount}% of $${detail.rate || 0}`;
      case 'Team driver pay':
        return `Truck Team Driver: Load Rate $${detail.rate || 0} / 2 (Each driver receives half)`;
      default:
        return `Truck rate for load: $${detail.rate || 0}`;
    }
  }
  
  if (selectedDriver && selectedDriver.settlement) {
    switch (selectedDriver.settlement) {
      case 'Hourly pay':
        return `Driver: ${selectedDriver.hoursWorked || 0} hours * $${selectedDriver.settlementAmount || 0} per hour`;
      case 'Per mile pay':
        return `Driver: Miles ${detail.miles} * $${selectedDriver.normalMileRate || 0} + DH Miles ${detail.dh} * $${selectedDriver.dhMileRate || 0}`;
      case 'Fixed salary':
        return `Fixed Salary (Driver) - $${selectedDriver.settlementAmount || 0}`;
      case 'Percentage of the load':
        return `Driver: ${selectedDriver.settlementAmount}% of $${detail.rate || 0}`;
      case 'Team driver pay':
        return `Driver Team: Load Rate $${detail.rate || 0} / 2`;
      default:
        return `Driver rate for load: $${detail.rate || 0}`;
    }
  }
  
  return `Rate: $${detail.rate || 0}`;
};





  const removeRecurring = (type, index) => {
    if (type === 'deduction') {
      setRecurringDeductions(recurringDeductions.filter((_, i) => i !== index));
    } else {
      setRecurringAdditions(recurringAdditions.filter((_, i) => i !== index));
    }
  };

  // Funcție pentru adăugarea deducerilor și adițiilor recurente
  const addDeduction = () => {
    setRecurringDeductions([...recurringDeductions, { amount: 0, service: '' }]);
  };

  const addAddition = () => {
    setRecurringAdditions([...recurringAdditions, { amount: 0, service: '' }]);
  };

  return (
    <div className={styles.driverPaySection}>
      <h2 className={styles.titledriverPay}>Payroll</h2>
      <div className={styles.formGroupdriverPay}>
        <label>Select Driver</label>
        <select onChange={handleDriverSelect} className={styles.selectFielddriverPay} disabled={selectedTruck !== null}>
          <option value="">Select Driver</option>
          {drivers.map((driver) => (
            <option key={driver._id} value={driver._id}>
              {driver.firstName} {driver.lastName}
            </option>
          ))}
        </select>


      </div>
      
      <select onChange={handleTruckSelect} className={styles.selectFielddriverPay} disabled={selectedDriver !== null}>
  <option value="">Select Truck</option>
  {trucks.length > 0 ? (
    trucks.map((truck) => (
      <option key={truck._id} value={truck._id}>
        Truck {truck.truckNumber}
      </option>
    ))
  ) : (
    <option disabled>No Trucks Available</option>
  )}
</select>



      {(selectedDriver || selectedTruck) && (
        <div className={styles.driverDetailsdriverPay}>
          <h3 className={styles.subtitledriverPay}>
            {selectedDriver
              ? `Driver: ${selectedDriver.firstName} ${selectedDriver.lastName}`
              : `Truck: ${selectedTruck.truckNumber}`}
          </h3>
          <div className={styles.formGroupdriverPay}>
            <label>Upload Company Logo</label>
            <input type="file" onChange={handleLogoChange} className={styles.inputFielddriverPay} />
            {logo && (
              <div className={styles.logoPreview}>
                <img src={logo} alt="Company Logo" className={styles.logoImagedriverPay} />
                <button onClick={handleLogoDelete} className={styles.deleteButtondriverPay}>Remove Logo</button>
              </div>
            )}
          </div>

          <div className={styles.formGroupdriverPay}>
            <label>Week</label>
            <input
              type="text"
              value={week}
              onChange={(e) => setWeek(e.target.value)}
              placeholder="Enter week (optional)"
              className={styles.inputFielddriverPay}
            />
          </div>
          <div className={styles.formGroupdriverPay}>
  <label>Custom Footer Text</label>
  <textarea
    value={customFooterText}
    onChange={(e) => setCustomFooterText(e.target.value)}
    placeholder="Enter custom footer text"
  ></textarea>
  <div>
    <label>
      <input
        type="checkbox"
        checked={useCustomFooter}
        onChange={(e) => setUseCustomFooter(e.target.checked)}
      />
      Use Custom Footer Text
    </label>
  </div>
  <button onClick={handleSaveFooterText}>Save Footer Text</button>
  <button onClick={handleDeleteFooterText}>Delete Footer Text</button>
</div>

   
          <div className={styles.formRowdriverPay}>
            <div className={styles.formGroupdriverPay}>
              <label>Start Date</label>
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={handleDateChange}
                className={styles.inputFielddriverPay}
              />
            </div>
            <div className={styles.formGroupdriverPay}>
              <label>End Date</label>
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={handleDateChange}
                className={styles.inputFielddriverPay}
              />
            </div>
          </div>
          <button onClick={searchLoads} className={styles.actionButtondriverPay}>
            Search
          </button>
          {filteredLoads.length > 0 && (
            <div className={styles.loadDetailsdriverPay}>
              <h3 className={styles.subtitledriverPay}>Load Details</h3>
              <ul className={styles.loadListdriverPay}>
              {filteredLoads.map((load, index) => (
  <li key={index} className={styles.loadItemdriverPay}>
    <input
      type="checkbox"
      checked={selectedLoads.includes(load.loadNumber)}
      onChange={() => handleLoadSelection(load.loadNumber)}
    />
    <strong>Load #:</strong> {load.loadNumber || 'N/A'} <br />
    <strong>Pick Up:</strong> {
      load.shippers.length > 0 && load.shippers[0].pickUpTime
        ? new Date(load.shippers[0].pickUpTime).toLocaleString()
        : 'N/A'
    } <br />
    <strong>Delivery:</strong> {
      load.consignees.length > 0 && load.consignees[0].deliveryTime
        ? new Date(load.consignees[0].deliveryTime).toLocaleString()
        : 'N/A'
    } <br />
    <strong>Rate:</strong> ${parseFloat(load.rate || 0).toFixed(2)} <br />
  </li>
))}

              </ul>

              <button onClick={calculatePay} className={styles.actionButtondriverPay}>
                Calculate
              </button>
               <div className={styles.recurringSectiondriverPay}>
            <div className={styles.formGroupdriverPay}>
              <label>Recurring Deduction</label>
              {recurringDeductions.map((deduction, index) => (
                <div key={index} className={styles.recurringItemdriverPay}>
                  <input
                    type="number"
                    value={deduction.amount}
                    onChange={(e) => {
                      const updatedDeductions = [...recurringDeductions];
                      updatedDeductions[index].amount = e.target.value;
                      setRecurringDeductions(updatedDeductions);
                    }}
                    placeholder="Amount"
                    className={styles.inputFielddriverPay}
                  />
                  <input
                    type="text"
                    value={deduction.service}
                    onChange={(e) => {
                      const updatedDeductions = [...recurringDeductions];
                      updatedDeductions[index].service = e.target.value;
                      setRecurringDeductions(updatedDeductions);
                    }}
                    placeholder="Service"
                    className={styles.inputFielddriverPay}
                  />
                  <button onClick={() => removeRecurring('deduction', index)} className={styles.removeButtondriverPay}>X</button>
                </div>
              ))}
              <button onClick={addDeduction} className={styles.addButtondriverPay}>Add Deduction</button>
            </div>

            <div className={styles.formGroup}>
              <label>Recurring Addition</label>
              {recurringAdditions.map((addition, index) => (
                <div key={index} className={styles.recurringItemdriverPay}>
                  <input
                    type="number"
                    value={addition.amount}
                    onChange={(e) => {
                      const updatedAdditions = [...recurringAdditions];
                      updatedAdditions[index].amount = e.target.value;
                      setRecurringAdditions(updatedAdditions);
                    }}
                    placeholder="Amount"
                    className={styles.inputFielddriverPay}
                  />
                  <input
                    type="text"
                    value={addition.service}
                    onChange={(e) => {
                      const updatedAdditions = [...recurringAdditions];
                      updatedAdditions[index].service = e.target.value;
                      setRecurringAdditions(updatedAdditions);
                    }}
                    placeholder="Service"
                    className={styles.inputFielddriverPay}
                  />
                  <button onClick={() => removeRecurring('addition', index)} className={styles.removeButtondriverPay}>X</button>
                </div>
              ))}
             
           
                  <button onClick={addAddition} className={styles.addButtondriverPay}>
                    Add Addition
                  </button>
                </div>
              </div>
              <button
                onClick={async () => {
                  await handleAddDriverPay();
                  generatePDF();
                }}
                className={styles.actionButtondriverPay}
              >
                Save and Preview
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


const FuelReport = ({ drivers, trucks }) => {
  
  const [fuelData, setFuelData] = useState({
    driverId: '',
    truckId: '',
    paymentMethod: '',
    location: '',
    fuelSubtotal: 0,
    otherCosts: 0,
    grandTotal: 0,
  });
  const [fuelReports, setFuelReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const apiUrl = 'https://api-backend.full-trucking-service.com/api';
  const token = sessionStorage.getItem('token');

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  useEffect(() => {
    fetchFuelReports();
  }, []);

  const fetchFuelReports = async () => {
    try {
      const response = await axios.get(`${apiUrl}/fuel-reports`, config);
      setFuelReports(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching fuel reports:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFuelData((prev) => ({
      ...prev,
      [name]: value,
      grandTotal:
        name === 'fuelSubtotal' || name === 'otherCosts'
          ? parseFloat(prev.fuelSubtotal || 0) + parseFloat(prev.otherCosts || 0)
          : prev.grandTotal,
    }));
  };

  const addFuelEntry = async () => {
    try {
      const entry = {
        ...fuelData,
        grandTotal: parseFloat(fuelData.fuelSubtotal || 0) + parseFloat(fuelData.otherCosts || 0),
      };
      const response = await axios.post(`${apiUrl}/fuel-reports`, entry, config);
      if (response.status === 201 && response.data?.data) {
        alert('Fuel entry added successfully');
        setFuelReports((prev) => [...prev, response.data.data]);
        setFuelData({
          driverId: '',
          truckId: '',
          paymentMethod: '',
          location: '',
          fuelSubtotal: 0,
          otherCosts: 0,
          grandTotal: 0,
        });
      } else {
        alert('Failed to add fuel entry.');
      }
    } catch (error) {
      console.error('Error adding fuel entry:', error);
      alert('Failed to add fuel entry.');
    }
  };

  const editFuelEntry = async () => {
    try {
      const response = await axios.put(`${apiUrl}/fuel-reports/${isEditing}`, fuelData, config);
      if (response.status === 200) {
        alert('Fuel entry updated successfully');
        setFuelReports((prev) =>
          prev.map((entry) => (entry._id === isEditing ? response.data.data : entry))
        );
        setIsEditing(null); // Resetează modul de editare
        setFuelData({
          driverId: '',
          truckId: '',
          paymentMethod: '',
          location: '',
          fuelSubtotal: 0,
          otherCosts: 0,
          grandTotal: 0,
        });
      }
    } catch (error) {
      console.error('Error editing fuel entry:', error);
      alert('Failed to edit fuel entry.');
    }
  };
  
  const handleEditClick = (entry) => {
    setIsEditing(entry._id); // Setează ID-ul raportului pentru editare
    setFuelData({
      driverId: entry.driverId?._id || '', // ID-ul șoferului
      truckId: entry.truckId?._id || '', // ID-ul camionului
      paymentMethod: entry.paymentMethod || '',
      location: entry.location || '',
      fuelSubtotal: entry.fuelSubtotal || 0,
      otherCosts: entry.otherCosts || 0,
      grandTotal: entry.grandTotal || 0,
      
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  

  const deleteFuelEntry = async (id) => {
    try {
      await axios.delete(`${apiUrl}/fuel-reports/${id}`, config);
      alert('Fuel entry deleted successfully');
      setFuelReports((prev) => prev.filter((entry) => entry._id !== id));
    } catch (error) {
      console.error('Error deleting fuel entry:', error);
      alert('Failed to delete fuel entry.');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleExport = () => {
    // Filtrăm doar rapoartele selectate
    const selectedData = fuelReports
      .filter((report) => selectedReports.includes(report._id))
      .map((report) => {
        // Găsim șoferul și camionul asociat folosind ID-uri
        const driver = drivers.find((d) => String(d._id) === String(report.driverId?._id || report.driverId));
        const truck = trucks.find((t) => String(t._id) === String(report.truckId?._id || report.truckId));
  
        // Structurăm datele pentru export
        return {
          Driver: driver ? `${driver.firstName} ${driver.lastName}` : 'N/A', // Numele complet al șoferului
          Truck: truck ? truck.truckNumber : 'N/A', // Numărul camionului
          Location: report.location || 'N/A',
          'Fuel Subtotal': report.fuelSubtotal ? `$${report.fuelSubtotal.toFixed(2)}` : 'N/A',
          'Other Costs': report.otherCosts ? `$${report.otherCosts.toFixed(2)}` : 'N/A',
          'Grand Total': report.grandTotal ? `$${report.grandTotal.toFixed(2)}` : 'N/A',
          'Created At': report.createdAt ? new Date(report.createdAt).toLocaleString() : 'N/A',
        };
      });
  
    if (selectedData.length === 0) {
      alert('No reports selected for export!');
      return;
    }
  
    // Exportăm în Excel
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Fuel Report');
    XLSX.writeFile(workbook, 'SelectedFuelReport.xlsx');
  };
  
  
  

  const handleSelect = (id) => {
    setSelectedReports((prev) =>
      prev.includes(id) ? prev.filter((reportId) => reportId !== id) : [...prev, id]
    );
  };

  const selectAll = () => {
    if (selectedReports.length === fuelReports.length) {
      setSelectedReports([]);
    } else {
      setSelectedReports(fuelReports.map((report) => report._id));
    }
  };

  const filteredReports = fuelReports.filter((report) => {
    const driver = drivers.find((d) => d._id === report.driverId);
    const truck = trucks.find((t) => t._id === report.truckId);
    return (
      (driver ? `${driver.firstName} ${driver.lastName}`.toLowerCase() : '').includes(searchTerm.toLowerCase()) ||
      (truck ? truck.truckNumber.toString() : '').includes(searchTerm.toLowerCase()) ||
      report.location.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const currentReports = filteredReports.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div className={styles.fuelReportContainer}>
      <h2 className={styles.titlefuelReport}>Fuel Report</h2>
      <div className={styles.formContainerfuelReport}>
  <label>Driver:</label>
  <select name="driverId" value={fuelData.driverId} onChange={handleInputChange}>
    <option value="">Select Driver</option>
    {drivers.map((driver) => (
      <option key={driver._id} value={driver._id}>
        {driver.firstName} {driver.lastName}
      </option>
    ))}
  </select>

  <label>Truck:</label>
  <select name="truckId" value={fuelData.truckId} onChange={handleInputChange}>
    <option value="">Select Truck</option>
    {trucks.map((truck) => (
      <option key={truck._id} value={truck._id}>
        {truck.truckNumber}
      </option>
    ))}
  </select>


        <label>Payment Method:</label>
        <select name="paymentMethod" value={fuelData.paymentMethod} onChange={handleInputChange}>
          <option value="">Select Payment Method</option>
          <option value="cash">Cash</option>
          <option value="private">Private</option>
          <option value="card">Card</option>
        </select>

        <label>Location:</label>
        <input
          type="text"
          name="location"
          value={fuelData.location}
          onChange={handleInputChange}
        />

        <label>Fuel Subtotal:</label>
        <input
          type="number"
          name="fuelSubtotal"
          value={fuelData.fuelSubtotal}
          onChange={handleInputChange}
        />

        <label>Other Costs:</label>
        <input
          type="number"
          name="otherCosts"
          value={fuelData.otherCosts}
          onChange={handleInputChange}
        />

        <label>Grand Total:</label>
        <input type="number" name="grandTotal" value={fuelData.grandTotal} readOnly />

        <button onClick={isEditing ? editFuelEntry : addFuelEntry}>
    {isEditing ? 'Update Fuel Entry' : 'Add Fuel Entry'}
  </button>
  {isEditing && (
    <button onClick={() => setIsEditing(null)}>
      Cancel
    </button>
  )}
</div>
      <div className={styles.buttonGroupfuelReport}>
        <button onClick={handleExport}>Export to Excel</button>
        <button onClick={selectAll}>Select All</button>
      </div>

      {/* Tabel */}
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>Driver</th>
            <th>Truck</th>
            <th>Location</th>
            <th>Fuel Subtotal</th>
            <th>Other Costs</th>
            <th>Grand Total</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {currentReports.map((entry) => (
    <tr key={entry._id}>
      <td>
        <input
          type="checkbox"
          checked={selectedReports.includes(entry._id)}
          onChange={() => handleSelect(entry._id)}
        />
      </td>
      <td>{entry.driverId ? `${entry.driverId.firstName} ${entry.driverId.lastName}` : 'N/A'}</td>
      <td>{entry.truckId ? entry.truckId.truckNumber : 'N/A'}</td>
      <td>{entry.location || 'N/A'}</td>
      <td>{entry.fuelSubtotal ? `$${entry.fuelSubtotal.toFixed(2)}` : 'N/A'}</td>
      <td>{entry.otherCosts ? `$${entry.otherCosts.toFixed(2)}` : 'N/A'}</td>
      <td>{entry.grandTotal ? `$${entry.grandTotal.toFixed(2)}` : 'N/A'}</td>
      <td>{entry.createdAt ? new Date(entry.createdAt).toLocaleString() : 'N/A'}</td>
      <td>
  <button onClick={() => handleEditClick(entry)}>Edit</button>
  <button onClick={() => deleteFuelEntry(entry._id)}>Delete</button>
</td>
    </tr>
  ))}
</tbody>


      </table>

      {/* Paginare */}
      <div>
        <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>Previous</button>
        <button
          onClick={() => setCurrentPage((prev) => prev + 1)}
          disabled={currentPage * rowsPerPage >= filteredReports.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};


const Invoice = ({ loads, settings, setLoads, drivers, trucks, brokers, consignee, shippers }) => {
  const [search, setSearch] = useState('');
  const [filteredLoads, setFilteredLoads] = useState([]);
  const [selectedLoads, setSelectedLoads] = useState([]);
  const [invoices, setInvoices] = useState([]); // Starea pentru facturi
  const [showPDFPreview, setShowPDFPreview] = useState(false);
  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [emailDetails, setEmailDetails] = useState({ subject: '', message: '', brokerEmail: '' });
  const [activeTab, setActiveTab] = useState('open'); // 'open' sau 'invoiced'
  const apiUrl = 'https://api-backend.full-trucking-service.com/api';
  const token = sessionStorage.getItem('token');

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${apiUrl}/invoices`, config);
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices:', error);
        alert('Failed to fetch invoices.');
      }
    };

    fetchInvoices();
  }, []);

  useEffect(() => {
    if (activeTab === 'open') {
      // Filtrăm încărcăturile care nu sunt facturate
      setFilteredLoads(
        loads.filter(
          (load) =>
            !load.isInvoiced &&
            (load.loadNumber?.toLowerCase().includes(search.toLowerCase()) ||
              load.brokerName?.toLowerCase().includes(search.toLowerCase()))
        )
      );
    } else if (activeTab === 'invoiced') {
      // Filtrăm facturile salvate
      setFilteredLoads(
        invoices.filter(
          (invoice) =>
            invoice.loadNumber?.toLowerCase().includes(search.toLowerCase()) ||
            invoice.brokerName?.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, loads, invoices, activeTab]);
  
  const handleSelectLoad = (loadId) => {
    if (!loadId || !filteredLoads.some((load) => load._id === loadId)) {
      console.error('Invalid load ID selected:', loadId);
      return;
    }
  
    setSelectedLoads((prevSelected) =>
      prevSelected.includes(loadId)
        ? prevSelected.filter((id) => id !== loadId)
        : [...prevSelected, loadId]
    );
  };
  

  const handleEditInvoiceNumber = (loadId, newInvoiceNumber) => {
    setFilteredLoads((prevLoads) =>
      prevLoads.map((load) =>
        load._id === loadId ? { ...load, invoiceNumber: newInvoiceNumber } : load
      )
    );
  };

  const handlePreviewPDF = () => {
    setShowPDFPreview(true);
  };

  const handleClosePDFPreview = () => {
    setShowPDFPreview(false);
  };
  const handleSaveInvoice = async () => {
    const loadsToSave = filteredLoads.filter((load) => selectedLoads.includes(load._id));

    if (!loadsToSave || loadsToSave.length === 0) {
      alert('No loads selected for invoicing!');
      return;
    }

    const invoicesData = loadsToSave.map((load) => ({
      loadNumber: load.loadNumber || 'N/A',
      status: load.status || 'N/A',
      brokerName: load.brokerName || 'N/A',
      brokerAddress: load.brokerAddress || 'N/A',
      invoiceNumber: load.invoiceNumber || 'N/A',
      invoiceDate: new Date(),
      rate: load.rate || 0,
      driver: load.driver || 'N/A',
      shippers: load.shippers || [],
      consignees: load.consignees || [],
      notes: invoiceNotes,
      isInvoiced: true,
    }));

    try {
      await axios.post(`${apiUrl}/invoices`, { invoices: invoicesData }, config);

      // Reîncarcă facturile din backend
      const response = await axios.get(`${apiUrl}/invoices`, config);
      setInvoices(response.data);
      setShowPDFPreview(false);
      setActiveTab('invoiced');
      setSelectedLoads([]);
      alert('Invoice saved and moved to Invoiced tab successfully.');
    } catch (error) {
      console.error('Error saving invoice:', error);
      alert('Failed to save invoice.');
    }
  };

  
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };
  
  // Funcție pentru generarea unui PDF Blob și crearea unui URL descărcabil
  const generatePDFBlob = async (PDFContent) => {
    try {
      const blob = await pdf(PDFContent).toBlob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error generating PDF Blob:", error);
      alert("Failed to generate PDF. Please try again.");
      return null;
    }
  };
  
  // Funcție pentru deschiderea aplicației locale de email
  const openEmailClient = async () => {
    if (selectedLoads.length === 0) {
      alert("No loads selected for sending!");
      return;
    }
  
    try {
      const PDFUrl = await generatePDFBlob(PDFContent);
  
      if (!PDFUrl) {
        alert("Unable to generate PDF for email.");
        return;
      }
  
      const recipient = emailDetails.brokerEmail || ""; // Email-ul brokerului
      const subject = encodeURIComponent(emailDetails.subject || "Invoice Details");
      const body = encodeURIComponent(
        `${emailDetails.message || "Please find the attached invoice."}\n\nDownload the invoice here: ${PDFUrl}`
      );
      const cc = encodeURIComponent(settings.email || ""); // Email-ul companiei clientului
  
      // Construim link-ul pentru `mailto:`
      const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}&cc=${cc}`;
  
      // Deschidem aplicația locală de email
      window.location.href = mailtoLink;
  
      // Deschidem PDF-ul într-un tab separat (opțional, pentru transparență)
      window.open(PDFUrl, "_blank");
    } catch (error) {
      console.error("Error opening email client:", error);
      alert("Failed to open email client. Please try again.");
    }
  };
  
  // Handler principal pentru trimiterea email-ului
  const handleSendEmail = async () => {
    await openEmailClient();
  };
  
 const PDFContent = (() => {
  const selectedLoadObjects = selectedLoads.map((id) =>
    activeTab === 'open'
      ? loads.find((load) => load._id === id)
      : invoices.find((invoice) => invoice._id === id)
  );

  return (
    <Document>
      <Page style={{ padding: 40, fontFamily: 'Helvetica', backgroundColor: '#f8f9fa' }}>
        {/* Header Section */}
        <View style={{ marginBottom: 30 }}>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View>
              <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#343a40' }}>{settings.companyName}</Text>
              <Text style={{ fontSize: 12, color: '#6c757d' }}>{settings.companyAddress}</Text>
              <Text style={{ fontSize: 12, color: '#6c757d' }}>Phone: {settings.phone}</Text>
              <Text style={{ fontSize: 12, color: '#6c757d' }}>Email: {settings.email}</Text>
            </View>
            <View style={{ textAlign: 'right' }}>
              <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#343a40' }}>Invoice</Text>
              <Text style={{ fontSize: 12, color: '#6c757d' }}>Date: {new Date().toLocaleDateString()}</Text>
              <Text style={{ fontSize: 12, color: '#6c757d' }}>
                Invoice Number: {selectedLoadObjects[0]?.invoiceNumber || 'Invoice Number Missing'}
              </Text>
            </View>
          </View>
        </View>

        {/* Bill To Section */}
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 14, fontWeight: 'bold', color: '#343a40' }}>Bill To:</Text>
          <Text style={{ fontSize: 12, color: '#6c757d' }}>{selectedLoadObjects[0]?.brokerName || 'Broker Name Missing'}</Text>
          <Text style={{ fontSize: 12, color: '#6c757d' }}>{selectedLoadObjects[0]?.brokerAddress || 'Broker Address Missing'}</Text>
        </View>

        {/* Table Section */}
        <View>
          {/* Table Header */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#343a40',
              color: '#ffffff',
              paddingVertical: 8,
              paddingHorizontal: 10,
              borderRadius: 4,
            }}
          >
            <Text style={{ flex: 1, fontSize: 12, fontWeight: 'bold' }}>Qty</Text>
            <Text style={{ flex: 4, fontSize: 12, fontWeight: 'bold' }}>Description</Text>
            <Text style={{ flex: 1, fontSize: 12, fontWeight: 'bold', textAlign: 'right' }}>Unit Price</Text>
            <Text style={{ flex: 1, fontSize: 12, fontWeight: 'bold', textAlign: 'right' }}>Line Price</Text>
          </View>

          {/* Table Rows */}
          {selectedLoadObjects.map((load, index) => (
            <View
              key={load?._id || index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingVertical: 8,
                paddingHorizontal: 10,
                borderBottom: '1px solid #dee2e6',
              }}
            >
              <Text style={{ flex: 1, fontSize: 12 }}>{index + 1}</Text>
              <Text style={{ flex: 4, fontSize: 12 }}>
                {(load?.shippers?.[0]?.address || 'Pickup Address Missing')} to{' '}
                {(load?.consignees?.[0]?.address || 'Delivery Address Missing')}
              </Text>
              <Text style={{ flex: 1, fontSize: 12, textAlign: 'right' }}>
                ${load?.rate ? load.rate.toFixed(2) : '0.00'}
              </Text>
              <Text style={{ flex: 1, fontSize: 12, textAlign: 'right' }}>
                ${load?.rate ? load.rate.toFixed(2) : '0.00'}
              </Text>
            </View>
          ))}

          {/* Table Footer */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingVertical: 8,
              paddingHorizontal: 10,
              backgroundColor: '#f8f9fa',
              borderTop: '2px solid #343a40',
              marginTop: 10,
            }}
          >
            <Text style={{ flex: 5, fontSize: 12, textAlign: 'right', fontWeight: 'bold', color: '#343a40' }}>
              Total:
            </Text>
            <Text style={{ flex: 1, fontSize: 12, textAlign: 'right', fontWeight: 'bold', color: '#343a40' }}>
              ${selectedLoadObjects.reduce((total, load) => total + (load?.rate || 0), 0).toFixed(2)}
            </Text>
          </View>
        </View>

        {/* Notes Section */}
        <View style={{ marginTop: 20, padding: 10, backgroundColor: '#e9ecef', borderRadius: 4 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', color: '#343a40' }}>Notes:</Text>
          <Text style={{ fontSize: 12, color: '#495057' }}>{invoiceNotes || 'No additional notes.'}</Text>
        </View>

        {/* Footer Section */}
        <View style={{ marginTop: 40, textAlign: 'center', color: '#6c757d' }}>
          <Text style={{ fontSize: 12 }}>Thank you for your business!</Text>
          <Text style={{ fontSize: 12 }}>If you have any questions, please contact {settings.email}</Text>
        </View>
      </Page>
    </Document>
  );
})();

  
  
  

  return (
    <div className={styles.invoiceContainer}>
      <h2>Invoices</h2>
      <div>
        <button onClick={() => setActiveTab('open')}>Open Invoice</button>
        <button onClick={() => setActiveTab('invoiced')}>Invoiced</button>
      </div>
      <input
        type="text"
        placeholder="Search by Load Number or Broker Name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInputinvoice}
      />
      <textarea
        placeholder="Add notes to the invoice"
        value={invoiceNotes}
        onChange={(e) => setInvoiceNotes(e.target.value)}
        className={styles.notesInputinvoice}
      />
      <table className={styles.invoiceTableinvoice}>
        <thead>
          <tr>
            <th>Select</th>
            <th>Load</th>
            <th>Status</th>
            <th>Load Number / POD</th>
            <th>Broker Name</th>
            <th>Broker Address</th>
            <th>Invoice Number</th>
            <th>Invoice Date</th>
            <th>Rate</th>
            <th>Driver</th>
            <th>Pick Up</th>
            <th>Delivery</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredLoads.map((load, index) => (
            <tr key={load._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedLoads.includes(load._id)}
                  onChange={() => handleSelectLoad(load._id)}
                />
              </td>
              <td>{index + 1}</td>
              <td>{load.status}</td>
              <td>{load.loadNumber}</td>
              <td>{load.brokerName}</td>
              <td>{load.brokerAddress}</td>
              
              <td>
                <input
                  type="text"
                  value={load.invoiceNumber || index + 1}
                  onChange={(e) => handleEditInvoiceNumber(load._id, e.target.value)}
                />
              </td>
              <td>{new Date(load.createdAt).toLocaleDateString()}</td>
              <td>{load.rate}</td>
              <td>
  {(() => {
    const driver = drivers.find((d) => d._id === load.driver);
    return driver ? `${driver.firstName} ${driver.lastName}` : 'N/A';
  })()}
</td>

              <td>
                {load.shippers.map((shipper, i) => (
                  <div key={i}>{shipper.pickUpTime ? new Date(shipper.pickUpTime).toLocaleDateString() : 'N/A'}</div>
                ))}
              </td>
              <td>
                {load.consignees.map((consignee, i) => (
                  <div key={i}>{consignee.deliveryTime ? new Date(consignee.deliveryTime).toLocaleDateString() : 'N/A'}</div>
                ))}
              </td>
              <td>
                <button onClick={handlePreviewPDF}>Preview PDF</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showPDFPreview && (
  <div className={styles.pdfOverlayinvoice}>
    <div className={styles.pdfViewerContainerinvoice}>
      <PDFViewer width="100%" height="500">
        {PDFContent}
      </PDFViewer>

      {/* Secțiunea pentru butoane și câmpuri */}
      <div className={styles.pdfActionsContainer}>
        <div className={styles.buttonGroup}>
          <button onClick={handleSaveInvoice}>Save Invoice</button>
          <button onClick={handleClosePDFPreview}>Close Preview</button>
          <button onClick={handleSendEmail}>Send via Email</button>
        </div>

        <div className={styles.emailFields}>
          <input
            type="text"
            placeholder="Email Subject"
            name="subject"
            value={emailDetails.subject}
            onChange={handleEmailChange}
          />
          <textarea
            placeholder="Message"
            name="message"
            value={emailDetails.message}
            onChange={handleEmailChange}
          />
          <input
            type="email"
            placeholder="Broker's Email"
            name="brokerEmail"
            value={emailDetails.brokerEmail}
            onChange={handleEmailChange}
          />
        </div>
      </div>
    </div>
  </div>
)}
 </div>
  );
};




const AddLoad = ({
  onClose,
  onAddLoad,
  drivers,
  trucks,
  trailers,
  handleUploadRC,
  brokers,
  shippers,
  consignees,
  dispatchers,
  currentUserId,
  currentUserRole
}) => {
  
  const [loadData, setLoadData] = useState({
    loadNumber: "",
    dispatcher: "",
    brokerName: "",
    brokerAddress: "",
    rate: "",
    shippers: [],
    consignees: [],
    dispatcher: "",
    driver: "",
    teamDrivers: [],
    truck: "",
    trailers: "",
    status: "",
    miles: "",
    dh: "",
    notes: "",
  });

  const [shippersSuggestions, setShippersSuggestions] = useState([]);
  const [consigneesSuggestions, setConsigneesSuggestions] = useState([]);
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const token = sessionStorage.getItem('token');  // Obține tokenul din sessionStorage
  const clientId = localStorage.getItem('clientId');  // Obține clientId din localStorage
  const apiUrl = 'https://api-backend.full-trucking-service.com/api'; // URL-ul backend-ului
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,  // Adaugă tokenul cu prefixul Bearer
      'Content-Type': 'application/json',
    },
    withCredentials: true,  // Trimite cookies pentru autentificare
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoadData((prevState) => ({
      ...prevState,
      [name]: value || "",
    }));
  };

  const handleDriverChange = (e) => {
    setLoadData((prevState) => ({
      ...prevState,
      driver: e.target.value,
    }));
  };

  // Selectare șoferi pentru echipă (Team Drivers)
  const handleTeamDriverChange = (selectedOptions) => {
    setLoadData((prevState) => ({
      ...prevState,
      teamDrivers: selectedOptions ? selectedOptions.map((option) => option.value) : [],
    }));
  };

  const handleBrokerSelect = (e) => {
    const selectedBrokerName = e.target.value;
    const selectedBroker = brokers.find(
      (b) => b.brokerName === selectedBrokerName
    );
    setLoadData((prevState) => ({
      ...prevState,
      brokerName: selectedBrokerName,
      brokerAddress: selectedBroker ? selectedBroker.address : "",
    }));
  };
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const shippersResponse = await axios.get(`${apiUrl}/shippers`, config);
        const consigneesResponse = await axios.get(`${apiUrl}/consignees`, config);
        setShippersSuggestions(shippersResponse.data);
        setConsigneesSuggestions(consigneesResponse.data);
      } catch (error) {
        console.error("Error fetching shippers and consignees:", error);
      }
    };

    fetchSuggestions();
  }, []);
  const handleShipperSelect = (index, selectedName) => {
    const selectedShipper = shippersSuggestions.find(
      (shipper) => shipper.shipperName === selectedName
    );
    
    if (selectedShipper) {
      const newShippers = [...loadData.shippers];
      newShippers[index] = {
        ...newShippers[index],
        name: selectedShipper.shipperName,
        address: selectedShipper.shipperAddress, // setează adresa corectă
      };
      setLoadData((prevState) => ({ ...prevState, shippers: newShippers }));
    }
  };
  
  const handleConsigneeSelect = (index, selectedName) => {
    const selectedConsignee = consigneesSuggestions.find(
      (consignee) => consignee.consigneeName === selectedName
    );
  
    if (selectedConsignee) {
      const newConsignees = [...loadData.consignees];
      newConsignees[index] = {
        ...newConsignees[index],
        name: selectedConsignee.consigneeName,
        address: selectedConsignee.consigneeAddress, // setează adresa corectă
      };
      setLoadData((prevState) => ({ ...prevState, consignees: newConsignees }));
    }
  };
  
  
  

  const handleAddShipper = () => {
    setLoadData((prevState) => ({
      ...prevState,
      shippers: [...prevState.shippers, { name: "", address: "", pickUpTime: "" }], // folosește 'name' și 'address'
    }));
  };
  
  const handleAddConsignee = () => {
    setLoadData((prevState) => ({
      ...prevState,
      consignees: [...prevState.consignees, { name: "", address: "", deliveryTime: "" }], // folosește 'name' și 'address'
    }));
  };
  

  const handleRemoveShipper = (index) => {
    setLoadData((prevState) => ({
      ...prevState,
      shippers: prevState.shippers.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveConsignee = (index) => {
    setLoadData((prevState) => ({
      ...prevState,
      consignees: prevState.consignees.filter((_, i) => i !== index),
    }));
  };

  const handleShipperChange = (index, e) => {
    const { name, value } = e.target;
    const newShippers = [...loadData.shippers];
    newShippers[index][name] = value;
    setLoadData((prevState) => ({ ...prevState, shippers: newShippers }));
  };

  const handleConsigneeChange = (index, e) => {
    const { name, value } = e.target;
    const newConsignees = [...loadData.consignees];
    newConsignees[index][name] = value;
    setLoadData((prevState) => ({ ...prevState, consignees: newConsignees }));
  };

  const calculateMiles = async () => {
    const allAddresses = [
      ...loadData.shippers.map((s) => s.address),
      ...loadData.consignees.map((c) => c.address),
    ];

    if (allAddresses.length < 2) return;

    try {
      let totalMiles = 0;
      for (let i = 0; i < allAddresses.length - 1; i++) {
        const url = `${apiUrl}/calculate-distance?origins=${encodeURIComponent(
          allAddresses[i]
        )}&destinations=${encodeURIComponent(allAddresses[i + 1])}&apiKey=${apiKey}`;

        const response = await axios.get(url);
        const data = response.data;

        if (data.distance) {
          const distanceInMeters = data.distance.value;
          totalMiles += distanceInMeters * 0.000621371;
        }
      }
      setLoadData((prevState) => ({ ...prevState, miles: totalMiles.toFixed(2) }));
    } catch (error) {
      console.error("Error calculating miles:", error);
    }
  };
  useEffect(() => {
    // Căutăm dispatcher-ul curent bazat pe clientId, în cazul în care utilizatorul autentificat este client
    console.log("Current User ID:", currentUserId);
    console.log("Dispatchers Array:", dispatchers);
  
    if (
      currentUserRole === 'dispatcher' &&
      Array.isArray(dispatchers) &&
      dispatchers.length > 0 &&
      currentUserId
    ) {
      // Dacă `currentUserId` este al clientului, găsim dispatcher-ul bazat pe `clientId`
      const currentDispatcher = dispatchers.find(d => d.clientId === currentUserId);
      console.log("Current Dispatcher:", currentDispatcher);
  
      if (currentDispatcher) {
        setLoadData(prev => ({
          ...prev,
          dispatcher: currentDispatcher._id
        }));
      }
    }
  }, [currentUserId, dispatchers, currentUserRole]);
  
  
  
  useEffect(() => {
    if (loadData.shippers.length && loadData.consignees.length) {
      calculateMiles();
    }
  }, [loadData.shippers, loadData.consignees]);
  const handleSubmit = async () => { 
    try {
      const dataToSend = { ...loadData, clientId }; 
      
      // Salvează shipperii și consignees
      for (const shipper of loadData.shippers) {
        const existingShipper = shippersSuggestions.find(
          (s) => s.shipperName === shipper.name && s.shipperAddress === shipper.address
        );
        if (!existingShipper) {
          await axios.post(`${apiUrl}/shippers`, { shipperName: shipper.name, shipperAddress: shipper.address }, config);
        }
      }
  
      for (const consignee of loadData.consignees) {
        const existingConsignee = consigneesSuggestions.find(
          (c) => c.consigneeName === consignee.name && c.consigneeAddress === consignee.address
        );
        if (!existingConsignee) {
          await axios.post(`${apiUrl}/consignees`, { consigneeName: consignee.name, consigneeAddress: consignee.address }, config);
        }
      }
  
      // Salvează încărcătura (load)
      await axios.post(`${apiUrl}/loads`, dataToSend, config);
  
      // Resetează formularul
      setLoadData({
        loadNumber: "",
        dispatcher: "",
        brokerName: "",
        brokerAddress: "",
        rate: "",
        equipment: "",
        driver: "",
        teamDrivers: [],
        truck: "",
        trailers: "",
        status: "",
        shippers: [],
        consignees: [],
        miles: "",
        dh: "",
        notes: "",
      });
  
      console.log("Load and related shippers/consignees added successfully");
  
    } catch (error) {
      console.error("Error adding load:", error);
      alert("There was an error saving the load: " + error.message);
    }
  };
  

  const saveBrokersShippersConsignees = async () => {
    try {
      const brokerExists = brokers.some(
        (broker) => broker.brokerName === loadData.brokerName
      );
      if (!brokerExists) {
        await axios.post(`${apiUrl}/brokers`, {
          brokerName: loadData.brokerName,
          brokerAddress: loadData.brokerAddress,
        }, config);
      }

      const shipperExists = shippers.some(
        (shipper) => shipper.shipperName === loadData.shipperName
      );
      if (!shipperExists) {
        await axios.post(`${apiUrl}/shippers`, {
          shipperName: loadData.shipperName,
          shipperAddress: loadData.shipperAddress,
        }, config);
      }

      const consigneeExists = consignees.some(
        (consignee) => consignee.consigneeName === loadData.consigneeName
      );
      if (!consigneeExists) {
        await axios.post(`${apiUrl}/consignees`, {
          consigneeName: loadData.consigneeName,
          consigneeAddress: loadData.consigneeAddress,
        }, config);
      }

      console.log("All brokers, shippers, and consignees saved successfully");
    } catch (error) {
      console.error("Error saving brokers, shippers, or consignees:", error);
      throw error;
    }
  };
  const [isLoading, setIsLoading] = useState(false);

const handleFileUpload = async (e) => { 
  const file = e.target.files[0];
  console.log("File selected for upload:", file);

  if (!file) {
    alert("No file selected");
    return;
  }

  // Setăm isLoading pe true pentru a afișa indicatorul de încărcare
  setIsLoading(true);

  const fileContent = await fileToBase64(file);

  try {
    const response = await axios.post('https://api-backend.full-trucking-service.com/api/upload-rc', { fileContent }, {
      headers: { 'Content-Type': 'application/json' },
    });

    const extractedData = response.data.extractedData;
    console.log("Extracted data received from backend:", extractedData);

    if (!extractedData || Object.keys(extractedData).length === 0) {
      alert("No relevant data found in the uploaded document. Please check the document format.");
      setIsLoading(false); // Oprim indicatorul de încărcare
      return;
    }

    const formatDateTimeLocal = (dateTime) => {
      const date = new Date(dateTime);
      return !isNaN(date.getTime()) ? date.toISOString().slice(0, 16) : ""; // returnează "" dacă data nu este validă
    };

    setLoadData((prevState) => ({
      ...prevState,
      loadNumber: extractedData.loadNumber || prevState.loadNumber,
      rate: extractedData.rate || prevState.rate,
      brokerName: extractedData.brokerName || prevState.brokerName,
      brokerAddress: extractedData.brokerAddress || prevState.brokerAddress,
      shippers: Array.isArray(extractedData.shippers) ? extractedData.shippers.map(shipper => ({
        name: shipper.name || "",
        address: shipper.address || "",
        pickUpTime: formatDateTimeLocal(shipper.pickUpTime) // completăm doar dacă data este validă
      })) : prevState.shippers,
      consignees: Array.isArray(extractedData.consignees) ? extractedData.consignees.map(consignee => ({
        name: consignee.name || "",
        address: consignee.address || "",
        deliveryTime: formatDateTimeLocal(consignee.deliveryTime) // completăm doar dacă data este validă
      })) : prevState.consignees,
    }));

    alert("Data successfully extracted and populated in the form.");
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Error uploading the file. Please try again.");
  } finally {
    setIsLoading(false); // Oprim indicatorul de încărcare
  }
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};
  
  
  const handleCancel = () => {
    onClose();
  };
  return (
    <div className={styles.sectionContainer}>
      <h2 className={styles.title}>Add New Load</h2>
      <form className={styles.loadForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Load Number</label>
            <input
              name="loadNumber"
              value={loadData.loadNumber}
              onChange={handleChange}
              placeholder="Load Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
          <label>Dispatcher</label>
          <select
  name="dispatcher"
  value={loadData.dispatcher}
  onChange={handleChange}
  className={styles.selectField}
  disabled={currentUserRole === 'dispatcher'}
  required
>
  {currentUserRole === 'dispatcher' ? (
    dispatchers.length > 0 ? (
      <option value={currentUserId}>
        {
          dispatchers.find(d => d.clientId === currentUserId)?.firstName
        } {
          dispatchers.find(d => d.clientId === currentUserId)?.lastName
        }
      </option>
    ) : (
      <option>Loading...</option>
    )
  ) : (
    <>
      <option value="">Select Dispatcher</option>
      {dispatchers.map((dispatcher) => (
        <option key={dispatcher._id} value={dispatcher._id}>
          {dispatcher.firstName} {dispatcher.lastName}
        </option>
      ))}
    </>
  )}
</select>

        </div>


        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Broker Name</label>
            <input
              list="brokers-list"
              name="brokerName"
              value={loadData.brokerName}
              onChange={handleBrokerSelect}
              placeholder="Broker Name"
              required
              className={styles.inputField}
            />
            <datalist id="brokers-list">
              {brokers.map((broker) => (
                <option key={broker._id} value={broker.brokerName} />
              ))}
            </datalist>
          </div>
          <div className={styles.formGroup}>
            <label>Broker Address</label>
            <input
              name="brokerAddress"
              value={loadData.brokerAddress}
              onChange={handleChange}
              placeholder="Broker Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Rate</label>
            <input
              name="rate"
              value={loadData.rate}
              onChange={handleChange}
              placeholder="Rate"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Equipment</label>
            <select
              name="equipment"
              value={loadData.equipment}
              onChange={handleChange}
              className={styles.selectField}
            >
              <option value="">Select Equipment</option>
              <option value="Dry Van">Dry Van</option>
              <option value="53 Van">53 Van</option>
              <option value="53' Reefer">53 Reefer</option>
              <option value="Air Freight">Air Freight</option>
              <option value="Airride/Logistical Van">Airride/Logistical Van</option>
              <option value="Any Equipment">Any Equipment</option>
              <option value="Beam">Beam</option>
              <option value="Cargo Van (1 Ton)">Cargo Van</option>
              <option value="Conestoga">Conestoga</option>
              <option value="Convertible Hopper">Convertible Hopper</option>
              <option value="Conveyor Belt">Conveyor Belt</option>
              <option value="Curtain Siders">Curtain Siders</option>
              <option value="Curtain Van">Curtain Van</option>
              <option value="Double Drop">Double Drop</option>
              <option value="Drive Away">Drive Away</option>
              <option value="Flat Intermodal">Flat Intermodal</option>
              <option value="Flat or Step Deck">Flat or Step Deck</option>
              <option value="Flat with Tarps">Flat with Tarps</option>
              <option value="Flatbed">Flatbed</option>
              <option value="Flatbed Air-Ride">Flatbed Air-Ride</option>
              <option value="Flatbed Blanket Wrapped">Flatbed Blanket Wrapped</option>
              <option value="Flatbed or Van">Flatbed or Van</option>
              <option value="Haul and Tow Unit">Haul and Tow Unit</option>
              <option value="Hazardous Material Load">Hazardous Material Load</option>
              <option value="Landoll Flatbed">Landoll Flatbed</option>
              <option value="Live Bottom Trailer">Live Bottom Trailer</option>
              <option value="Load-Out">Load-Out</option>
              <option value="Lowboy">Lowboy</option>
              <option value="Lowboy over-dimension load">Lowboy over-dimension load</option>
              <option value="Maxi or Double Flat Trailers">Maxi or Double Flat Trailers</option>
              <option value="Maxi/Double Flat Trailer">Maxi/Double Flat Trailer</option>
              <option value="Moving Van">Moving Van</option>
              <option value="Multi-Axle Heavy Hauler">Multi-Axle Heavy Hauler</option>
              <option value="Pneumatic">Pneumatic</option>
              <option value="Power Only">Power Only</option>
              <option value="Reefer Pallet Exchange">Reefer Pallet Exchange</option>
              <option value="Refrigerated Carrier">Refrigerated Carrier</option>
              <option value="Removable Goose Neck">Removable Goose Neck</option>
              <option value="Removable Goose Neck & Multi-Axle Heavy Haulers">Removable Goose Neck & Multi-Axle Heavy Haulers</option>
              <option value="RGN Extendable">RGN Extendable</option>
              <option value="Step Deck"></option>
              <option value="Straight Van">Straight Van</option>
              <option value="Tandem Van">Tandem Van</option>
              <option value="Tanker">Tanker</option>
              <option value="Team Driver Needed">Team Driver Needed</option>
              <option value="Van">Van</option>
              <option value="Van Air-Ride">Van Air-Ride</option>
              <option value="Van intermodal">Van intermodal</option>
              <option value="Van or Flatbed">Van or Flatbed</option>
              <option value="Van or Reefer">Van or Reefer</option>
              <option value="Van Pallet Exchange">Van Pallet Exchange</option>
              <option value="Vented Insulated Van">Vented Insulated Van</option>
              <option value="Vented Van">Vented Van</option>
              <option value="Walking Floor">Walking Floor</option>
            </select>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Driver</label>
            <select
              name="driver"
              value={loadData.driver}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Driver</option>
              {drivers.map((driver, index) => (
                <option key={index} value={driver._id}>
                  {driver.firstName} {driver.lastName}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Team Drivers</label>
            <Select
              isMulti
              options={drivers.map((driver) => ({
                value: driver._id,
                label: `${driver.firstName} ${driver.lastName}`,
              }))}
              onChange={handleTeamDriverChange}
              className={styles.selectField}
              placeholder="Select Team Drivers"
            />
          </div>
          <div className={styles.formGroup}>
            <label>Truck</label>
            <select
              name="truck"
              value={loadData.truck}
              onChange={handleChange}
              className={styles.selectField}
            >
              <option value="">Select Truck</option>
              {trucks.map((truck, index) => (
                <option key={index} value={truck._id}>
                  {truck.truckNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={styles.formGroup}>
  <label>Trailer</label>
  <select
    name="trailer"
    value={loadData.trailer}
    onChange={handleChange}
    className={styles.selectField}
  >
    <option value="">Select Trailer</option>
    {Array.isArray(trailers) && trailers.map((trailer) => (
  <option key={trailer._id} value={trailer._id}>
    {trailer.trailerNumber}
  </option>
))}

  </select>
</div>


 
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Status</label>
            <select
              name="status"
              value={loadData.status}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Status</option>
              <option value="Pending">Pending</option>
              <option value="Open">Open</option>
              <option value="Refused">Refused</option>
              <option value="Assigned">Assigned</option>
              <option value="Dispatched">Dispatched</option>
              <option value="On Route">On Route</option>
              <option value="In Progress">In Progress</option>
              <option value="Delivered">Delivered</option>
              <option value="(Un)Loading">Un-Loading</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
              <option value="In Yard">In Yard</option>

            </select>
          </div>
        </div>

   {/* Shippers Section */}
<div className={styles.section}>
  <h3>Shippers</h3>
  {loadData.shippers.map((shipper, index) => (
    <div key={index} className={styles.formGroup}>
      <input
        name="name"
        list={`shipper-suggestions-${index}`}
        value={shipper.name}
        onChange={(e) => {
          handleShipperChange(index, e);
          handleShipperSelect(index, e.target.value); // adaugă această linie pentru a asigura completarea adresei
        }}
        placeholder="Shipper Name"
        className={styles.inputField}
      />
      <datalist id={`shipper-suggestions-${index}`}>
        {shippersSuggestions.map((sugg) => (
          <option key={sugg._id} value={sugg.shipperName} />
        ))}
      </datalist>
      <input
        name="address"
        value={shipper.address}
        onChange={(e) => handleShipperChange(index, e)}
        placeholder="Shipper Address"
        className={styles.inputField}
      />
      <input
        name="pickUpTime"
        type="datetime-local"
        value={shipper.pickUpTime}
        onChange={(e) => handleShipperChange(index, e)}
        className={styles.inputField}
      />
      <button type="button" onClick={() => handleRemoveShipper(index)}>
        Remove
      </button>
    </div>
  ))}
  <button type="button" onClick={handleAddShipper}>
    Add Shipper
  </button>
</div>

{/* Consignees Section */}
<div className={styles.section}>
  <h3>Consignees</h3>
  {loadData.consignees.map((consignee, index) => (
    <div key={index} className={styles.formGroup}>
      <input
        name="name"
        list={`consignee-suggestions-${index}`}
        value={consignee.name}
        onChange={(e) => {
          handleConsigneeChange(index, e);
          handleConsigneeSelect(index, e.target.value); // adaugă această linie pentru a asigura completarea adresei
        }}
        placeholder="Consignee Name"
        className={styles.inputField}
      />
      <datalist id={`consignee-suggestions-${index}`}>
        {consigneesSuggestions.map((sugg) => (
          <option key={sugg._id} value={sugg.consigneeName} />
        ))}
      </datalist>
      <input
        name="address"
        value={consignee.address}
        onChange={(e) => handleConsigneeChange(index, e)}
        placeholder="Consignee Address"
        className={styles.inputField}
      />
      <input
        name="deliveryTime"
        type="datetime-local"
        value={consignee.deliveryTime}
        onChange={(e) => handleConsigneeChange(index, e)}
        className={styles.inputField}
      />
      <button type="button" onClick={() => handleRemoveConsignee(index)}>
        Remove
      </button>
    </div>
  ))}
  <button type="button" onClick={handleAddConsignee}>
    Add Consignee
  </button>
</div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Miles</label>
            <input
              name="miles"
              value={loadData.miles}
              onChange={handleChange}
              placeholder="Miles"
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>DH</label>
            <input
              name="dh"
              value={loadData.dh}
              onChange={handleChange}
              placeholder="DH"
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Notes</label>
          <textarea
            name="notes"
            value={loadData.notes}
            onChange={handleChange}
            placeholder="Notes"
            className={styles.textareaField}
          ></textarea>
        </div>

        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          Add New Load
        </button>
        <button type="button" onClick={handleCancel} className={styles.closeBtn}>
          Cancel
        </button>
      </form>

      <div className={styles.uploadSection}>
      <label>Choose RC</label>
      <input type="file" accept="application/pdf" onChange={handleFileUpload} />

      {/* Afișează indicatorul de încărcare */}
      {isLoading && <p>Loading... Please wait.</p>}
    </div>
  </div>
);
};


const IFTA = () => { 
  const [iftaData, setIftaData] = useState({
    companyName: localStorage.getItem('companyName') || '',
    companyLogo: localStorage.getItem('companyLogo') || null,
    fuelPurchasedState: '',
    milesDrivenState: '',
    fuelPrice: '',
    fuelConsumed: '',
    milesDriven: '',
    driver: '',
    loadNumber: '',
  });

  const [reports, setReports] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [loads, setLoads] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReport, setSelectedReport] = useState(null);
  const apiUrl = 'https://api-backend.full-trucking-service.com/api';
  const token = sessionStorage.getItem('token');
  const clientId = localStorage.getItem('clientId');
  
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
    'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
    'Wisconsin', 'Wyoming', 'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia',
    'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'
  ];

  useEffect(() => {
    fetchReports();
    fetchDriversAndLoads();
  }, []);

  const fetchReports = async () => {
    try {
      const response = await axios.get(`${apiUrl}/ifta`, config);
      setReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  const fetchDriversAndLoads = async () => {
    try {
      const driversResponse = await axios.get(`${apiUrl}/drivers`, config);
      const loadsResponse = await axios.get(`${apiUrl}/loads`, config);
      setDrivers(driversResponse.data);
      setLoads(loadsResponse.data);
    } catch (error) {
      console.error('Error fetching drivers and loads:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIftaData({ ...iftaData, [name]: value || '' });
  };

  const generateIFTAReport = async () => {
    try {
      const payload = {
        companyName: iftaData.companyName || '',
        fuelPurchasedState: iftaData.fuelPurchasedState || '',
        milesDrivenState: iftaData.milesDrivenState || '',
        fuelPrice: parseFloat(iftaData.fuelPrice) || 0,
        fuelConsumed: parseFloat(iftaData.fuelConsumed) || 0,
        milesDriven: parseFloat(iftaData.milesDriven) || 0,
        driver: iftaData.driver || '',
        loadNumber: iftaData.loadNumber || '',
      };

      const response = await axios.post(`${apiUrl}/ifta`, payload, config);
      const { result } = response.data;

      generatePDF(result);
      fetchReports();
    } catch (error) {
      console.error('Error generating IFTA report:', error);
    }
  };

  const generatePDF = (data) => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text('IFTA Report', 10, 10);
    const selectedDriver = drivers.find((driver) => driver._id === iftaData.driver);

    if (iftaData.companyLogo) {
      const imageData = iftaData.companyLogo;
      doc.addImage(imageData, 'PNG', 10, 20, 50, 20);
      doc.text(iftaData.companyName, 70, 30);
    } else {
      doc.text(iftaData.companyName, 10, 30);
    }

    doc.autoTable({
      startY: 50,
      head: [['Description', 'Value']],
      body: [
        ['Total Fuel Tax', `$${data.totalFuelTax}`],
        ['Total Fuel Cost', `$${data.totalFuelCost}`],
        ['Fuel Purchased State Tax', `$${data.breakdownByState.fuelPurchasedState}`],
        ['Miles Driven State Tax', `$${data.breakdownByState.milesDrivenState}`],
        ['Driver', selectedDriver ? `${selectedDriver.firstName} ${selectedDriver.lastName}` : 'N/A'],
        ['Load Number', iftaData.loadNumber],
      ],
    });

    doc.save(`${iftaData.companyName}_IFTA_Report.pdf`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/ifta/${id}`, config);
      fetchReports();
    } catch (error) {
      console.error('Error deleting report:', error);
    }
  };

  const handleEdit = (report) => {
    setIftaData(report);
    setSelectedReport(report._id);
    window.scrollTo(0, 0);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`${apiUrl}/ifta/${selectedReport}`, iftaData, config);
      setSelectedReport(null);
      fetchReports();
    } catch (error) {
      console.error('Error updating report:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredReports = reports.filter(report =>
    report.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    report.driver.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageData = reader.result;
      setIftaData({ ...iftaData, companyLogo: imageData });
      localStorage.setItem('companyLogo', imageData);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.iftaSection}>
      <h2 className={styles.title}>Fuel Tax Reporting</h2>

      <input
        type="text"
        placeholder="Search reports"
        value={searchTerm}
        onChange={handleSearchChange}
        className={styles.searchInput}
      />

      <div className={styles.formGroup}>
        <label>Company Name</label>
        <input
          type="text"
          name="companyName"
          value={iftaData.companyName || ''}
          onChange={handleChange}
          placeholder="Enter company name"
          className={styles.inputField}
        />
        <small>This is the name of the company.</small>
        <label>Upload Company Logo</label>
        <input type="file" onChange={handleLogoChange} className={styles.fileInput} />
        <small>Upload the logo of the company (PNG format).</small>
      </div>

      <div className={styles.formGroup}>
        <label>Fuel Purchased State</label>
        <select
          name="fuelPurchasedState"
          value={iftaData.fuelPurchasedState || ''}
          onChange={handleChange}
          className={styles.selectField}
        >
          <option value="">Select State</option>
          {states.map((state, index) => (
            <option key={index} value={state}>{state}</option>
          ))}
        </select>
        <small>Select the state where the fuel was purchased.</small>
      </div>

      <div className={styles.formGroup}>
        <label>Miles Driven State</label>
        <select
          name="milesDrivenState"
          value={iftaData.milesDrivenState || ''}
          onChange={handleChange}
          className={styles.selectField}
        >
          <option value="">Select State</option>
          {states.map((state, index) => (
            <option key={index} value={state}>{state}</option>
          ))}
        </select>
        <small>Select the state where the miles were driven.</small>
      </div>

      <div className={styles.formGroup}>
        <label>Fuel Price (Per Gallon)</label>
        <input
          type="number"
          name="fuelPrice"
          value={iftaData.fuelPrice || ''}
          onChange={handleChange}
          placeholder="Fuel Price"
          className={styles.inputField}
        />
        <small>Enter the fuel price per gallon.</small>
      </div>

      <div className={styles.formGroup}>
        <label>Fuel Consumed (Gallons)</label>
        <input
          type="number"
          name="fuelConsumed"
          value={iftaData.fuelConsumed || ''}
          onChange={handleChange}
          placeholder="Fuel Consumed"
          className={styles.inputField}
        />
        <small>Enter the total fuel consumed in gallons.</small>
      </div>

      <div className={styles.formGroup}>
        <label>Miles Driven</label>
        <input
          type="number"
          name="milesDriven"
          value={iftaData.milesDriven || ''}
          onChange={handleChange}
          placeholder="Miles Driven"
          className={styles.inputField}
        />
        <small>Enter the total miles driven.</small>
      </div>

      <div className={styles.formGroup}>
        <label>Driver</label>
        <select
          name="driver"
          value={iftaData.driver || ''}
          onChange={handleChange}
          className={styles.selectField}
        >
          <option value="">Select Driver</option>
          {drivers.map((driver) => (
            <option key={driver._id} value={driver._id}>
              {driver.firstName} {driver.lastName}
            </option>
          ))}
        </select>
        <small>Select the driver for this report.</small>
      </div>

      <div className={styles.formGroup}>
        <label>Load Number</label>
        <select
          name="loadNumber"
          value={iftaData.loadNumber || ''}
          onChange={handleChange}
          className={styles.selectField}
        >
          <option value="">Select Load</option>
          {loads.map((load) => (
            <option key={load._id} value={load.loadNumber}>
              {load.loadNumber}
            </option>
          ))}
        </select>
        <small>Select the load for this report.</small>
      </div>

      <button
        className={styles.submitButton}
        onClick={selectedReport ? handleUpdate : generateIFTAReport}
      >
        {selectedReport ? 'Update IFTA' : 'Save and Preview IFTA Report'}
      </button>

      <div className={styles.savedReports}>
        <h3>Saved Reports</h3>
        <ul>
          {reports.map((report) => (
            <li key={report._id}>
              {report.companyName} - {report.driver} - {report.loadNumber}
              <button onClick={() => handleEdit(report)}>Edit</button>
              <button onClick={() => handleDelete(report._id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};





const mapContainerStyle = {
  width: '100%',
  height: '600px',
};


const Trucking = ({ drivers = [] }) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  const [socket, setSocket] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [linkStatus, setLinkStatus] = useState(null); // "active", "inactive", null
  const [shareLinks, setShareLinks] = useState([]);
  const [shareLinkId, setShareLinkId] = useState('');

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const apiUrl = 'https://api-backend.full-trucking-service.com';
  const token = sessionStorage.getItem('token');

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };
  // Configurare WebSocket
  useEffect(() => {
    const newSocket = io(apiUrl, {
      transports: ['websocket', 'polling'],
      secure: true,
      reconnect: true,
      timeout: 20000,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      rejectUnauthorized: false,
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    return () => {
      newSocket.close();
    };
  }, []);

  // Ascultare pentru locație
  useEffect(() => {
    if (socket && selectedDriver) {
      setLoadingLocation(true);

      socket.on('locationUpdated', (data) => {
        if (data.driverId === selectedDriver) {
          const newLocation = {
            lat: data.location.latitude,
            lng: data.location.longitude,
          };
          setCurrentLocation(newLocation);
          setMapCenter(newLocation);
          setLoadingLocation(false);
        }
      });

      return () => {
        socket.off('locationUpdated');
      };
    }
  }, [socket, selectedDriver]);

  // Selectare șofer și resetare stări
const handleDriverSelect = async (driverId) => {
  if (!driverId) {
    setSelectedDriver(null);
    setCurrentLocation(null);
    setShareLink('');
    setPinCode('');
    setLinkStatus(null);
    setShareLinkId('');
    setLoadingLocation(false);
    return;
  }

  setSelectedDriver(driverId);
  setCurrentLocation(null);
  setShareLink('');
  setPinCode('');
  setLinkStatus(null);
  setShareLinkId('');
  setLoadingLocation(true);

  try {
    const response = await axios.get(`${apiUrl}/api/getShareLinks/${driverId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      setShareLinks(response.data.links || []);
    } else {
      setShareLinks([]);
    }
  } catch (error) {
    console.error('Error fetching share links:', error);
    setShareLinks([]);
  } finally {
    setLoadingLocation(false);
  }
};
const generateShareLink = async () => {
  if (!selectedDriver) {
    alert('Please select a driver first.');
    return;
  }

  try {
    const response = await axios.post(
      `${apiUrl}/api/ShareLink`,
      { driverId: selectedDriver },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 201) {
      const { link, pinCode, expiresAt } = response.data;
      setShareLinks((prevLinks) => [
        ...prevLinks,
        { link, pinCode, expiresAt },
      ]);
      alert(`Link generated successfully:\n${link}\nPIN: ${pinCode}`);
    }
  } catch (error) {
    if (error.response) {
      console.error('Server error:', error.response.data);
      alert(error.response.data.error || 'Failed to generate share link.');
    } else if (error.request) {
      console.error('No response from the server:', error.request);
      alert('No response from the server. Please try again later.');
    } else {
      console.error('Request setup error:', error.message);
      alert('An unexpected error occurred. Please try again.');
    }
  }
};



// Anulare link de partajare
const cancelShareLink = async (shareLinkId) => {
  if (!shareLinkId) {
    alert('No active share link to cancel.');
    return;
  }

  try {
    const response = await axios.post(
      `${apiUrl}/api/cancelShareLink`,
      { shareLinkId },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 200) {
      setShareLinks((prevLinks) =>
        prevLinks.filter((link) => link._id !== shareLinkId)
      );
      alert('Share location canceled successfully.');
    }
  } catch (error) {
    console.error('Error canceling share link:', error);
    alert('Failed to cancel share link.');
  }
};


  return (
    <div className={styles.truckingSection}>
      <div className={styles.driverControls}>
        <h3>Driver Tracking</h3>
        <select
          className={styles.selectField}
          onChange={(e) => handleDriverSelect(e.target.value)}
        >
          <option value="">Select Driver</option>
          {drivers.map((driver) => (
            <option key={driver._id} value={driver._id}>
              {driver.firstName} {driver.lastName}
            </option>
          ))}
        </select>
        {selectedDriver && (
          <div className={styles.linkControls}>
            {linkStatus !== 'active' && (
              <button onClick={generateShareLink}>Generate Link</button>
            )}
            {linkStatus === 'active' && (
              <button onClick={cancelShareLink}>Cancel Share Location</button>
            )}
          </div>
        )}
        {loadingLocation && selectedDriver && (
          <p className={styles.loadingText}>Loading location, please wait...</p>
        )}
        {shareLink && (
          <div className={styles.shareLink}>
            <p>Share this link:</p>
            <input type="text" value={shareLink} readOnly />
            <p>PIN: {pinCode}</p>
            <button
              className={styles.copyButton}
              onClick={() => navigator.clipboard.writeText(`${shareLink} PIN: ${pinCode}`)}
            >
              Copy Link and PIN
            </button>
          </div>
        )}
      </div>
      <div className={styles.mapContainer}>
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap mapContainerStyle={mapContainerStyle} center={currentLocation || mapCenter} zoom={14}>
            {currentLocation && (
              <>
                <Marker position={currentLocation} label="Current Location" />
                <Circle
                  center={currentLocation}
                  radius={500}
                  options={{
                    fillColor: 'rgba(0, 0, 255, 0.2)',
                    strokeColor: 'rgba(0, 0, 255, 0.6)',
                    strokeWeight: 2,
                  }}
                />
              </>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    <div className={styles.linksContainer}>
        <h4>Active Share Links</h4>
        {shareLinks.length > 0 ? (
          <ul className={styles.linksList}>
            {shareLinks.map((link) => (
              <li key={link._id} className={styles.linkItem}>
                <p><strong>Link:</strong> {link.link}</p>
                <p><strong>PIN:</strong> {link.pinCode}</p>
                <p><strong>Expires At:</strong> {new Date(link.expiresAt).toLocaleString()}</p>
                <div>
                  <button
                    className={styles.copyButton}
                    onClick={() => navigator.clipboard.writeText(`${link.link} PIN: ${link.pinCode}`)}
                  >
                    Copy Link
                  </button>
                  <button
                    className={styles.cancelButton}
                    onClick={() => cancelShareLink(link._id)}
                  >
                    Cancel
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No active share links for this driver.</p>
        )}
      </div>
    </div>
  );
};


const Chat = ({ drivers = [] }) => {
  const [messages, setMessages] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [socket, setSocket] = useState(null);

  const apiUrl = 'https://api-backend.full-trucking-service.com/api';
  const token = sessionStorage.getItem('token');
  const clientId = localStorage.getItem('clientId');

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  // Filtrare șoferi pe baza căutării
  const filteredDrivers = drivers.filter((driver) =>
    `${driver.firstName} ${driver.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Configurare WebSocket
  useEffect(() => {
    if (!token || !clientId) {
      console.error('Token or Client ID missing!');
      return;
    }

    const newSocket = io('https://api-backend.full-trucking-service.com', {
      transports: ['websocket', 'polling'],
      extraHeaders: { Authorization: `Bearer ${token}` },
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    newSocket.on('receiveMessage', (data) => {
      if (!data.driverId || !data.sender || !data.message || !data.timestamp) {
        console.error('Invalid message data received via WebSocket:', data);
        return;
      }
      setMessages((prevMessages) => ({
        ...prevMessages,
        [data.driverId]: [
          ...(prevMessages[data.driverId] || []),
          {
            from: data.sender,
            message: data.message,
            timestamp: data.timestamp,
          },
        ],
      }));
    });

    newSocket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [token, clientId]);

  // Preluare mesaje din backend pentru șoferul selectat
  useEffect(() => {
    if (selectedDriver) {
      const fetchMessages = async () => {
        try {
          const response = await fetch(`${apiUrl}/chat/${selectedDriver._id}`, config);

          if (response.ok) {
            const data = await response.json();
            setMessages((prevMessages) => ({
              ...prevMessages,
              [selectedDriver._id]: data,
            }));
          } else {
            console.error('Failed to fetch messages:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };

      fetchMessages();
    }
  }, [selectedDriver]);

  // Trimitere mesaj
  const handleSendMessageClick = async () => {
    if (!newMessage.trim() || !selectedDriver) {
      console.warn('Message is empty or no driver selected.');
      return;
    }

    const localMessage = {
      from: 'dispatcher',
      message: newMessage,
      timestamp: new Date().toISOString(),
    };

    // Adaugă mesaj local
    setMessages((prevMessages) => ({
      ...prevMessages,
      [selectedDriver._id]: [...(prevMessages[selectedDriver._id] || []), localMessage],
    }));

    const messageData = {
      driverId: selectedDriver._id,
      clientId,
      message: newMessage,
      sender: 'dispatcher',
    };

    setNewMessage(''); // Curăță input-ul

    // Trimite mesajul prin WebSocket
    if (socket) {
      socket.emit('sendMessage', messageData, (ack) => {
        if (!ack?.success) {
          console.error('WebSocket error:', ack?.error);
        }
      });
    }

    // Salvează mesajul în backend
    try {
      const response = await fetch(`${apiUrl}/chat`, {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(messageData),
      });

      if (!response.ok) {
        throw new Error('Failed to save message to backend.');
      }
    } catch (error) {
      console.error('Error saving message to backend:', error);

      // Revert mesaj local dacă salvarea a eșuat
      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedDriver._id]: (prevMessages[selectedDriver._id] || []).filter(
          (msg) => msg !== localMessage
        ),
      }));
    }
  };

  // Selectare șofer
  const handleSelectDriver = (driver) => {
    setSelectedDriver(driver);
  };

  // Selectare emoji
  const handleEmojiClick = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className={styles.chatContainer}>
      {/* Sidebar pentru lista șoferilor */}
      <div className={styles.chatSidebar}>
        <input
          type="text"
          placeholder="Search drivers..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={styles.searchInput}
        />
        <div className={styles.driverList}>
          {filteredDrivers.length > 0 ? (
            filteredDrivers.map((driver) => (
              <div
                key={driver._id}
                className={`${styles.driverItem} ${
                  selectedDriver && selectedDriver._id === driver._id ? styles.selected : ''
                }`}
                onClick={() => handleSelectDriver(driver)}
              >
                {driver.firstName} {driver.lastName}
              </div>
            ))
          ) : (
            <div className={styles.noDrivers}>No drivers available</div>
          )}
        </div>
      </div>

      {/* Secțiunea principală de chat */}
      <div className={styles.chatSection}>
        {selectedDriver ? (
          <>
            <div className={styles.chatMessages}>
              {Array.isArray(messages[selectedDriver._id])
                ? messages[selectedDriver._id].map((message, index) => (
                    <div
                      key={index}
                      className={`${styles.message} ${
                        message.from === 'dispatcher' ? styles.outgoing : styles.incoming
                      }`}
                    >
                      {message.message}
                    </div>
                  ))
                : <p>No messages yet.</p>}
            </div>
            <div className={styles.chatInput}>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
                className={styles.inputField}
              />
              <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className={styles.emojiButton}>
                😀
              </button>
              <button onClick={handleSendMessageClick} className={styles.sendButton}>
                Send
              </button>
              {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />}
            </div>
          </>
        ) : (
          <div className={styles.noDriverSelected}>
            <p>Select a driver to start the conversation</p>
          </div>
        )}
      </div>
    </div>
  );
};








const Settings = ({ updateUserSettings, drivers = [], handleUpdateDriver }) => {
  const [updatedSettings, setUpdatedSettings] = useState({
    companyName: '',
    companyAddress: '',
    firstName: '',
    lastName: '',
    mcNumber: '',
    phone: '',
    email: '',
    password: '',
    subscriptionType: '',
    contractStart: '',
    package: '',
    contractEnd: '',
    notes: ''
  });

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [driverEmail, setDriverEmail] = useState('');
  const [driverPassword, setDriverPassword] = useState('');
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    paymentType: '',
    expirationDate: ''
  });

  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);
  const [twoFactorWarning, setTwoFactorWarning] = useState(false);
  

   
  const [dispatchers, setDispatchers] = useState([]);
  const [accountings, setAccountings] = useState([]);
  const [selectedRole, setSelectedRole] = useState('dispatcher');
  const [newAccountFirstName, setNewAccountFirstName] = useState('');
  const [newAccountLastName, setNewAccountLastName] = useState('');
  const [newAccountEmail, setNewAccountEmail] = useState('');
  const [newAccountPhone, setNewAccountPhone] = useState('');
  const [newAccountAddress, setNewAccountAddress] = useState('');
  const [newAccountDOB, setNewAccountDOB] = useState('');
  const [newAccountSalary, setNewAccountSalary] = useState('');
  const [newAccountPassword, setNewAccountPassword] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);

  const token = sessionStorage.getItem('token');
  const apiUrl = 'https://api-backend.full-trucking-service.com/api';
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${apiUrl}/settings`, config);
      const settings = response.data;

      setUpdatedSettings({
        companyName: settings.companyName || '',
        companyAddress: settings.companyAddress || '',
        firstName: settings.firstName || '',
        lastName: settings.lastName || '',
        mcNumber: settings.mcNumber || '',
        phone: settings.phone || '',
        email: settings.email || '',
        password: settings.password || '',
        subscriptionType: settings.subscriptionType || '',
        contractStart: settings.contractStart ? settings.contractStart.split('T')[0] : '',
        contractEnd: settings.contractEnd ? settings.contractEnd.split('T')[0] : '',
        package: settings.package || '',
        notes: settings.notes || ''
      });

      setSubscriptionInfo({
        subscriptionType: settings.subscriptionType || 'N/A',
        contractEnd: settings.contractEnd ? settings.contractEnd.split('T')[0] : 'N/A',
        package: settings.package || 'N/A',
      });

      setIsTwoFactorEnabled(settings.twoFactorAuthEnabled || false);
      setTwoFactorWarning(!settings.twoFactorAuthEnabled);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedSettings({ ...updatedSettings, [name]: value });
  };
  const handleSaveSettings = async () => {
    if (password && password !== confirmPassword) {
        alert('Passwords do not match!');
        return;
    }

    // Validare format parolă
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@!#$%^&*])[A-Za-z\d@!#$%^&*]{12,}$/;
    if (password && !passwordRegex.test(password)) {
        alert('Password must be at least 12 characters long, contain an uppercase letter, a number, and a special character (@!#$%^&*).');
        return;
    }

    setIsSaving(true);

    try {
        const updatedData = { ...updatedSettings };
        // Include parola doar dacă este setată
        if (password) {
            updatedData.password = password;
        }

        await updateUserSettings(updatedData); // trimite doar datele relevante
        await fetchSettings();

        if (password) {
            alert('Password changed successfully. Please log in again.');
            sessionStorage.removeItem('token');
            window.location.href = '/login';
        } else {
            alert('Settings updated successfully');
        }
    } catch (error) {
        console.error('Error updating settings:', error);
        alert('Failed to update settings. Please try again.');
    } finally {
        setIsSaving(false);
    }
};

  
    
  const handleDriverUpdate = () => {
    if (!selectedDriver) return;

    const updatedDriver = {
      ...selectedDriver,
      email: driverEmail,
      password: driverPassword,
      isBlocked: false,
    };

    axios.put(`${apiUrl}/drivers/${selectedDriver._id}`, updatedDriver, config)
      .then(() => {
        alert('Driver information has been updated!');
      })
      .catch(error => {
        console.error('Error updating driver:', error);
        alert('Failed to update driver.');
      });
  };

  const handleBlockDriver = () => {
    if (!selectedDriver) return;

    const updatedDriver = { ...selectedDriver, isBlocked: true };
    handleUpdateDriver(updatedDriver);
    alert('Driver account has been blocked!');
  };

  const handleSelectDriver = (driverId) => {
    const driver = drivers.find((d) => d._id === driverId);
    setSelectedDriver(driver);
    setDriverEmail(driver?.email || '');
    setDriverPassword('');
  };


  const handleEnableTwoFactorAuth = () => {
    axios.post(`${apiUrl}/enable-two-factor`, { method: 'email' }, config)
      .then(() => {
        alert('Two-Factor Authentication via Email enabled. You will receive a code during login.');
        setIsTwoFactorEnabled(true);
        setTwoFactorWarning(false);
      })
      .catch(error => {
        console.error('Error enabling Two-Factor Authentication:', error);
        alert('Failed to enable Two-Factor Authentication.');
      });
  };

  const handleDisableTwoFactorAuth = () => {
    axios.post(`${apiUrl}/disable-two-factor`, {}, config)
      .then(() => {
        alert('Two-Factor Authentication has been disabled!');
        setIsTwoFactorEnabled(false);
        setTwoFactorWarning(true);
      })
      .catch(error => {
        console.error('Error disabling Two-Factor Authentication:', error);
        alert('Failed to disable Two-Factor Authentication.');
      });
  };
  useEffect(() => {
    fetchClientData();  
    fetchDispatchers();
    fetchAccountings();
  }, []);

  const [clientData, setClientData] = useState({});

const fetchClientData = async () => {
  try {
    const response = await axios.get(`${apiUrl}/clients`, config); // Fără clientId în URL
    setClientData(response.data);
  } catch (error) {
    console.error('Failed to fetch client data:', error);
  }
};

const fetchDispatchers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/dispatchers`, config);
    console.log('Dispatchers from API:', response.data); // Verifică datele returnate de API
    setDispatchers(Array.isArray(response.data) ? response.data : []);
  } catch (error) {
    console.error('Failed to fetch dispatchers:', error);
    setDispatchers([]); // Setează un array gol în caz de eroare
  }
};


  const fetchAccountings = async () => {
    try {
      const response = await axios.get(`${apiUrl}/accountings`, config);
      setAccountings(response.data);
    } catch (error) {
      console.error('Failed to fetch accountings:', error);
    }
  };

  const handleCreateDispatcherOrAccounting = async () => {
    const clientId = localStorage.getItem('clientId'); // sau folosește variabila unde este stocat clientId-ul
  
     // Verifică numărul de dispatchers și accountants deja existenți
  const currentDispatchersCount = dispatchers.length;
  const currentAccountingsCount = accountings.length;

  // Comparăm cu limitele definite pentru client
  if (selectedRole === 'dispatcher' && currentDispatchersCount >= clientData.numberOfDispatchers) {
    alert('Nu poți adăuga mai mulți dispatcheri decât limita stabilită.');
    return;
  }
  
  if (selectedRole === 'accounting' && currentAccountingsCount >= clientData.numberOfAccountants) {
    alert('Nu poți adăuga mai mulți contabili decât limita stabilită.');
    return;
  }

    const newAccountData = {
      firstName: newAccountFirstName,
      lastName: newAccountLastName,
      email: newAccountEmail,
      phone: newAccountPhone,
      address: newAccountAddress,
      dateOfBirth: newAccountDOB,
      salaryDetails: newAccountSalary,
      password: newAccountPassword,
      role: selectedRole,
      clientId: clientId // Adaugă clientId la datele pentru `accounting`
    };
  
    try {
      await axios.post(
        `${apiUrl}/${selectedRole === 'dispatcher' ? 'dispatchers' : 'accountings'}`,
        newAccountData,
        config
      );
      alert(`${selectedRole === 'dispatcher' ? 'Dispatcher' : 'Accounting'} created successfully!`);
      selectedRole === 'dispatcher' ? fetchDispatchers() : fetchAccountings();
      clearAccountForm();
    } catch (error) {
      console.error(`Error creating ${selectedRole}:`, error);
      alert(`Failed to create ${selectedRole}`);
    }
  };
  

  const handleEditAccount = (account) => {
    setSelectedAccount(account);
    setNewAccountFirstName(account.firstName);
    setNewAccountLastName(account.lastName);
    setNewAccountEmail(account.email);
    setNewAccountPhone(account.phone);
    setNewAccountAddress(account.address);
    setNewAccountDOB(account.dateOfBirth);
    setNewAccountSalary(account.salaryDetails);
    setSelectedRole(account.role);
  };

  const handleUpdateAccount = async () => {
    if (!selectedAccount) return;

    const updatedData = {
      firstName: newAccountFirstName,
      lastName: newAccountLastName,
      email: newAccountEmail,
      phone: newAccountPhone,
      address: newAccountAddress,
      dateOfBirth: newAccountDOB,
      salaryDetails: newAccountSalary,
    };

    try {
      await axios.put(`${apiUrl}/${selectedRole === 'dispatcher' ? 'dispatchers' : 'accountings'}/${selectedAccount._id}`, updatedData, config);
      alert(`${selectedRole === 'dispatcher' ? 'Dispatcher' : 'Accounting'} updated successfully!`);
      selectedRole === 'dispatcher' ? fetchDispatchers() : fetchAccountings();
      clearAccountForm();
    } catch (error) {
      console.error(`Error updating ${selectedRole}:`, error);
      alert(`Failed to update ${selectedRole}`);
    }
  };

  const handleDeleteAccount = async (account) => {
    const accountId = account._id;
    const accountType = account.role === 'dispatcher' ? 'dispatchers' : 'accountings';

    try {
      await axios.delete(`${apiUrl}/${accountType}/${accountId}`, config);
      alert(`${account.role} deleted successfully!`);
      account.role === 'dispatcher' ? fetchDispatchers() : fetchAccountings();
    } catch (error) {
      console.error(`Error deleting ${account.role}:`, error);
      alert(`Failed to delete ${account.role}`);
    }
  };

  const clearAccountForm = () => {
    setNewAccountFirstName('');
    setNewAccountLastName('');
    setNewAccountEmail('');
    setNewAccountPhone('');
    setNewAccountAddress('');
    setNewAccountDOB('');
    setNewAccountSalary('');
    setNewAccountPassword('');
    setSelectedAccount(null);
  };

  return (
    <div className={styles.settingsSection}>
      {twoFactorWarning && (
        <div className={styles.twoFactorWarningsettings}>
          <p style={{ color: 'red' }}>Please set up Two-Factor Authentication to enhance your account security!</p>
        </div>
      )}

      <h2 className={styles.titlesettings}>Settings</h2>
      <form className={styles.settingsFormsettings}>
        <label>Company Name</label>
        <input
          name="companyName"
          value={updatedSettings.companyName || ''}
          onChange={handleChange}
          placeholder="Company Name"
          className={styles.inputFieldsettings}
        />

        <label>Company Address</label>
        <input
          name="companyAddress"
          value={updatedSettings.companyAddress || ''}
          onChange={handleChange}
          placeholder="Company Address"
          className={styles.inputFieldsettings}
        />

        <label>First Name</label>
        <input
          name="firstName"
          value={updatedSettings.firstName || ''}
          onChange={handleChange}
          placeholder="First Name"
          className={styles.inputFieldsettings}
        />

        <label>Last Name</label>
        <input
          name="lastName"
          value={updatedSettings.lastName || ''}
          onChange={handleChange}
          placeholder="Last Name"
          className={styles.inputFieldsettings}
        />

        <label>MC Number</label>
        <input
          name="mcNumber"
          value={updatedSettings.mcNumber || ''}
          onChange={handleChange}
          placeholder="MC Number"
          className={styles.inputFieldsettings}
        />

        <label>Phone Number</label>
        <input
          name="phone"
          value={updatedSettings.phone || ''}
          onChange={handleChange}
          placeholder="Phone Number"
          className={styles.inputFieldsettings}
        />

        <label>Email</label>
        <input
          name="email"
          value={updatedSettings.email || ''}
          onChange={handleChange}
          placeholder="Email"
          className={styles.inputFieldsettings}
        />

        <label>New Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New Password"
          className={styles.inputFieldsettings}
        />

        <label>Confirm Password</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          className={styles.inputFieldsettings}
        />

        <button type="button" onClick={handleSaveSettings} className={styles.submitButtonsettings}>
          Save Settings
        </button>
      </form>
      <h3 className={styles.subtitlesettings}>Drivers</h3>
      <select onChange={(e) => handleSelectDriver(e.target.value)} className={styles.selectFieldsettings}>
        <option value="">Select Driver</option>
        {Array.isArray(drivers) && drivers.map((driver) => (
          <option key={driver._id} value={driver._id}>
            {driver.firstName} {driver.lastName}
          </option>
        ))}
      </select>
      {selectedDriver && (
        <div className={styles.driverFormsettings}>
          <label>Driver Email</label>
          <input
            type="email"
            value={driverEmail}
            onChange={(e) => setDriverEmail(e.target.value)}
            placeholder="Driver Email"
            className={styles.inputFieldsettings}
          />

          <label>Driver Password</label>
          <input
            type="password"
            value={driverPassword}
            onChange={(e) => setDriverPassword(e.target.value)}
            placeholder="Driver Password"
            className={styles.inputFieldsettings}
          />

          <button onClick={handleDriverUpdate} className={styles.submitButtonsettings}>
            Update Driver
          </button>
          <button onClick={handleBlockDriver} className={styles.deleteButtonsettings}>
            Block Driver
          </button>
        </div>
      )}
          
<h3 className={styles.subtitle}>Account Management</h3>
<select onChange={e => setSelectedRole(e.target.value)} className={styles.selectFieldsettings}>
  <option value="dispatcher">Dispatcher</option>
  <option value="accounting">Accounting</option>
</select>

<form className={styles.accountFormsettings}>
  <label>First Name</label>
  <input
    type="text"
    value={newAccountFirstName}
    onChange={e => setNewAccountFirstName(e.target.value)}
    placeholder="First Name"
    className={styles.inputFieldsettings}
  />

  <label>Last Name</label>
  <input
    type="text"
    value={newAccountLastName}
    onChange={e => setNewAccountLastName(e.target.value)}
    placeholder="Last Name"
    className={styles.inputFieldsettings}
  />

  <label>Email</label>
  <input
    type="email"
    value={newAccountEmail}
    onChange={e => setNewAccountEmail(e.target.value)}
    placeholder="Email"
    className={styles.inputFieldsettings}
  />

  <label>Phone</label>
  <input
    type="text"
    value={newAccountPhone}
    onChange={e => setNewAccountPhone(e.target.value)}
    placeholder="Phone"
    className={styles.inputFieldsettings}
  />

  <label>Address</label>
  <input
    type="text"
    value={newAccountAddress}
    onChange={e => setNewAccountAddress(e.target.value)}
    placeholder="Address"
    className={styles.inputFieldsettings}
  />

  <label>Date of Birth</label>
  <input
    type="date"
    value={newAccountDOB}
    onChange={e => setNewAccountDOB(e.target.value)}
    placeholder="Date of Birth"
    className={styles.inputFieldsettings}
  />

  <label>Salary Details</label>
  <input
    type="text"
    value={newAccountSalary}
    onChange={e => setNewAccountSalary(e.target.value)}
    placeholder="Salary Details"
    className={styles.inputFieldsettings}
  />

  <label>Password</label>
  <input
    type="password"
    value={newAccountPassword}
    onChange={e => setNewAccountPassword(e.target.value)}
    placeholder="Password"
    className={styles.inputFieldsettings}
  />

  <button type="button" onClick={selectedAccount ? handleUpdateAccount : handleCreateDispatcherOrAccounting} className={styles.submitButtonsettings}>
    {selectedAccount ? 'Update Account' : 'Create Account'}
  </button>
  {selectedAccount && (
    <button type="button" onClick={clearAccountForm} className={styles.cancelButtonsettings}>
      Cancel Edit
    </button>
  )}
</form>

<h3 className={styles.subtitle}>Existing Accounts</h3>
<div>
  {[...dispatchers, ...accountings].map((account) => (
    <div key={account._id} className={styles.accountItemsettings}>
      <p>{account.firstName} {account.lastName} - {account.role}</p>
      <button onClick={() => handleEditAccount(account)} className={styles.editButtonsettings}>Edit</button>
      <button onClick={() => handleDeleteAccount(account)} className={styles.deleteButtonsettings}>Delete</button>
    </div>
  ))}
</div>

<h3 className={styles.subtitlesettings}>Subscription</h3>
<p className={styles.blackTextsettings}>Subscription Type: {subscriptionInfo.subscriptionType}</p>
<p className={styles.blackTextsettings}>Expiration Date: {subscriptionInfo.contractEnd}</p>
<p className={styles.blackTextsettings}>Package: {subscriptionInfo.package}</p>


      <h3 className={styles.subtitlesettings}>Two-Factor Authentication</h3>
      {isTwoFactorEnabled ? (
        <div>
          <p style={{ color: 'green' }}>Two-Factor Authentication is Enabled</p>
          <button onClick={handleDisableTwoFactorAuth} className={styles.deleteButtonsettings}>
            Disable 2FA
          </button>
        </div>
      ) : (
        <div>
          <button onClick={handleEnableTwoFactorAuth} className={styles.submitButtonsettings}>
            Enable 2FA via Email
          </button>
        </div>
      )}

<button onClick={() => window.location.href = '/FaqPage'} className={styles.actionButtonsettings}>
  FAQ
</button>

    </div>
  );
};



export default UltimatePage;