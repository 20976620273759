import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';import { useParams } from 'react-router-dom';
import io from 'socket.io-client';


const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;



const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
};

const infoContainerStyle = {
  position: 'absolute',
  top: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: '10px',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
};
const isValidLocation = (latitude, longitude) => {
  return (
    typeof latitude === 'number' &&
    typeof longitude === 'number' &&
    isFinite(latitude) &&
    isFinite(longitude) &&
    latitude >= -90 &&
    latitude <= 90 &&
    longitude >= -180 &&
    longitude <= 180
  );
};

const ShareTrucking = ({ drivers = [] }) => {
  const { driverId: paramDriverId, expires } = useParams();
  const [driverId] = useState(paramDriverId.trim());
  const [pin, setPin] = useState('');
  const [isPinValid, setIsPinValid] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  const [socket, setSocket] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');


  const apiUrl = 'https://api-backend.full-trucking-service.com';
  const token = sessionStorage.getItem('token');

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };
  // Timpul rămas pentru link
  useEffect(() => {
    const expirationTime = parseInt(expires, 10);
    const currentTime = Date.now();

    const remainingTime = expirationTime - currentTime;

    if (remainingTime <= 0) {
      setIsExpired(true);
    } else {
      setTimeLeft(Math.floor(remainingTime / 1000));
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          setIsExpired(true);
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [expires]);

  useEffect(() => {
    if (isPinValid) {
      const newSocket = io(apiUrl, {
        transports: ['websocket', 'polling'],
        secure: true,
        reconnect: true,
        timeout: 20000,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        rejectUnauthorized: false,
      });
  
      setSocket(newSocket);
  
      // Conectare WebSocket
      newSocket.on('connect', () => {
        console.log('Connected to WebSocket server');
      });
  
      // Handler pentru actualizări de locație
      const locationUpdateHandler = (data) => {
        console.log('Location update received from server:', data);
  
        if (data.driverId === driverId) {
          const { latitude, longitude } = data.location;
  
          if (!isValidLocation(latitude, longitude)) {
            console.error('Invalid location data received:', data.location);
            return;
          }
  
          const updatedLocation = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          };
  
          console.log('Setting current location to:', updatedLocation);
          setCurrentLocation(updatedLocation);
          setMapCenter(updatedLocation);
        } else {
          console.warn('Location update ignored due to mismatched driverId:', data.driverId);
        }
      };
  
      newSocket.on('locationUpdated', locationUpdateHandler);
  
      // Anulare rută
      const cancelRouteHandler = (data) => {
        if (data.driverId === driverId) {
          console.log('Route canceled for driver:', data.driverId);
          setIsExpired(true);
        }
      };
  
      newSocket.on('cancelRoute', cancelRouteHandler);
  
      // Gestionare erori WebSocket
      newSocket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error.message);
      });
  
      newSocket.on('disconnect', () => {
        console.warn('WebSocket disconnected. Attempting to reconnect...');
      });
  
      // Cleanup
      return () => {
        newSocket.off('locationUpdated', locationUpdateHandler);
        newSocket.off('cancelRoute', cancelRouteHandler);
        newSocket.close();
        console.log('WebSocket connection closed');
      };
    }
  }, [isPinValid, driverId]);
  
  

  // Validare PIN
  const handlePinSubmit = async () => {
    try {
      if (!driverId) {
        setErrorMessage('Invalid or missing Driver ID.');
        return;
      }
  
      console.log('Sending to backend:', { pinCode: pin, shareLinkId: driverId });
  
      const response = await fetch('https://api-backend.full-trucking-service.com/api/verifyPin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pinCode: pin, shareLinkId: driverId }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Invalid PIN');
      }
  
      setIsPinValid(true);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message || 'Something went wrong.');
      console.error('Error verifying PIN:', error.message);
    }
  };
  

  if (isExpired) {
    return (
      <div style={infoContainerStyle}>
        <p>The link has expired.</p>
      </div>
    );
  }

  if (!isPinValid) {
    return (
      <div style={infoContainerStyle}>
        <p>Please enter the PIN to access the location:</p>
        <input
          type="text"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          maxLength={8}
          placeholder="Enter PIN"
          style={{ padding: '5px', fontSize: '16px', marginBottom: '10px' }}
        />
        <button onClick={handlePinSubmit} style={{ padding: '5px 10px', fontSize: '16px' }}>
          Submit
        </button>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
    );
  }

  return (
    <div>
      <div style={infoContainerStyle}>
        {timeLeft > 0 ? <p>Link expires in: {timeLeft}s</p> : <p>Loading...</p>}
      </div>
      <div>
      <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap mapContainerStyle={mapContainerStyle} center={currentLocation || mapCenter} zoom={14}>
            {currentLocation && (
              <>
                <Marker position={currentLocation} label="Current Location" />
                <Circle
                  center={currentLocation}
                  radius={500}
                  options={{
                    fillColor: 'rgba(0, 0, 255, 0.2)',
                    strokeColor: 'rgba(0, 0, 255, 0.6)',
                    strokeWeight: 2,
                  }}
                />
              </>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default ShareTrucking;
